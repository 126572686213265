.mobile-side-bar {
    .horizontal-nav {
        display: inline-block !important;
        justify-content: space-around;
        /* You can use space-between or other values as per your need */
        align-items: center;
    }

    .horizontal-nav>div {
        padding: 10px;
        /* Adds spacing around the items */
        text-align: center;
    }

    .horizontal-nav a {
        text-decoration: none;
        color: inherit;
        /* Keeps the default color or allows you to style */
    }

    .horizontal-nav a:hover {
        color: #007bff;
        /* Changes the color on hover, for example */
    }

    .side-navigation-panel *,
    .side-navigation-panel:after,
    .side-navigation-panel:before {
        display: inline-block !important;
        border-style: none !important;
    }

    [class*="option-selected"] {
        border-top: 1px solid #2975CA !important;
        color: #2975CA !important;
        font-weight: 500 !important;
        // background: #D9EFFF !important;
        width: 100% !important;
    }

    .side-navigation-panel-select-option-text {
        display: none !important;
    }

    .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
        display: flex !important;
        justify-content: center !important;
    }

    .side-navigation-panel-select-option,
    .side-navigation-panel-select-option-selected {

        svg {
            height: 24px;
            width: 24px;
        }
    }

}

.mobile-close {
    padding: 10px;
    display: flex;
    justify-content: end;
    cursor: pointer;
}

.side-mobile-modal {

    .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
        font-size: 14px !important;
        font-weight: 400 !important;
    }

    .side-navigation-panel-select-option,
    .side-navigation-panel-select-option-selected {

        svg {
            height: 24px;
            width: 24px;
        }
    }

    .side-navigation-panel *,
    .side-navigation-panel:after,
    .side-navigation-panel:before {
        border-style: none !important;
    }

    [class*="option-selected"] {
        border-left: 4px solid #2975CA !important;
        color: #2975CA !important;
        font-weight: 500 !important;
        background: #D9EFFF !important;
        width: 100% !important;
    }
}
.mobile-side-bar{
    .side-navigation-panel{
        display: flex !important;
        justify-content: space-between !important;
        width: 100vw !important;
        padding: 0px 4px 0px 2px !important;
    }
}

