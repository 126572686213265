.payment-transaction-container {
    height: auto;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px 0px #0000001F;

    @media (max-width:575px) {
        margin: 0px 10px 10px 10px !important;
    }

    .past-transaction-heading {
        font-size: 18px;
        font-weight: 500;
        padding-top: 25px;
        padding-left: 15px;
    }

    .transaction-list {
        padding: 5px 40px 25px 25px;
        // width: 500px;
        height: 78vh;
        overflow-y: scroll;


        .list-date {
            font-size: 16px;
            font-weight: 400;
            color: #919CA5;
        }

        .list-payment {
            padding: 10px 0 10px 10px;
            border-bottom: 1px solid #dedddc;

            .listing-heading {
                font-size: 16px;
                font-weight: 600;
                padding-left: 10px;
                display: flex;
                align-items: center;
            }

            .amount-heading {
                font-size: 20px;
                font-weight: 600;
                color: #00A84D;
                padding-left: 7px;
            }

            .show-status {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}

.payment-transaction-container .transaction-list::-webkit-scrollbar {
    width: 8px !important;
    z-index: 1 !important;
    display: inline-block !important;
}

.payment-transaction-container .transaction-list::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}

.payment-transaction-container .transaction-list::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
    border-radius: 3px !important;
}

.payment-transaction-container .transaction-list::-webkit-scrollbar-thumb:hover {
    background-color: #909090 !important;
}

@media (max-width:1199px) {
    .payment-transaction-container {
        margin-top: 15px !important;
    }
}

@media (max-width:575px) {
    .payment-transaction-container .transaction-list .list-payment .listing-heading {
        align-items: start !important;
    }
}