.buttonManager {
    background: linear-gradient(180deg, #40A0E5 0%, #136BAB 100%);
    max-width: 369px;
}

.buttonManagerDisabled {
    background: linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%);
    max-width: 369px;
}

.continue,
.continueDisabled {
    font-family: Proxima_nova_medium;
    position: absolute;
    bottom: 66px;
    right: 68px;
    cursor: pointer;
    width: 217px;
}

/* For iPhone eXpensive portrait and iPhone 6-8 portrait */
@media (max-width: 375px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .buttonTwo {
        font-size: 14px !important;
    }
}

/* For Android (Pixel 2) portrait */
@media (min-width: 375px) and (max-width: 412px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .buttonTwo {
        font-size: 14px !important;
    }
}

/* For iPhone 6-8 Plump portrait */
@media (min-width:412px) and (max-width:414px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .buttonTwo {
        font-size: 14px !important;
    }
}

@media (min-width:413px) and (max-width:430px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 20px;
        margin-bottom: 20px !important;
    }
}

/* For iPhone 6-8 landscape */
@media (min-width:431px) and (max-width:667px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 70px;
        margin-bottom: 20px !important;
    }
}


/* For Android (Pixel 2) landscape */
@media (min-width:667px)and (max-width: 684px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 70px;
        margin-bottom: 20px !important;
    }
}

/* For iPhone eXpensive landscape */
@media (min-width: 684px) and (max-width: 734px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 90px;
        margin-bottom: 20px !important;
    }
}



/* For iPhone 6-8 Plump landscape */
@media (min-width: 734px) and (max-width:736px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 70px;
        margin-bottom: 20px !important;
    }
}

/* For iPad portrait */
@media (min-width:736px) and (max-width:768px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 90px;
        margin-bottom: 20px !important;
    }
}

/* For iPad landscape */
@media (min-width:768px) and (max-width:1025px) {

    .continue,
    .continueDisabled {
        position: unset;
        width: 100% !important;
        margin-top: 70px;
        margin-bottom: 20px !important;
    }
}

/* For screens with max height of 850px */
// @media screen and (max-height: 850px) {

//     .continue,
//     .continueDisabled {
//         position: relative !important;
//         bottom: 0px !important;
//         right: 0px !important;
//         margin-top: 80px !important;
//         float: right;
//         clear: both;
//     }
// }