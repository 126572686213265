.add-manager {
    margin-top: 20px;
    // width: 402px;

    .location-descrption {
        font-size: 18px;
        font-weight: 400;
        margin-left: 10px;
        color: #717578;
    }

    .add-card {
        // width: 430px;
        margin-top: 15px;

        .card-mobile-title {
            @media (max-width:575px) {
                position: fixed !important;
                // background-color: rgb(242, 242, 247) !important;
                // z-index: 999 !important;
                width: 100% !important;
                margin-top: 20px !important;
            }
        }

        .search-btn-manager {
            width: 112px;
            height: 40px;
            background-color: #2F86D1;
            border-radius: 4px;
            cursor: pointer;
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:575px) {
                margin-right: 20px !important;
            }
        }

        .search-heading {
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            padding-left: 7px;
            padding-right: 12px;
        }

        // .MuiInputBase-root {
        //     height: 50px !important;
        //     background: white !important;

        // }

        .add-manager-description {
            font-size: 16px;
            font-weight: 500;
            color: #2F86D1;
            display: flex;
            align-items: center;
            text-decoration: underline;
            cursor: pointer;

        }

        .manager-heading {
            font-size: 18px;
            font-weight: 500;
            margin-top: 15px;
        }

        .manager-card {
            // width: 432px;
            height: 280px;
            background-color: #ffffff;
            margin-top: 10px;
            overflow-y: scroll;
            overflow-x: hidden;
            box-shadow: 0px 2px 8px 0px #0000001F;


            @media (max-width:360px) {
                height: 326px !important;
            }

            @media (min-width:400px) and (max-width:415px) {
                height: 300px !important;
                margin-bottom: 10px !important;
            }

            @media (min-width:430px) and (max-width:500px) {
                height: 490px !important;
                margin-bottom: 30px !important;
            }

            @media (min-width:420px) and (max-width:430px) {
                height: 518px !important;
            }

            @media (min-width:380px) and (max-width:400px) {
                height: 360px !important;
                margin-bottom: 30px !important;
            }


            @media (min-height:660px) and (max-height:670px) {
                height: 252px !important;
            }

            @media (min-height:890px) and (max-height: 900px) {
                height: 481px !important;
            }

            @media (min-height:730px) and (max-height: 740px) {
                height: 321px !important;
            }

            @media (min-height:800px) and (max-height:815px) {
                height: 397px !important;
            }

            @media (max-width:575px) {
                margin-top: 250px !important;
            }

            @media (min-width:760px) and (max-width:1030px) {
                height: 575px !important;
            }


            .users-heading {
                padding-left: 10px;
                padding-top: 10px;

                .user-main-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #000000;
                }

                .user-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #909090;
                }
            }

            .listing-count {
                font-size: 14px;
                font-weight: 500;
                display: flex !important;
                align-items: center !important;
                margin-top: -5px;
                padding-right: 15px;
                color: #2F86D1;

                @media (max-width:575px) {
                    margin-top: -25px;
                    padding-left: 70px !important;
                }
            }
        }

        .manager-card-without-result {
            // width: 432px;
            height: 280px;
            background-color: #ffffff;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px dotted black;

            @media (max-width:575px) {
                margin-top: 215px !important;
            }

            .result-heading {
                font-size: 20px;
                font-weight: 400;
                display: flex;
                justify-content: center;
            }
        }
    }
}

.add-manager-listing-data {
    // width: 840px;
    height: auto;
    background-color: #ffffff;
    padding: 20px 20px 36px 0;
    margin-top: 20px;

    @media (min-width:760px) and (max-width:1025px) {
        margin-top: 0px !important;
    }

    @media (max-width:575px) {
        margin-top: 0px !important;
    }

    // @media (min-width:760px) and (max-width:1025px) {
    //     margin-top: 0px !important;
    // }

    .profile-addmanager {
        display: flex !important;
        justify-content: end !important;

        @media (max-width:1199px) {
            justify-content: space-between !important;
        }
    }

    .listing-data {
        margin-top: -26px;

    }

    .listing-data-heading {
        font-size: 20px;
        font-weight: 500;
        margin-top: 5px;
    }

    .phone-container {
        display: flex;
        justify-content: center;

        .phone-number {
            font-size: 16px;
            font-weight: 400;
            color: #707070;
            padding-left: 8px;
        }
    }

    // .phone-number {
    //     font-size: 16px;
    //     font-weight: 400;
    //     color: #707070;
    //     padding-left: 8px;
    // }

    .msg-box {
        width: 180px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #2975CA;
    }

    .email-conatiner {
        display: none !important;

        .phone-number {
            font-size: 16px;
            font-weight: 400;
            color: #707070;
            padding-left: 8px;
        }

    }
}

.add-manager .add-card .manager-card::-webkit-scrollbar {
    width: 8px !important;
    z-index: 1 !important;
    display: inline-block !important;
}

.add-manager .add-card .manager-card::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}

.add-manager .add-card .manager-card::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
    border-radius: 3px !important;
}

.add-manager .add-card .manager-card::-webkit-scrollbar-thumb:hover {
    background-color: #909090 !important;
}


.add-manager-modal {
    padding: 5px 10px;

    .manager-modal-heading {
        font-size: 24px;
        font-weight: 700;
        color: #303030;
        padding-top: 10px;
    }

    .continueMail {
        font-family: Proxima_nova_medium;
        // position: absolute;
        cursor: pointer;
        height: 56px;
    }

    .continueMailDisabled {
        font-family: Proxima_nova_medium;
        // position: absolute;
        cursor: pointer;
        height: 56px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    // .add-manager-listing-data .phone-container {
    //     display: none !important;
    // }

    // .add-manager-listing-data .email-conatiner {
    //     display: flex !important;
    //     justify-content: center !important;
    // }

    .responsive-find-list {
        display: none !important;
    }

    .responsive-find-main {
        display: inline !important;
    }

    // .find-people-listing-data {
    //     height: 65vh !important;
    // }
}

@media (min-width:991px) and (max-width:1549px) {
    .add-manager .add-card .manager-card .users-heading .user-main-heading {
        font-size: 14px !important;
    }

    .add-manager .add-card .add-manager-description {
        font-size: 13px !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .add-manager .add-card .add-manager-description {
        font-size: 14px !important;
    }

    .add-manager-listing-data {
        // margin-top: 20px !important;
        margin-right: 10px !important;
    }
}

@media (max-width:768px) {
    .add-manager .add-card .manager-card .users-heading .user-main-heading {
        font-size: 14px !important;
    }
}



@media (max-width:767px) {
    .add-manager {
        margin-top: 0px !important;

        .add-manager-icon {
            padding: 10px !important;
        }

        .add-card {
            padding: 10px !important;

            .manager-card .listing-count {
                padding-left: 70px !important;
            }
        }


    }

    .add-manager-listing-data {
        padding: 20px !important;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .122);

        .phone-icon {
            width: 15px !important;
            height: 15px !important;
        }

        // .phone-number{
        //     font-size: 12px !important;
        //     padding-top: 2px !important;
        // }

    }

    .add-manager-modal {

        .MuiInputBase-input {
            font-size: 12px !important;
            text-overflow: ellipsis;
        }

        .manager-modal-heading {
            font-size: 20px !important;
        }

        .continueMail {
            height: 49px !important;
        }

        .continueMailDisabled {
            height: 49px !important;
        }
    }

}

@media (min-width:575px) and (max-width:770px) {
    .add-manager .add-card .add-manager-description {
        font-size: 13px !important;
    }
}

@media (min-width:361px) and (max-width:575px) {
    .add-manager .add-card .add-manager-description {
        font-size: 15px !important;
    }

    // .add-manager .add-card .search-btn-manager {
    //     position: fixed !important;
    //     margin-top: 165px !important;
    //     margin-right: 0px !important;
    // }
}

@media (max-width:360px) {
    .add-manager .add-card .add-manager-description {
        font-size: 14px !important;
    }
}