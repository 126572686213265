.SelectSection {
    font-family: 'Roboto', sans-serif !important;
    padding: 10px !important;
}

.first-heading {
    font-size: 40px;
    font-weight: 600;
    color: #0075CA;
    display: flex;
    justify-content: center;
}

.alignment-center {
    text-align: center;
}

.description {
    font-size: 20px;
    display: flex;
    justify-content: center;
}

.subscriptionButton {
    display: flex;
    flex-direction: column;
    // gap: 20px; 
}

.item-description {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #505050;
}

.below-description {
    margin-top: 3px;
    margin-left: 2px;
    font-size: 16px;
    font-weight: 500;
    color: #919CA5;
}

.subscription-box-container-change {
    display: flex;
    justify-content: center;
    flex-direction: column;
    // width: 280px;
    // margin-right: 15px;

    .item-heading {
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 10px;
        color: #505050;
    }

    .item-below-description {
        margin-top: 5px;
        margin-left: 8px;
        font-size: 16px;
        font-weight: 500;
        color: #919CA5;
    }

    .subscribe-button {
        cursor: pointer;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;

        .amount-term {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0075CA;
            font-size: 24px;
            font-weight: 700;
        }

        .listing-amount {
            color: #505050;
            font-weight: 400;
            font-size: 20px;
            padding-left: 5px;
        }
    }
}

.subscription-row {
    .subscription-box-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        // width: 280px;
        // margin-right: 15px;

        .item-heading {
            display: flex;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            margin-top: 15px;
            margin-bottom: 10px;
            color: #505050;
        }

        .item-below-description {
            margin-top: 5px;
            margin-left: 8px;
            font-size: 16px;
            font-weight: 500;
            color: #919CA5;
        }

        .subscribe-button {
            cursor: pointer;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;

            .amount-term {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0075CA;
                font-size: 24px;
                font-weight: 700;
            }

            .listing-amount {
                color: #505050;
                font-weight: 400;
                font-size: 20px;
                padding-left: 5px;
            }
        }
    }

    .subscription-box-one-time-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        // width: 430px;
        // margin-right: 15px;

        .item-heading {
            display: flex;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            margin-top: 15px;
            margin-bottom: 10px;
            color: #505050;

            @media (max-width:575px) {
                font-size: 16px !important;
            }
        }

        .item-below-description {
            margin-top: 5px;
            margin-left: 8px;
            font-size: 16px;
            font-weight: 500;
            color: #919CA5;

            @media (max-width:575px) {
                margin-top: 0px !important;
            }
        }

        .subscribe-button {
            cursor: pointer;
            height: 110px;

            .amount-term {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0075CA;
                font-size: 24px;
                font-weight: 700;
                padding-top: 15px;
            }

            .amount-term-listing {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0075CA;
                font-size: 24px;
                font-weight: 700;
            }
        }

        .listing-amount {
            color: #505050;
            font-weight: 400;
            font-size: 20px;
            padding-left: 5px;
        }
    }
}



.paypal-select {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.payment_method {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.credit_button {
    height: 60px;
    width: 120px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.credit_button.clicked {

    outline: 1px solid #0075CA !important;

}


.term_timeperiod {
    color: #919CA5;
    font-size: 16px;
    font-weight: 500 !important;
}

.term {
    color: #505050;
    font-weight: 600;
    font-size: 20px;
    height: 5vh;
    text-align: center;
    // line-height: 28px;
}

.footer {
    padding-top: 20px;
    text-align: center;
}

.or {
    font-size: 24px;
    font-weight: 600;
}

.footerlink {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #0075CA;
    cursor: pointer;
}

.long-button {
    border: 2px solid #E3A300 !important;
    background: none;
    padding: 15px;
    height: 180px;
}

.subs_button {
    padding: 15px;
    background: none;
    // border: 2px solid #505050;
    height: 80px;
}

// .subs_button:hover{
//     border: 3px solid #0075CA;

// }
.subs_button_gold {
    height: 160px;
    background: none;
}

.subs_button_onetime {
    padding: 15px;


    height: 130px;
    text-align: center;
}

// .subs_button_onetime:hover{
//     border: 3px solid #0075CA;

// }
.subs_button_one_time {
    padding: 15px;
    background: none;

    height: 160px;
    text-align: center;
}

// .calender {
//     display: flex;
//     align-items: center
// }

.mainContent {
    margin-top: 100px;

    .react-datepicker-wrapper {
        float: right !important;
    }
}

.col_mainContent {
    // display: flex;
    // justify-content: center;

    .MuiInputBase-root {
        font-size: 20px;
        color: #0075CA;
        font-weight: 600;
    }

    .DepositAmount {
        .MuiInputBase-root {
            color: #505050;
            font-weight: 400;
        }

        // .DepositAmount-value {
        //     margin-left: 100px !important;
        // }
    }

    .amount-date {
        @media (max-width:575px) {
            margin-top: 20px !important;
        }

        @media (min-width:1025px) {
            margin-top: -12px !important;
        }

        @media (min-width:760px) and (max-width:1025px) {
            margin-top: -6px !important;
        }

    }
}

.amountterm {
    color: #0075CA;
    font-size: 24px;
    font-weight: 700;
}

.listing_amount {
    color: #505050;
    font-weight: 400;
    font-size: 20px;
}

.payment_method_buttons {
    display: flex;
    justify-content: center;
    gap: 5px;

    .payment_method_button {

        width: 30%;
    }
}

.react-datepicker {
    font-family: Arial, Helvetica, sans-serif;
}

.react-datepicker__day--disabled {
    color: #ccc;
    /* Color for disabled dates */
}

.react-datepicker__day--selected {
    background-color: #007bff;
    color: white;
}

.react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: white;
}

// @media (max-width:1024px) {
//     .subscription-row .subscription-box-container .item-heading {
//         font-size: 14px !important;
//     }

//     .subscription-row.subscription-box-container .item-below-description {
//         margin-top: 0px !important;
//     }

//     .subscription-row .subscription-box-container .subscribe-button .amount-term {
//         font-size: 18px !important;
//     }

//     .subscription-row .subscription-box-container .subscribe-button .listing-amount {
//         font-size: 15px !important;
//     }

//     .subscription-row.subscription-box-one-time-container .item-heading {
//         font-size: 15px !important;
//     }

//     .subscription-row .subscription-box-one-time-container .item-below-description {
//         margin-top: 0px !important;
//     }
// }

@media screen and (max-width:991px) {
    .col_mainContent {
        margin-bottom: 15px !important;

    }

    .term {
        padding-top: 15px;
        font-size: 16px;
        height: 6vh !important;
    }

    // .term_timeperiod{

    //     font-size: 14px !important;

    // }
    .term_timeperiod {
        font-size: 14px !important;
    }
}

@media (min-width:1600px) and (max-width:1700px) {
    .col_mainContent .DepositAmount .DepositAmount-value {
        margin-left: 15px !important;
    }
}

@media (min-width:1500px) and (max-width:1600px) {
    .col_mainContent .DepositAmount .DepositAmount-value {
        margin-left: 15px !important;
    }
}

@media (max-width:768px) {
    .col_mainContent .DepositAmount .DepositAmount-value {
        margin-left: 15px !important;
    }

    .col_mainContent .DepositAmount .date-rent-field {
        margin-right: 30px !important;
    }

}

@media (min-width:768px) and (max-width:1199px) {
    .first-heading {
        font-size: 32px !important;
    }

    .mainContent {
        margin-top: 32px;
    }
}

@media (max-width:1199px) {
    .subscription-row {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }
}

@media screen and (min-width:992px) {
    .col_mainContent {
        margin-bottom: 15px !important;
    }

    // .mainContent {
    //     margin-top: 70px;
    // }

    .subscriptionbuttons {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .subscription-row .subscription-box-container {
        margin-bottom: 15px !important;
    }

    .process-value {
        margin-top: 15px !important;
    }
}

.mainContent .dollar {
    font-size: 32px;
    margin-top: 16px;
    margin-right: -5px;
    font-weight: 600;
    color: black;
}

@media (max-width:767px) {
    .mainContent {
        margin-top: 7px !important;

        .first-heading {
            font-size: 16px !important;
            justify-content: start !important;
            margin-left: 10px !important;
        }

        .description {
            font-size: 14px !important;
            text-align: start;
            padding-right: 5px;
            margin-left: 10px;
        }

        .dollar {
            font-size: 24px !important;
        }

        .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart {
            font-size: 24px !important;
        }

        .MuiSelect-select {
            font-size: 16px !important;
        }

        .lableContent {
            font-size: 12px !important;
        }

        .MuiInputLabel-root {
            font-size: 16px !important;
        }
    }

    .main_calender {
        .monthyear {
            font-size: 16px !important;
        }
    }

    .col_mainContent .DepositAmount {
        // margin-top: -5px !important;

        .DepositAmount-value {
            margin-left: 0px !important;
        }
    }

    .currentPlan {
        width: 360px !important;
    }

    .progressBar {
        width: 360px !important;
    }

    .custom-header {
        .forward {
            width: 32px;
            height: 32px;
        }

        .days {
            font-size: 16px !important;
        }
    }

    .main_calender .react-datepicker__week {
        font-size: 16px !important;
    }

    .modal-container {
        .dateRange {
            font-size: 16px !important;
        }

        // .close{
        //     width: 32px;
        //     height: 32px; 
        // }
    }

    .custom-header .date_month {
        padding: 5px 30px 5px 18px !important;
    }

    .modal-container .calender_header {
        padding: 20px 32px 0px 18px !important;
    }

    .checkbox-final {
        height: 13px !important;
    }
}

@media (min-width:1030px) {
    .col_mainContent .DepositAmount {
        margin-top: -6px !important;
    }
}

.currentPlan {
    width: 390px;
}

.progressBar {
    width: 390px;
}

.custom-header {
    .days {
        font-size: 20px,
    }
}

.modal-container {
    .dateRange {
        font-size: 24px;
    }
}

@media (min-width:760px) and (max-width:770px) {
    .saveButton-price {
        margin-right: 6px !important;
    }

    .backButton-price {
        margin-left: -24px !important;
    }

}

@media (min-width:770px) and (max-width:1030px) {
    .saveButton-price {
        margin-right: 27px !important;
    }

    .backButton-price {
        margin-left: 0px !important;
    }
}

@media (min-width:1100px) {
    .saveButton-price {
        margin-right: 50px !important;
    }

    .backButton-price {
        margin-left: 50px !important;
    }
}

@media (max-width:575px) {
    .saveExit-price {
        margin-left: -7px !important;
    }

    .saveButton-mobile-price {
        margin-right: -7px !important;
    }
}