.relist-section {
    margin-top: 100px;
    width: 100%;
    text-align: center;

    .price-heading {
        font-size: 40px;
        font-weight: 600;
        color: #0075CA;
    }

    .price-description {
        font-size: 20px;
        font-weight: 400;
    }

    .MuiInputBase-root {
        font-size: 20px;
        color: #0075CA;
        font-weight: 600;
    }

    .negotiable-heading {
        font-size: 18px;
        font-weight: 500;
        color: #505050;
        margin-left: 10px;
    }

    .subscription-heading {
        font-size: 24px;
        font-weight: 700;
        margin-top: 30px;
    }

    .DepositAmount-rent {
        .MuiInputBase-root {
            color: #505050;
            font-weight: 400;
        }
    }

    .subscription-relist {
        display: flex;
        justify-content: center;

        .subscriptionBox {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 308px;
            // margin-right: 15px;

            .item-heading {
                display: flex;
                justify-content: center;
                font-size: 20px;
                font-weight: 600;
                margin-top: 15px;
                margin-bottom: 10px;
                color: #505050;
            }

            .item-below-description {
                margin-top: 5px;
                margin-left: 2px;
                font-size: 16px;
                font-weight: 500;
                color: #919CA5;
            }

            .subscribe-button {
                height: 86px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            .listing-amount {
                color: #505050;
                font-weight: 400;
                font-size: 20px;
                padding-left: 5px;
            }
        }

    }


    .subscription-relist-onetime {
        display: flex;
        justify-content: center;

        .subscription-box-one-time {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 430px;
            // margin-right: 15px;

            .item-heading {
                display: flex;
                justify-content: center;
                font-size: 20px;
                font-weight: 600;
                margin-top: 15px;
                margin-bottom: 10px;
                color: #505050;
            }

            .item-below-description {
                margin-top: 5px;
                margin-left: 2px;
                font-size: 16px;
                font-weight: 500;
                color: #919CA5;
            }

            .subscribe-button {
                cursor: pointer;
                height: 110px;

                .amount-term {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #0075CA;
                    font-size: 24px;
                    font-weight: 700;
                    padding-top: 15px;
                }

                .amount-term-listing {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #0075CA;
                    font-size: 24px;
                    font-weight: 700;
                }
            }

            .listing-amount {
                color: #505050;
                font-weight: 400;
                font-size: 20px;
                padding-left: 5px;
            }
        }
    }

    .one-time-description {
        font-size: 20px;
        font-weight: 400;
        margin-top: 7px;
        color: #505050;
    }

    .footer-container {
        margin-top: 20px;
        text-align: center;

        .other-heading {
            font-size: 24px;
            font-weight: 600;
            color: #505050;
        }

        .footer-link {
            font-size: 20px;
            font-weight: 600;
            color: #0075CA;
            text-decoration: underline;
            cursor: pointer;
        }
    }

}

.fixed-bottom-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
}


@media (max-width:1024px) {
    .subscription-relist {
        justify-content: start !important;
    }
}

@media (max-width:1199px) {
    .relist-section {
        margin-top: 10px !important;
    }
}