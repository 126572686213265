.payment-card-seaction {
    // width: 730px;
    height: 330px;
    background-color: #ffffff;
    padding: 15px;
    box-shadow: 0px 2px 8px 0px #0000001F;

    @media (max-width:575px) {
        margin: 0px 10px 0 10px !important;
    }


    .payment-heading {
        font-size: 18px;
        font-weight: 500;
        padding-top: 10px;
    }

    .payment-card-method {
        // width: 700px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding-top: 70px;

        @media (max-width:600px) {
            font-size: 16px !important;
        }
    }

    .payment-save-card {
        width: 700px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 90px;
        padding-top: 15px;
        cursor: pointer;

        .payment-save-card-heading {
            font-size: 18px;
            font-weight: 600;
            color: #0075CA;
            padding-top: 5px;
            padding-left: 10px;
        }
    }
}