.listing-detail-data {
    box-shadow: 0px 2px 8px 0px #0000001F;
    margin-top: 5px !important;

    .listing-data {
        padding-top: 10px !important;
        margin-top: 0 !important;

        .tenantProfile {
            width: 80px;
            height: 80px;
            border: 1px solid grey;
            border-radius: 50%;
        }
    }

    .view-heading {
        color: #0075CA !important;
        text-decoration: underline !important;
        padding-left: 30px;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;

    }

}

.tenant-manager-tab {

    font-family: 'Proxima_nova_medium';
    // margin-left: 20px;

    .MuiTabs-root {
        width: 350px;
        border: 0.5px solid #909090;

        @media (max-width:575px) {
            width: 100% !important;
            min-height: 40px !important;
        }
    }

    .MuiTab-root {
        min-width: 175px;

        @media (max-width:575px) {
            min-width: 50% !important;
            min-height: 40px !important;
        }
    }

    .MuiTab-wrapper {
        @media (max-width:575px) {
            font-size: 12px !important;
        }
    }

    .MuiAppBar-colorPrimary {
        color: #000000;
        background-color: #ffffff !important;
    }

    .MuiButtonBase-root {
        border-right: 1px solid #909090;
    }

    .MuiTab-textColorInherit.Mui-selected {
        background-color: #2975ca !important;
        color: #ffffff;

    }

    .MuiTabs-indicator {
        background-color: transparent !important;
    }

    .date {
        font-weight: 600;
        font-family: 'Proxima_nova_medium';
    }

}

.tenant-list-tab-card {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #ffffff;

    @media (min-width:700px) and (max-width:768px) {
        margin-right: 20px !important;
    }

    @media (max-width:575px) {
        .MuiBox-root {
            padding: 0px !important;
        }

    }

    .tenant-tab-card {

        .MuiMobileStepper-root {
            display: flex;
            align-items: center;
            background-color: transparent;
            color: #ffff !important;
            margin-top: -94px !important;
            margin-left: -20px !important;
            margin-right: -23px !important;

            @media (min-width:431px) and (max-width:575px) {
                margin-top: -190px !important;
            }

            @media (max-width:430px) {
                margin-top: -124px !important;
            }

        }

        .MuiPaper-root {
            background-color: transparent !important;
            color: #ffff !important;
        }

        .MuiButtonBase-root {
            display: inline-block;
            justify-content: inherit !important;
        }

        .tenant_detail {
            font-weight: 600;
            font-family: Proxima_nova_medium;
            font-size: 14px;
            color: #000000;
        }

        .tenant-detail-payments {
            font-weight: 400;
            font-family: Proxima_nova_medium;
            font-size: 14px;
            color: #919CA5;

        }

        .payments {
            color: #919CA5;
            font-weight: 500;
            font-size: 14px !important;
        }

        .listing-card-detail {

            @media (max-width:575px) {
                padding: 0 10px 10px 10px !important;
            }

            .mobile-subscription {
                @media (min-width:430px) and (max-width:575px) {
                    margin-top: 150px !important;
                }

                @media (max-width:430px) {
                    margin-top: 85px !important;
                }
            }

            .listing-user-heading {
                font-size: 23px;
                font-weight: 600;
                color: #2F86D1;
            }

            .listing-user-month {
                display: flex;
                align-self: center;
                color: #2F86D1;
                margin-top: 5px;
                font-size: 16px;
                font-weight: 600;
            }

            .request-profile {
                background-color: #2F86D1;
                color: #ffffff;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 600;
                padding-right: 3px;
                padding-top: 3px;
                cursor: pointer;
            }

            .categroy-heading {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .categroy-heading-type {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
                font-family: "Proxima_nova_medium";
                display: flex;
                align-items: center;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }

            .category-value {
                font-size: 16px;
                font-weight: 600;
                margin-left: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .select-value-heading {
                font-size: 16px;
                font-weight: 600;
                margin-top: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .request-genarte {
                font-size: 18px;
                font-weight: 600;
                margin-right: 20px;
                color: #2F86D1;
                margin-top: 10px;
                text-decoration: underline;
                cursor: pointer;
            }

            .user-heading {
                font-size: 18px !important;
                font-weight: 600 !important;
                font-family: "Proxima Nova";
            }

            .user-dot {
                font-size: 20px;
                font-weight: 800;
                margin: -6px 6px 0 6px;
            }

            .property-owner {
                color: #707070;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
            }

            .view-heading {
                font-size: 18px;
                font-weight: 600;
                color: #2F86D1;
                text-decoration: underline;
            }

        }

    }

    .view-heading {
        font-size: 16px;
        font-weight: 600;
        color: #2F86D1;
        text-decoration: underline;
        text-align: right;
    }

    // .listing-card-detail {

    //     .listing-user-heading {
    //         font-size: 23px;
    //         font-weight: 600;
    //         color: #2F86D1;
    //     }

    //     .listing-user-month {
    //         display: flex;
    //         align-self: center;
    //         color: #2F86D1;
    //         margin-top: 5px;
    //         font-size: 16px;
    //         font-weight: 600;
    //     }

    //     .request-profile {
    //         background-color: #2F86D1;
    //         color: #ffffff;
    //         width: 30px;
    //         height: 30px;
    //         border-radius: 50%;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         font-size: 20px;
    //         font-weight: 600;
    //         padding-right: 3px;
    //         padding-top: 3px;
    //         cursor: pointer;
    //     }

    //     .categroy-heading {
    //         color: #707070;
    //         font-size: 14px;
    //         font-weight: 500;
    //         margin-left: 8px;
    //         white-space: nowrap;
    //         overflow: hidden;
    //         text-overflow: ellipsis;
    //     }

    //     .category-value {
    //         font-size: 16px;
    //         font-weight: 600;
    //         margin-left: 5px;
    //     }

    //     .select-value-heading {
    //         font-size: 16px;
    //         font-weight: 600;
    //         margin-top: 10px;
    //     }

    //     .request-genarte {
    //         font-size: 18px;
    //         font-weight: 600;
    //         margin-right: 20px;
    //         color: #2F86D1;
    //         margin-top: 10px;
    //         text-decoration: underline;
    //         cursor: pointer;
    //     }

    //     .user-heading {
    //         font-size: 18px !important;
    //         font-weight: 600 !important;
    //         font-family: "Proxima Nova";
    //     }

    //     .user-dot {
    //         font-size: 20px;
    //         font-weight: 800;
    //         margin: -6px 6px 0 6px;
    //     }

    //     .property-owner {
    //         color: #707070;
    //         font-size: 16px;
    //         font-weight: 500;
    //         display: flex;
    //         align-items: center;
    //     }

    //     .view-heading {
    //         font-size: 18px;
    //         font-weight: 600;
    //         color: #2F86D1;
    //         text-decoration: underline;
    //     }

    // }

    .date {
        font-weight: 600;
        font-size: 16px;
        font-family: 'Proxima_nova_medium';
        color: #505050;

    }

    .amount {
        color: #00A84D;
        font-weight: 600;
        font-size: 20px;
        font-family: 'Proxima_nova_medium';
    }

    .tenet-past-conatiner {
        background-color: #ffffff;
        width: 100%;
        height: auto;
        // margin-top: 10px;

        .past-tenet-date {
            font-size: 16px;
            font-weight: 600;
            font-family: Proxima_nova_medium;
        }

        .past-tenet-price {
            color: #00A84D;
            font-weight: 600;
            font-size: 20px;
            font-family: Proxima_nova_medium;
        }

    }

}

.tenet-past-empty {
    // background-color: #ffffff;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width:575px) and (max-width:768px) {
    .tenant-tab-card .MuiMobileStepper-root {
        margin-top: -100px !important;
    }
}

// @media (max-width:575px) {
//     .listing-card-detail .mobile-subscription {
//         margin-top: 55px !important;
//     }
// }