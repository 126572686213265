.nav-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px !important;

  @media (max-width:575px) {
    display: none !important;
  }

  .search-btn {
    background-color: #2F86D1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 120px;
    border-radius: 4px;
    // margin-right: 7px;

    @media (max-width:690px) {
      width: 48px !important;
      // padding-left: 15px !important;
    }
  }

  .MuiInputBase-root {
    display: flex !important;
    justify-content: space-between !important;
  }

  .MuiIconButton-root {
    margin-right: 10px !important;
  }

}

.slash-value {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-right: 15px;
}

.custom-textfield {

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.filter_items {
  display: block !important;
  padding: 10px !important;
}

.MuiStack-root.css-10o2lyd-MuiStack-root {
  overflow: hidden !important;
  padding-top: 0px !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  width: 50% !important;
}

.Mui-selected {
  background-color: transparent !important;
}

.Mui-selected :hover {
  background-color: transparent !important;
}

.custom-textfield .MuiInputBase-input {
  color: #000000;
  font-weight: 600;
  font-family: 'Proxima_nova_medium';
}

.custom-textfield .MuiInputLabel-root {
  color: #000000;
  font-weight: 400;
  font-family: 'Proxima_nova_medium';
}

.custom-textfield .MuiInput-underline:before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.custom-textfield .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.custom-textfield .MuiInput-underline:after {
  border-bottom-color: #0075CA;
}

.custom-textfield .Mui-focused .MuiInputLabel-root {
  color: blue;
}

.MuiStack-root {
  overflow: hidden !important;
  padding-top: 0px !important;
}

.MuiStack-root.css-sx5hge {
  padding-top: 0px !important;
}

.mobile-section-property {
  position: fixed;
  padding-bottom: 20px !important;
  margin-right: 8px !important;
  display: none !important;
  width: 96% !important;

  @media (max-width:575px) {
    display: flex !important;
  }

  @media (min-width:400px) and (max-width:430px) {
    width: 97% !important;
  }

  @media (min-width:440px) and (max-width:540px) {
    width: 100% !important;
  }

  .search-btn {
    background-color: #2F86D1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 48px;
    border-radius: 4px;
  }

  .MuiInputBase-root {
    display: flex !important;
    justify-content: space-between !important;
  }


  .MuiIconButton-root {
    margin-right: 3px !important;
  }

}

.modal-container-search {
  padding: 20px 10px 10px 10px;

  .filter-heading {
    font-size: 20px;
    font-weight: 600;
    color: #101010;
    font-family: Proxima_nova_medium;
  }

  .modal-price {
    font-size: 16px;
    font-weight: 600;
    color: #101010;
    font-family: Proxima_nova_medium;
    margin-top: 20px;
  }

  .MuiStack-root>.MuiTextField-root {
    min-width: 100% !important;
  }

  .modal-button {
    margin-top: 10px;
    margin-left: 1px;
  }
}