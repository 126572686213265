// .property-manager-detail {

// .listing-detail-data {
//     // width: 760px;
//     height: auto;
//     background-color: #ffffff;
//     // margin-left: 20px;
//     padding: 20px 20px 36px 0;

//     .listing-data {
//         margin-top: -26px;
//     }

//     .listing-data-heading {
//         font-size: 20px;
//         font-weight: 500;
//         margin-top: 5px;
//     }

//     .phone-container {
//         display: flex;
//         justify-content: center;

//         .phone-number {
//             font-size: 16px;
//             font-weight: 400;
//             color: #707070;
//             padding-left: 8px;
//         }

//         .msg-box {
//             width: 180px;
//             height: 50px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             margin-bottom: 20px;
//         }
//     }

//     .email-conatiner {
//         display: none !important;

//         .phone-number {
//             font-size: 16px;
//             font-weight: 400;
//             color: #707070;
//             padding-left: 8px;
//         }

//         .msg-box {
//             width: 180px;
//             height: 50px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             cursor: pointer;
//             background-color: #2975CA;
//         }

//         .msg-request-box {
//             width: 180px;
//             height: 50px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             cursor: pointer;
//             background-color: #D9EFFF;
//             color: #0075CA;
//         }

//         .msg-request-box-receive {
//             width: 180px;
//             height: 50px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             cursor: pointer;
//             background-color: #0075CA;
//             color: #ffffff;
//         }
//     }

// }

.listing-tab-detail {
    // width: 760px;
    margin-top: 20px;

    @media (min-width:700px) and (max-width:770px) {
        margin-right: 15px !important;
    }

    .listing-managed-heading {
        font-size: 18px;
        font-weight: 500;
        margin-left: 8px;
    }

    .request-heading {
        font-size: 20px;
        font-weight: 500;
        color: #2F86D1;
        margin-right: 8px;
        text-decoration: underline;
        cursor: pointer;
    }

    // }


}

.request-modal {
    // padding: 5px 10px;


    .request-heading {
        font-size: 24px;
        font-weight: 500;
        color: #303030;
        padding-top: 10px;
    }

    .search-model {
        width: 45px;
        height: 25px;
    }

    .request-modal-section {
        margin-top: 10px !important;
        height: 520px;
        overflow-y: scroll;
        overflow-x: hidden;

        @media (min-height: 850px) and (max-height:897px) {
            height: 450px !important;
        }

        @media (min-height: 700px) and (max-height:740px) {
            height: 340px !important;
        }

        @media (min-width: 420px) and (max-width:431px) {
            height: 470px !important;
        }

        @media (min-width: 400px) and (max-width:413px) {
            height: 460px !important;
        }

        @media (min-width: 380px) and (max-width:400px) {
            height: 415px !important;
        }

        @media (min-width:361px) and (max-width:375px) {
            height: 285px !important;
        }

        @media (max-width:360px) {
            height: 340px !important;
        }

        .request-modal-card {
            // width: 900px;
            height: auto;
            background-color: #ffffff;
            margin-top: 10px;
            padding: 8px;

            .MuiMobileStepper-root {
                display: flex;
                align-items: center;
                background-color: transparent;
                color: #ffff !important;
                margin-top: -130px !important;
                margin-left: -20px !important;
                margin-right: -23px !important;
            }

            .MuiPaper-root {
                background-color: transparent !important;
                color: #ffff !important;
            }

            .MuiButtonBase-root {
                display: inline-block;
                justify-content: inherit !important;
            }

            .listing-heading {
                padding-top: 90px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .listing-card-detail {

                .listing-user-heading {
                    font-size: 23px;
                    font-weight: 600;
                    color: #2F86D1;
                }

                .listing-user-month {
                    display: flex;
                    align-self: center;
                    color: #2F86D1;
                    margin-top: 5px;
                    font-size: 16px;
                    font-weight: 600;
                }

                .request-profile {
                    background-color: #2F86D1;
                    color: #ffffff;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 600;
                    padding-right: 3px;
                    padding-top: 3px;
                    cursor: pointer;
                }

                .categroy-heading {
                    color: #707070;
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 8px;
                    margin-top: 3px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .categroy-heading-type {
                    color: #707070;
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 8px;
                    font-family: "Proxima_nova_medium";
                    display: flex;
                    align-items: center;
                    // white-space: nowrap;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                }

                .category-value {
                    font-size: 16px;
                    font-weight: 500;
                    margin-left: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .select-value-heading {
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .request-genarte {
                    font-size: 18px;
                    font-weight: 600;
                    margin-right: 20px;
                    color: #2F86D1;
                    margin-top: 10px;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }


    }
}

.ReactModal__Content::-webkit-scrollbar {
    display: none !important;
}

// @media (min-width:992px) and (max-width:1200px) {
//     .listing-detail-data .phone-container {
//         display: none !important;
//     }

//     .listing-detail-data .email-conatiner {
//         display: flex !important;
//         justify-content: center !important;
//     }

//     .responsive-find-list {
//         display: none !important;
//     }

//     .responsive-find-main {
//         display: inline !important;
//     }

//     // .find-people-listing-data {
//     //     height: 65vh !important;
//     // }
// }

.request-modal .request-modal-section::-webkit-scrollbar {
    width: 8px !important;
    z-index: 1 !important;
    display: inline-block !important;
}

.request-modal .request-modal-section::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}

.request-modal .request-modal-section::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
    border-radius: 3px !important;
}

.request-modal .request-modal-section::-webkit-scrollbar-thumb:hover {
    background-color: #909090 !important;
}

// @media (min-width:800px) and (max-width:1024px) {
//     .request-modal .request-modal-section {
//         height: 310px !important;
//     }
// }

@media (min-width:768px) and (max-width:1199px) {
    .request-modal .request-modal-section .request-modal-card .MuiMobileStepper-root {
        margin-top: -100px !important;
    }
}

// @media (max-width:1399px) {
//     .property-manager .listing-detail-data {
//         margin-top: 20px !important;
//     }
// }

@media (max-width: 767px) {

    .request-modal {
        padding: 10px;

        .request-heading {
            font-size: 20px !important;
        }

        .search-model {
            width: 30px;
            height: 30px;
        }

        .MuiOutlinedInput-input {
            font-size: 12px !important;
            text-overflow: ellipsis;
        }

        .buttonTwo {
            font-size: 12px !important;
        }

        .listing-card-detail {
            padding-top: 30% !important;
        }
    }

    .listing-card-detail {
        flex-direction: column;
    }
}

@media (max-width:1199px) {
    .listing-tab-detail {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}

@media (min-width:700px) and (max-width:767px) {
    .request-modal {
        .listing-card-detail {
            padding-top: 15% !important;
        }
    }
}

@media (min-width:555px) and (max-width:699px) {
    .request-modal {
        .listing-card-detail {
            padding-top: 18% !important;
        }
    }
}

@media (min-width:470px) and (max-width:554px) {
    .request-modal {
        .listing-card-detail {
            padding-top: 22% !important;
        }
    }
}