.select-list {
    // width: 400px !important;
    margin-top: 220px !important;

    // display: flex;
    // align-items: center;
    // justify-content: center;
    .mobile-room-value {
        font-size: 25px;
        font-weight: 600;
        color: #2F86D1;
    }

    .value-select {
        display: flex;

        .room-value {
            font-size: 25px;
            font-weight: 600;
            padding: 20px 20px 0 0;
            color: #2F86D1;
            display: flex;
            align-items: center;

        }

        .room-select {
            width: 210px;
            height: 60px;
            margin-top: 30px;
            padding-left: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .room-heading {
            font-size: 20px;
            // color: rgb(136 132 132);
            font-family: 'Proxima_nova_reg' !important;
            padding-left: 10px;
        }

        .room-image {
            width: 46px;
            height: 46px;
        }
    }
}






.back-heading {
    font-size: 28px;
    font-weight: 500;
}

.stay-heading {
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-right: 30px;
    cursor: pointer;
}

@media (min-width:1064px) and (max-width:1199px) {
    .select-list {
        margin-top: 0 !important;
        margin-bottom: 32px !important;
    }

    .select-list .value-select {
        justify-content: center !important;
    }

    .select-list .value-select .room-value {
        position: absolute !important;
        left: 260px !important;
        padding-top: 40px !important;
    }

}

@media (min-width:964px) and (max-width:1064px) {
    .select-list {
        margin-top: 0 !important;
        margin-bottom: 32px !important;
    }

    .select-list .value-select {
        justify-content: center !important;
    }

    .select-list .value-select .room-value {
        position: absolute !important;
        left: 200px !important;
        padding-top: 40px !important;
    }

}

@media (min-width:851px) and (max-width:964px) {
    .select-list {
        margin-top: 0 !important;
        margin-bottom: 32px !important;
    }

    .select-list .value-select {
        justify-content: center !important;
    }

    .select-list .value-select .room-value {
        position: absolute !important;
        left: 150px !important;
        padding-top: 40px !important;
    }

}

@media (min-width:768px) and (max-width:851px) {
    .select-list {
        margin-top: 0 !important;
        margin-bottom: 32px !important;
    }

    .select-list .value-select {
        justify-content: center !important;


    }

    .select-list .value-select .room-value {
        position: absolute !important;
        left: 100px !important;
        padding-top: 40px !important;
    }

}

@media (max-width:767px) {
    .select-list {
        margin-top: 10% !important;
        margin-bottom: 32px !important;
        margin-left: 10px !important;

    }

    .mobile-buttons {
        margin-left: 16px !important;
    }

    .list {
        display: flex;
        gap: 10px;
        // flex-direction: column;
        align-items: center;
        overflow-x: scroll;
    }

    .mobile-room-value {
        font-size: 24px;
        font-weight: 600;
        padding: 20px 20px 0 0;
        color: #2F86D1;
        text-align: center;
    }

    .buttons {
        margin-top: 20px !important;
        justify-content: center !important;
        overflow: hidden;
        margin-bottom: 10px;
        display: none;
    }

    .backButton {
        display: none;
    }

    .saveExitButton {
        width: 147px;
    }

    .saveButton {
        margin-right: 0px !important;
    }

    .select-list .value-select {
        justify-content: center !important;


        .room-heading {
            font-size: 16px !important;
        }

        .room-image {
            width: 32px !important;
            height: 32px !important;
        }

        .room-select {
            width: 190px !important;
        }
    }

}

.saveExitButton {
    width: 200px;

}

@media (min-width:767px) {
    .saveButton {
        margin-right: 50px !important;
    }

    .buttons {
        margin: 20px 0 20px 30px;
        justify-content: space-between;
    }

    .select-list .value-select .room-select {
        width: 190px !important;
    }
}

@media (max-width:575px) {
    .saveButton-mobile {
        margin-right: 15px !important;
    }
}

@media (min-width:760px) and (max-width:770px) {
    .saveButton-continue {
        margin-right: 15px !important;
    }

    .backButton-ipaid {
        margin-left: -15px !important;
    }
}

@media (min-width:770px) and (max-width:1030px) {
    .saveButton-continue {
        margin-right: 35px !important;
    }

    .backButton-ipaid {
        margin-left: 15px !important;
    }
}

@media (min-width:1100px) {
    .saveButton-continue {
        margin-right: 50px !important;
    }
}