.my-listing-tabs {
    position: fixed;
    margin-bottom: 20px;
    width: 100% !important;

    @media (max-width:575px) {
        margin-top: -10px !important;
    }

    .tab-value {
        margin: 20px 13px 25px 13px;

        @media (max-width:575px) {
            margin: 20px 13px 25px 13px !important;
        }
    }


    .MuiAppBar-colorPrimary {
        color: #000000;
        background-color: #ffffff !important;
    }

    .MuiTab-root {
        min-width: 175px !important;

        // @media (min-width:390px) and (max-width:575px) {
        //     min-width: 134px !important;
        // }

        // @media (min-width:375px) and (max-width:390px) {
        //     min-width: 121px !important;
        // }

        // @media (max-width:375px) {
        //     min-width: 118px !important;
        // }

        @media (max-width:575px) {
            min-width: 33.33% !important;
        }
    }

    .MuiTabs-root {
        width: 525px;
        border: 0.5px solid #909090;

        @media (min-width:375px) and (max-width:575px) {
            width: 100% !important;
            min-height: 40px !important;
        }

        @media (max-width:375px) {
            width: 100% !important;
            margin-right: 10px !important;
        }
    }

    .MuiTab-wrapper {
        @media (max-width:575px) {
            font-size: 12px !important;
        }
    }

    .MuiButtonBase-root {
        border-right: 1px solid #909090;
        background-color: #ffffff;
    }

    .MuiTab-textColorInherit.Mui-selected {
        background-color: #2975ca !important;
        color: #ffffff;

    }

    .MuiTabs-indicator {
        background-color: transparent !important;
    }

}

.my-listing-tab-card {
    margin-top: 80px;

    @media (max-width:575px) {
        overflow-x: hidden !important;
    }

    .MuiBox-root {
        padding: 10px !important;
    }

    .MuiMobileStepper-root {
        display: flex;
        align-items: center;
        background-color: transparent;
        color: #ffff !important;
        margin-top: -124px !important;
        margin-left: -10px !important;
        margin-right: -23px !important;

        @media (min-width:431px) and (max-width:575px) {
            margin-top: -190px !important;
        }
    }

    .MuiPaper-root {
        background-color: transparent !important;
        color: #ffff !important;
    }

    .MuiButtonBase-root {
        display: inline-block;
        justify-content: inherit !important;
    }

    .tab-panel-value {
        // width: 900px;
        height: 76vh;
        overflow-y: scroll;
        z-index: 2 !important;


        @media (max-width:575px) {
            height: auto !important;
            overflow-x: hidden !important;
        }

        .listing-card-detail {

            @media (max-width:575px) {
                padding: 10px 13px 10px 20px !important;
            }

            .mobile-section {
                @media (min-width:431px) and (max-width:574px) {
                    margin-top: 140px !important;
                }

                @media (max-width:430px) {
                    margin-top: 73px !important;
                }
            }

            .listing-user-heading {
                font-size: 23px;
                font-weight: 600;
            }

            .listing-user-month {
                display: flex;
                align-self: center;
                // margin-top: 5px;
                font-size: 20px;
                font-weight: 600;
                margin-top: 0px !important;
            }

            .view-heading {
                font-size: 14px;
                font-weight: 600;
                color: #2F86D1;
                text-decoration: underline;
            }

            .request-profile {
                background-color: #2F86D1;
                color: #ffffff;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 600;
                // padding-right: 3px;
                // padding-top: 3px;
                cursor: pointer;
            }

            .categroy-heading {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
                margin-top: 3px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .category-value {
                font-size: 18px;
                font-weight: 600;
                margin-left: 10px;
                margin-top: 1px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .select-value-heading {
                font-size: 16px;
                font-weight: 600;
                margin-top: 10px;
                color: #707070;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .request-genarte {
                font-size: 18px;
                font-weight: 600;
                margin-right: 20px;
                color: #2F86D1;
                // margin-top: 30px;
                text-decoration: underline;
                cursor: pointer;

                @media (max-width:575px) {
                    margin-right: 0px !important;
                }
            }

            .request-genarte-mobile {
                font-size: 18px;
                font-weight: 600;
                margin-right: 20px;
                color: #2F86D1;
                margin-top: 3px;
                text-decoration: underline;
                cursor: pointer;

                @media (max-width:575px) {
                    margin-right: 0px !important;
                }
            }
        }
    }

    .empty-my-list {
        width: 840px;
        background: white;
        height: 230px;
        // margin-left: -10px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width:564px) and (max-width:676px) {
            width: 550px !important;
            margin-left: -6px;
        }

        @media (min-width:500px) and (max-width:564px) {
            width: 517px !important;
            margin-bottom: 240px !important;
        }

        @media (min-width:420px) and (max-width:430px) {
            width: 410px !important;
            margin-bottom: 450px !important;
        }

        @media (width:414px) and (height:896px) {
            margin-bottom: 414px !important;
        }

        @media (width:414px) and (height:736px) {
            margin-bottom: 310px !important;
        }


        @media (min-width:413px) and (max-width:420px) {
            width: 394px !important;
        }

        @media (min-width:400px) and (max-width:413px) {
            width: 390px !important;
            margin-bottom: 435px !important;
        }

        @media (min-width:376px) and (max-width:400px) {
            width: 369px !important;
            margin-bottom: 365px !important;
        }

        @media (min-width:361px) and (max-width:375px) {
            width: 354px !important;
            margin-bottom: 185px !important;
        }

        @media (max-width:360px) {
            width: 340px !important;
            margin-bottom: 260px !important;
        }

        .empty-heading {
            font-size: 20px;
            font-weight: 400;
            font-family: 'Proxima_nova_medium' !important;
        }

        .empty-description {
            font-size: 16px;
            font-weight: 400;
            display: flex;
            justify-content: center;
        }
    }
}

.modal-relist-container {
    .modal-relist-heading {
        font-size: 24px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        padding-top: 15px;
    }

    .modal-relist-description {
        font-size: 20px;
        font-weight: 400;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-right: 40px;
    }

}


.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(145, 145, 148, 0.9);
}

.myuploadmodal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent !important;
    border: none !important;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal-container {
    .modal-main-heading {
        font-size: 24px;
        font-weight: 700;
        // margin-top: -15px;

        @media (max-width:575px) {
            font-size: 20px !important;
        }
    }

    .manage-request-modal {
        // width: 36.3vw;
        height: 430px;
        cursor: pointer;
        overflow-y: scroll;

        @media (min-width:529px) and (max-width:575px) {
            height: 310px !important;
        }

        @media (min-width:376px) and (max-width:392px) {
            height: 400px !important;
        }

        @media (min-width:361px) and (max-width:375px) {
            height: 270px !important;
        }

        @media (max-width:360px) {
            height: 320px !important;
        }

        .manager-request-list {
            padding: 15px;

            .users-heading {
                padding-left: 10px;

                .user-main-heading {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                }

                .user-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #909090;
                }

                .listing-description {
                    font-size: 16px;
                    font-weight: 500;
                }
            }

        }

    }

}


.modal-container .manage-request-modal::-webkit-scrollbar {
    width: 8px !important;
    z-index: 1 !important;
    display: inline-block !important;
}


.modal-container .manage-request-modal::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}


.modal-container .manage-request-modal::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
    border-radius: 3px !important;
}


.modal-container .manage-request-modal::-webkit-scrollbar-thumb:hover {
    background-color: #909090 !important;
}

@media (min-width:1024px) and (max-width:1199px) {
    .empty-my-list {
        width: 650px !important;
    }

    .my-listing-tab-card .tab-panel-value .listing-card-detail .category-value {
        font-size: 13px !important;
    }
}

@media (min-width:991px) and (max-width:1024px) {
    .my-listing-tab-card .tab-panel-value .listing-card-detail .category-value {
        font-size: 14px !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .my-listing-tab-card .tab-panel-value .listing-card-detail .category-value {
        font-size: 14px !important;
    }

    .empty-my-list {
        width: 595px !important;
    }

}

@media (min-width:770px) and (max-width:915px) {
    .my-listing-tab-card .MuiMobileStepper-root {
        margin-top: -100px !important;
    }
}

@media (min-width:575px) and (max-width:768px) {
    .my-listing-tab-card .tab-panel-value .listing-card-detail .category-value {
        font-size: 14px !important;
    }

    .my-listing-tab-card .MuiMobileStepper-root {
        margin-top: -100px !important;
    }

}

// @media (min-width:770px) and (max-width:1024px) {
//     .modal-container .manage-request-modal {
//         height: 250px !important;
//     }
// }

@media (min-width:1024px) and (max-width:1199px) {
    .my-listing-tab-card .MuiMobileStepper-root {
        margin-top: -100px !important;
    }
}

// @media (min-width:375px) and (max-width:550px) {
//     // .my-listing-tabs .MuiTabs-root {
//     //     width: 345px !important;
//     // }

//     .my-listing-tabs .MuiTab-root {
//         min-width: 110px !important;
//     }
// }