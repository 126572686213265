.main-content {
    margin: 0 !important;
    padding: 30px 0 0 30px !important;
    width: 100%;
    align-items: center;
}

.fav-heading {
    font-size: 50px;
    font-weight: 600;
    color: #0075CA;
    font-family: Proxima_nova_medium;
}

.fav-description {
    font-size: 20px;
    // width: 655px;
    padding-top: 11px;
    font-family: Proxima_nova_medium;
}

.font_style {
    font-family: 'Proxima_nova_reg' !important;
    margin-right: 50px;
}

.back-ipaid {
    margin-left: -30px !important;

    @media (max-width:575px) {
        display: none !important;
    }
}

@media (min-width:1200px) and (max-width:1414px) {
    .main-content {
        padding: 0 !important
    }

    .fav-heading {
        font-size: 40px !important;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .main-content {
        padding: 0 !important
    }

    .fav-heading {
        font-size: 30px !important;
    }
}

@media (max-width:992px) {
    .main-content {
        padding: 30px 0 0 0 !important
    }

    .fav-heading {
        font-size: 35px !important;
    }

    .list-home {
        padding-top: 40px !important;
        padding-right: 40px !important;
        // opacity: 0.6 !important;
    }
}

@media (max-width:767px) {
    .fav-heading {
        font-size: 23px !important;
    }

    .fav-description {
        font-size: 16px !important;
        text-align: center;
    }

    .font_style {
        margin-right: 0px;
    }

    .buttons {
        margin-left: -50px;
        margin-right: 5px;
    }
}

@media (max-width:575px) {
    .fav-heading {
        font-size: 24px !important;
        text-align: center !important;
    }

    .fav-description {
        font-size: 16px !important;
        font-weight: 400;
        text-align: center;
        line-height: 22.4px;
    }
}