.tenent-manager {
    margin-top: 100px;

    @media (min-width:575px) and (max-width:1199px) {
        margin-top: 77px !important;
    }

    .manager-list-card {
        // width: 380px;

        .manager-list {
            margin: 0px 10px 0 10px;

            @media (max-width:575px) {
                margin: 10px 10px 0 10px !important;
            }

            .manager-heading {
                font-size: 16px;
                font-weight: 500;
            }

            .manager-add-heading {
                font-size: 16px;
                font-weight: 500;
                color: #2F86D1;
                padding-left: 10px;
                text-decoration: underline;
                cursor: pointer;
            }
        }


        .manager-card {
            height: 400px;
            background-color: #ffffff;
            margin-top: 10px;
            overflow-y: scroll;
            overflow-x: hidden;
            // padding: 15px;

            // @media (max-width:575px) {
            //     height: auto !important;
            // }

            .users-heading {
                padding-left: 10px;
                padding-top: 10px;
                overflow-x: hidden;

                .user-main-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #000000;
                    // max-width: 300px !important;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex-shrink: 1;
                }

                .user-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #909090;
                }
            }

            .listing-count {
                font-size: 14px;
                font-weight: 500;
                display: flex;
                align-items: center;
                margin-top: -5px;
                padding-right: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .Invite-sent {
                font-size: 14px;
                font-weight: 600;
                display: flex;
                align-items: center;
                margin-top: -15px;
                padding-right: 15px;
                color: #03B958;
            }
        }
    }

    .listing-manager-detail {

        // @media (max-width:575px) {
        //     display: none !important;
        // }

        .lising-managed-heading {
            font-size: 20px;
            font-weight: 500;
        }

        .manager-card {
            // width: 760px;
            height: 300px;
            background-color: #ffffff;
            border: 1px dashed black;
            margin-top: 10px;
            box-shadow: 0px 2px 8px 0px #0000001F;

            .select-dec {
                // width: 610px;
                height: 300px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px;

                .select-des-value {
                    font-size: 20px;
                    font-weight: 400;
                    text-align: center;
                }
            }

        }
    }

    .listing-detail-data {
        // width: 760px;
        height: auto;
        background-color: #ffffff;
        // margin-left: 20px;
        padding: 20px !important;

        @media (min-width:700px) and (max-width:768px) {
            margin-right: 20px !important;
        }

        .listing-data {
            margin-top: -26px;
        }

        .listing-data-heading {
            font-size: 20px;
            font-weight: 500;
            margin-top: 5px;
        }

        .phone-container {
            // display: flex;
            // justify-content: center;
            margin-top: 2px;

            .phone-number {
                font-size: 16px;
                font-weight: 400;
                color: #707070;
                padding-left: 8px;
                display: flex;
                // align-items: center;
            }
        }

        .msg-box {
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }

        .email-conatiner {
            display: none !important;

            .phone-number {
                font-size: 16px;
                font-weight: 400;
                color: #707070;
                padding-left: 8px;
                display: flex;
                align-items: center;
            }

        }

    }
}

@media (min-width:575px) {
    .tenent-manager .manager-list-card .manager-card::-webkit-scrollbar {
        width: 8px !important;
        z-index: 1 !important;
        display: inline-block !important;
    }

    .property-manager .manager-list-card .manager-card::-webkit-scrollbar-track {
        background-color: #f5f5f5 !important;
    }

    .tenent-manager .manager-list-card .manager-card::-webkit-scrollbar-thumb {
        background-color: #909090 !important;
        border-radius: 3px !important;
    }

    .tenent-manager .manager-list-card .manager-card::-webkit-scrollbar-thumb:hover {
        background-color: #909090 !important;
    }

}


@media (min-width:1025px) and (max-width:1200px) {
    .tenent-manager .listing-detail-data .phone-container {
        display: none !important;
    }

    .tenent-manager .listing-detail-data .email-conatiner {
        display: flex !important;
        justify-content: center !important;
    }

    .responsive-find-list {
        display: none !important;
    }

    .responsive-find-main {
        display: inline !important;
    }
}

@media (min-width:1200px) and (max-width:1430px) {
    .tenent-manager .manager-list {
        margin-top: 0px !important;
    }

    .tenent-manager .manager-list-card .manager-card .users-heading .user-main-heading {
        font-size: 14px !important;
    }

    .tenent-manager .manager-list-card .manager-card .listing-count {
        font-size: 14px !important;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .tenent-manager .manager-list-card .manager-card .listing-count {
        // display: none !important;
        padding-left: 83px !important;
    }

    .tenent-manager .manager-list-card .manager-card .Invite-sent {
        padding-left: 90px !important;
        margin-top: -45px !important;
    }

}

@media (max-width:1311px) {
    .tenent-manager .manager-list {
        margin-top: 0px !important;
    }
}

@media (max-width:992px) {
    .tenent-manager .listing-manager-detail {
        margin-top: 20px !important;
    }
}

@media (max-width:760px) {
    .tenent-manager .listing-detail-data .phone-container {
        display: none !important;
    }

    .tenent-manager .listing-detail-data .email-conatiner {
        display: flex !important;
        justify-content: center !important;
    }
}

// @media (max-width:440px) {
//     .tenent-manager .listing-detail-data .email-conatiner .phone-number {
//         font-size: 12px !important;
//     }

// }

@media (max-width:767px) {
    .tenent-manager {
        margin: 10px 0 0 0 !important;

        .manager-list-card {
            .manager-card {
                .listing-count {
                    padding-left: 86px !important;
                }

                .Invite-sent {
                    padding-left: 20px !important;
                    padding-top: 5px !important;
                }
            }
        }

        .phone-image {
            width: 15px !important;
            height: 15px !important;
        }

    }
}