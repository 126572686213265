.review-container {
    // width: 880px !important;
    margin-top: 50px;

    .review-heading {
        color: #2F86D1;
        font-size: 35px;
        font-weight: 600;
        font-family: "Proxima Nova";
        display: flex;
        justify-content: center;
    }

    .review-des {
        // width: 650px !important;
        text-align: center;
        padding-top: 10px;

        .review-des-value {
            font-size: 20px;
            font-family: "Proxima Nova";
        }
    }

}

.detail-seaction {
    .userImage {
        width: 60px;
        height: 60px;
    }
}

.review-value-box {
    // width: 950px !important;
    // height: 400px;
    background-color: #F2F2F7;
    border-radius: 5px;
    margin-top: 12px;
    padding: 25px;

    .revire-value-item {
        background-color: #ffff;
        // width: 900px;
        // height: 300px;
        padding: 15px;

        .MuiMobileStepper-root {
            display: flex;
            align-items: center;
            background-color: transparent;
            color: #ffff !important;
            margin-top: -130px !important;
            margin-left: -20px !important;
            margin-right: -23px !important;

            @media (min-width:431px) and (max-width:575px) {
                margin-top: -190px !important;
            }

            @media (min-width:575px) and (max-width:915px) {
                margin-top: -100px !important;
            }
        }

        .MuiPaper-root {
            background-color: transparent !important;
            color: #ffff !important;
        }

        .MuiButtonBase-root {
            display: inline-block;
            justify-content: inherit !important;
        }

        .rent-box {
            padding-top: 32% !important;

            .rental-heading {
                padding-top: 70px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 20px;
                font-weight: 600;
                padding: 10px 6px 5px 10px;
                font-family: "Proxima Nova";
            }

            .rent-heading {
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 20px;
                font-weight: 600;
                padding: 8px 0 5px 10px;
                font-family: "Proxima Nova";
            }
        }

        .deposit-type {
            display: none !important;
        }


        .mobile-type {
            display: none !important;

            .rental-heading {
                padding-top: 70px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 16px;
                font-weight: 600;
                // padding: 10px 6px 5px 10px;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }

            .rent-heading {
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 16px;
                font-weight: 600;
                // padding: 8px 0 5px 10px;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }

        }


        .detail-seaction {
            // margin-left: -17px;
            // margin-top: 5px;
            // padding: 10px 10px 10px 0;

            @media (max-width:575px) {
                padding: 0 10px 10px 10px !important;
            }

            .user-heading {
                font-size: 18px !important;
                font-weight: 600 !important;
                font-family: "Proxima Nova";
            }

            .user-dot {
                font-size: 20px;
                font-weight: 800;
                margin: -6px 10px 0 10px;
            }

            .property-owner {
                color: #707070;
                font-size: 18px;
                font-weight: 500;
            }

            .categroy-heading {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
            }

            .categroy-heading-type {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
                font-family: "Proxima_nova_medium";
                display: flex;
                align-items: center;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }

            .category-value {
                font-size: 18px;
                font-weight: 600;
                margin-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .select-value-heading {
                font-size: 20px;
                font-weight: 600;
                margin-top: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .view-heading {
                font-size: 20px;
                font-weight: 600;
                color: #2F86D1;
                text-decoration: underline;
            }
        }

    }

    .edit-property {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        text-decoration: underline;
        font-size: 20px;
        font-weight: 600;
        color: #2F86D1;
        font-family: "Proxima Nova";
        cursor: pointer;
    }
}

.update-container {
    // width: 920px !important;
    margin-top: 20px;

    .update-heading-review {
        font-size: 24px;
        font-weight: 600;
        font-family: "Proxima Nova";
        text-align: center;
    }

    .update-des {
        text-align: center;
        padding-top: 10px;

        .update-des-value {
            font-size: 20px;
            font-family: "Proxima Nova";
            color: #707070;
        }
    }

    .update-check {
        text-align: center;
        margin-top: 10px;

        .check-list {
            font-size: 20px;
            font-weight: 600;
            font-family: "Proxima Nova";
            color: #2F86D1;
        }

    }

}

.select-choose {
    text-align: center;
    padding-top: 10px;
}

.changes-des {
    width: 920px !important;
    margin-top: 10px;
    padding-left: 160px;

    .changes-des-value {
        font-size: 20px;
        font-weight: 500;
        color: #707070;
    }

}

@media (min-width:1523px) and (max-width:1700px) {
    .review-value-box .revire-value-item {
        .rent-box {
            padding-top: 45% !important;
        }
    }
}

@media (min-width:1200px) and (max-width:1523px) {
    .review-value-box .revire-value-item {
        .rent-box {
            padding-top: 45% !important;
        }
    }
}

@media (min-width:1023px) and (max-width:1199px) {
    .review-container {
        margin: 10px !important;
    }
}

@media (min-width:808px) and (max-width:913px) {
    .review-value-box .revire-value-item.rent-box {
        display: none !important;
    }

    .review-value-box .revire-value-item .deposit-type {
        padding-top: 45% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }
}

@media (min-width:768px) and (max-width:808px) {
    .review-value-box .revire-value-item.rent-box {
        display: none !important;
    }

    .review-value-box .revire-value-item .deposit-type {
        padding-top: 43% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }

}

@media (min-width:768px) and (max-width:991px) {
    .review-value-box .revire-value-item {
        .rent-box {
            display: none !important;
        }
    }

    .review-value-box .revire-value-item .deposit-type {
        display: inline-block !important;
        padding-top: 24% !important;
        padding-left: 10px !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }

    .review-container {
        margin: 10px !important;
    }
}

@media (max-width:768px) {
    .review-value-box .revire-value-item {
        box-shadow: 0px 4px 4px 0px #0000001F;

        .rent-box {
            padding-top: 33% !important;
        }
    }

    .review-container {
        margin: 10px !important;
    }
}

@media (max-width:767px) {
    .mobile-box {
        padding: 0px !important;

    }

    .border-mobile {
        border-top: 1px solid rgb(183, 183, 183);
        display: flex !important;
    }

    .review-container {
        .review-heading {
            font-size: 16px !important;
            justify-content: start !important;
            padding-left: 10px !important;
        }

        .review-des {
            padding-left: 10px !important;

            .review-des-value {
                font-size: 12px !important;
                text-align: start !important;
            }
        }
    }

    .review-value-box {
        width: 100%;
        border-radius: 0px !important;
        overflow-x: hidden !important;
        padding: 10px !important;

        .revire-value-item {
            max-width: 100% !important;

            .deposit-type {
                display: inline-block !important;

                padding-left: 10px !important;
            }

            // .detail-seaction {

            //     // padding-top: 12% !important;
            //     .profile {
            //         padding-left: 10px !important;
            //         overflow: hidden;
            //         text-overflow: ellipsis;
            //         white-space: nowrap;
            //         display: flex !important;

            //         // justify-content: space-between !important;
            //         .rental-price {
            //             font-weight: 600;
            //         }

            //         .deposit-price {
            //             font-weight: 600;
            //         }
            //     }

            //     .userImage {
            //         width: 40px;
            //         height: 40px;
            //     }

            //     .user-heading {
            //         font-size: 14px !important;
            //     }

            //     .property-owner {
            //         font-size: 12px !important;
            //         margin-bottom: 0px !important;
            //     }

            //     .select-value-heading {
            //         font-size: 16px !important;
            //     }
            // }

            .rent-box {
                padding-top: 10% !important;
            }
        }

        .edit-property {
            font-size: 16px;
        }
    }

    .mobile-line {
        display: none !important;
    }

    .update-container {

        .update-heading-review {
            font-size: 20px !important;
            padding: 0px 15px 0px 15px !important;
        }

        .update-des {
            .update-des-value {
                font-size: 14px !important;
                padding: 10px 2px 10px 2px !important;
            }
        }

        .update-check {
            .update-checkbox {
                width: 18px;
                height: 18px;
            }

            .check-list {
                font-size: 16px !important;
            }
        }
    }

    .review-value-box .revire-value-item {
        padding: 0px !important;
    }

    .makeStyles-img-3 {
        width: 100% !important;
    }

}

@media (max-width:576px) {
    .review-value-box {
        width: 100%;


        .revire-value-item {

            .rent-box {
                // padding-top: 22% !important;
                display: none !important;

                .rental-price {
                    font-size: 16px !important;
                }

                .deposit-price {
                    font-size: 16px !important;
                }
            }
        }
    }

    .review-value-box .revire-value-item .mobile-type {
        display: flex !important;
        margin-top: 10px !important;
    }
}



@media (min-width:430px) and (max-width:575px) {
    .review-value-box .revire-value-item .detail-seaction .section-profile {
        margin-top: 90px !important;
    }
}

@media (max-width:430px) {
    .review-value-box .revire-value-item .detail-seaction .section-profile {
        // margin-top: 155px !important;
        margin-top: 30px !important;
    }
}

.update-check {
    .update-checkbox {
        width: 25px;
        height: 25px;
    }
}

@media (min-width:760px) and (max-width:770px) {
    .saveButton-review {
        margin-right: 6px !important;
    }

    .backButton-review {
        margin-left: -24px !important;
    }

}

@media (min-width:770px) and (max-width:1030px) {
    .saveButton-review {
        margin-right: 27px !important;
    }

    .backButton-review {
        margin-left: 0px !important;
    }
}

@media (min-width:1100px) {
    .saveButton-review {
        margin-right: 50px !important;
    }

    .backButton-review {
        margin-left: 50px !important;
    }
}

@media (max-width:575px) {
    .saveExit-review {
        margin-left: 7px !important;
    }

    .saveButton-mobile-review {
        margin-right: 7px !important;
    }
}