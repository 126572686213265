.map-medium-section {

    .map-container-meduim {

        .map-container {
            width: 100%;
            height: 75vh;
        }

        .location-tooltip {
            position: absolute;
            bottom: 10px;
            // left: 10px;
            background-color: white;
            // padding: 5px;
            border: 1px solid black;
            border-radius: 3px;
            z-index: 100;
        }
    }

}

@media (max-width: 768px) {
    .map-medium-section .map-container-meduim .map-container {
        height: 40vh;
        /* Smaller height for mobile */
    }
}

@media (min-width:391px) and (max-width: 575px) {
    .map-medium-section {
        // margin-top: -390px !important;
        padding: 0 !important;

    }
}

@media (max-width:575px) {
    .map-medium-section {
        padding: 0 !important;
        margin-top: 65px !important;

    }
}

@media (min-width:376px) and (max-width:390px) {
    .map-medium-section {
        // margin-top: -260px !important;
        padding: 0 !important;
    }
}

@media (min-width:360px) and (max-width:374px) {
    .map-medium-section {
        // margin-top: -160px !important;
        padding: 0 !important;
    }
}

@media (min-width:361px) and (max-width:375px) {
    .map-medium-section {
        // margin-top: -115px !important;
        padding: 0 !important;
    }
}

// .search-map-list {
.show-list {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    color: #ffffff;
    background-color: #0075CA;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer;
    position: absolute;
    bottom: 200px;
    font-size: 18px;
    font-weight: 500;

    @media (min-width:820px) and (max-width:912px) {
        bottom: 210px !important;
    }

    @media (min-width:770px) and (max-width:820px) {
        bottom: 150px !important;
    }

    @media (max-width:575px) {
        position: fixed !important;
        bottom: 160px !important;
    }
}

.search-list {
    width: 87% !important;
    position: absolute;
    bottom: -95px;
    left: 50px;

    @media (min-width:915px) and (max-width:1025px) {
        width: 95% !important;
        left: 15px;
        bottom: 70px !important;
    }

    @media (min-width:770px) and (max-width:912px) {
        width: 100% !important;
        left: 0px;
    }

    @media (min-width:820px) and (max-width:912px) {
        bottom: 70px !important;
    }

    @media (min-width:770px) and (max-width:820px) {
        bottom: 15px !important;
    }

    @media (max-width:575px) {
        bottom: 15px !important;
        position: fixed !important;
        left: -6px !important;
        width: 100% !important;
    }

    .feature-card {
        display: flex !important;
        width: 97% !important;
        background-color: #ffffff;
        height: auto;
        cursor: pointer;

    }

    .feature-card-detail {
        margin-left: 20px !important;

        @media (max-width:575px) {
            margin-left: 10px !important;
        }
    }

    .slick-prev {
        display: none !important;
    }

    .slick-next {
        display: none !important;
    }

    .rent-price {
        font-size: 18px;
        font-weight: 600;
        font-family: Proxima_nova_reg;
        padding-top: 15px;
    }

    .category-heading {
        font-size: 12px;
        font-weight: 400;
        font-family: Proxima_nova_reg;
        padding-left: 5px;
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }

    .location-name {
        font-size: 14px;
        font-weight: 400;
        font-family: Proxima_nova_reg;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;

        @media (max-width:575px) {
            padding-bottom: 10px !important;
            width: 70% !important;
        }
    }

}

@media (min-width:915px) and (max-width:1025px) {
    .map-medium-section {
        padding: 0 !important;
    }
}

@media (min-width:770px) and (max-width:915px) {
    .map-medium-section {
        padding: 0 !important;
    }
}

@media (min-width:576px) and (max-width:768px) {
    .map-medium-section {
        padding: 0 !important;
    }

    .search-list {
        width: 100% !important;
        bottom: 70px !important;
        left: 0px !important;
    }
}