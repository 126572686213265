.current-subscription-container {
    // width: 1000px;
    height: auto;
    background-color: #FFFFFF;
    padding: 20px 30px;
    box-shadow: 0px 2px 8px 0px #0000001F;

    @media (max-width:575px) {
        margin: 0px 10px 10px 10px !important;
    }

    .current-heading {
        font-size: 18px;
        font-weight: 400;
    }

    .active-heading {
        font-size: 24px;
        font-weight: 600;
        color: #919CA5;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .current-plan-heading {
        font-size: 16px;
        font-weight: 500;
    }

    .term-heading {
        font-size: 26px;
        font-weight: 600;
        color: #0075CA;
    }

    .price-manager-heading {
        font-size: 40px;
        font-weight: 700;

        @media (max-width:575px) {
            font-size: 32px !important;
        }
    }

    .month-manager-heading {
        display: flex;
        align-self: center;
        color: #919CA5;
        margin-left: 5px;
        margin-top: -2px;
        font-size: 20px;
        font-weight: 400;

        @media (max-width:575px) {
            margin-top: 5px !important;
        }
    }

    .payment-description {
        font-size: 16px;
        font-weight: 400;
        color: #919CA5;
        margin-top: 15px;

        @media (max-width:575px) {
            font-size: 14px !important;
        }
    }

    .date-description {
        font-size: 20px;
        font-weight: 600;
        color: #505050;

        @media (max-width:575px) {
            font-size: 16px !important;
        }
    }

    .plan-description {
        font-size: 16px;
        font-weight: 600;
        margin-top: 20px !important;
    }

    .percent-box {
        width: 390px;

        .percent-description {
            font-size: 20px;
            font-weight: 700;
            color: #505050;
        }

        @media (min-width:440px) and (max-width: 600px) {
            width: 300px !important;
        }

        @media (min-width:400px) and (max-width: 440px) {
            width: 310px !important;
        }

        @media (max-width:400px) {
            width: 300px !important;
        }
    }

    .progressBar {
        width: 390px !important;

        @media (min-width:440px) and (max-width: 600px) {
            width: 300px !important;
        }

        @media (min-width:400px) and (max-width: 440px) {
            width: 310px !important;
        }

        @media (max-width:400px) {
            width: 300px !important;
        }
    }

    .listing-property {
        font-size: 16px;
        font-weight: 500;

        @media (max-width:600px) {
            font-size: 14px !important;
        }
    }

    .select-package-heading {
        font-size: 24px;
        font-weight: 700;
        padding-top: 20px;

        @media (max-width:600px) {
            font-size: 16px !important;
        }
    }

    .select-package-description {
        font-size: 16px;
        font-weight: 600;
        margin-top: 5px;
        color: #919CA5;

        @media (max-width:600px) {
            font-size: 14px !important;
            text-align: center !important;
        }
    }

    .subscription-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        // width: 280px;
        // margin-right: 15px;

        .item-heading {
            display: flex;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            margin-top: 15px;
            margin-bottom: 10px;
            color: #505050;
        }

        .item-below-description {
            margin-top: 5px;
            margin-left: 2px;
            font-size: 16px;
            font-weight: 500;
            color: #919CA5;
        }

        .subscribe-button {
            cursor: pointer;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:575px) {
                height: 60px !important;
            }

            .amount-term {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0075CA;
                font-size: 24px;
                font-weight: 700;
            }

            .listing-amount {
                color: #505050;
                font-weight: 400;
                font-size: 20px;
                padding-left: 5px;
            }
        }
    }

    .checkout-button {
        display: flex;
        justify-content: end;
        margin-top: 40px;
        // margin-right: 20px;

        @media (max-width:575px) {
            margin-right: 0px !important;
            // justify-content: center !important;
        }
    }

}


@media (min-width:1467px) and (max-width:1679px) {
    .current-subscription-container .subscription-box .subscribe-button .listing-amount {
        font-size: 16px !important;
    }

    .current-subscription-container .subscription-box .subscribe-button .amount-term {
        font-size: 22px !important;
    }
}