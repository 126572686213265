.favourite-listing {
    margin-top: 80px;
    overflow-x: hidden !important;

    @media (max-width:575px) {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .listing-manager-fav {
        // margin-left: -10px;
        margin-bottom: 10px;
        font-family: "Proxima_nova_reg";
        height: auto;
        background: white;
        padding: 10px;
        z-index: 1;

        @media (max-width:575px) {
            padding: 0px !important;
        }

        .mobile-fav {
            position: relative;

            .mobile-fav-icon {
                position: absolute !important;
                top: 5%;
                right: 20px;
                // z-index: 1;

                .mobile-fav {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }
            }
        }


        .MuiMobileStepper-root {
            display: flex;
            align-items: center;
            background-color: transparent;
            color: #ffff !important;
            margin-top: -130px !important;
            margin-left: -20px !important;
            margin-right: -23px !important;

            @media (min-width:915px) and (max-width:1024px) {
                margin-top: -100px !important;
            }

            @media (min-width:431px) and (max-width:575px) {
                margin-top: -190px !important;
            }
        }

        .MuiPaper-root {
            background-color: transparent !important;
            color: #ffff !important;
        }

        .MuiButtonBase-root {
            display: inline-block;
            justify-content: inherit !important;
            border: none !important;
        }

        .listing-heading {
            padding-top: 90px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rent-box {
            padding-top: 32% !important;

            @media (min-width:991px) and (max-width:1024px) {
                padding-top: 25% !important;
            }

            .rental-heading {
                padding-top: 70px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 20px;
                font-weight: 600;
                padding: 10px 6px 5px 10px;
                font-family: Proxima_nova_reg;
            }

            .rent-heading {
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 20px;
                font-weight: 600;
                padding: 8px 0 5px 10px;
                font-family: "Proxima Nova";
            }
        }

        .deposit-type {
            display: none !important;

            .rental-heading {
                padding-top: 70px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 16px;
                font-weight: 600;
                // padding: 10px 6px 5px 10px;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }

            .rent-heading {
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 16px;
                font-weight: 600;
                // padding: 8px 0 5px 10px;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }

        }

        .mobile-type {
            display: none !important;

            .rental-heading {
                padding-top: 70px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 16px;
                font-weight: 600;
                // padding: 10px 6px 5px 10px;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }

            .rent-heading {
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 16px;
                font-weight: 600;
                // padding: 8px 0 5px 10px;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }

        }


        .listing-card-detail {

            @media (max-width:575px) {
                padding: 8px 10px 10px 10px;
            }

            .profile-value {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }

            .listing-user-heading {
                font-size: 23px;
                font-weight: 600;
                color: #2F86D1;
            }

            .listing-user-month {
                display: flex;
                align-self: center;
                color: #2F86D1;
                margin-top: 5px;
                font-size: 16px;
                font-weight: 600;
            }

            .categroy-heading {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
                margin-top: 3px;
            }

            .categroy-heading-type {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
                font-family: "Proxima_nova_medium";
                display: flex;
                align-items: center;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }

            .category-value {
                font-size: 16px;
                font-weight: 600;
                margin-left: 5px;
                margin-top: 2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .select-value-heading {
                font-size: 16px;
                font-weight: 600;
                margin-top: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

            }

            .request-genarte {
                font-size: 18px;
                font-weight: 600;
                margin-right: 20px;
                color: #2F86D1;
                margin-top: 10px;
                text-decoration: underline;
                cursor: pointer;
            }

            .user-heading {
                font-size: 18px !important;
                font-weight: 600 !important;
                font-family: "Proxima Nova";
            }

            .user-dot {
                font-size: 20px;
                font-weight: 800;
                margin: -6px 6px 0 6px;
            }

            .property-owner {
                color: #707070;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
            }

            .orgnization-name {
                color: #707070;
                font-size: 14px;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 5px;
                max-width: 280px;

            }

            .custom-margin {
                margin-top: 17px !important;
            }

            .ipaid-detail {
                display: none !important;

                @media (min-width:766px) and (max-width:915px) {
                    display: flex !important;
                    justify-content: end !important;
                }
            }

            .full-screen-detail {
                display: flex !important;
                align-items: center !important;

                @media (min-width:766px) and (max-width:915px) {
                    display: none !important;
                }
            }

            .view-heading {
                font-size: 18px;
                font-weight: 600;
                color: #2F86D1;
                text-decoration: underline;

                @media (min-width:766px) and (max-width:915px) {
                    font-size: 16px !important;
                }
            }

        }

    }

}

.empty-fav-list {
    width: 100%;
    background: white;
    height: 230px;
    // margin-left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border: 1px dashed black;

    @media (min-width:420px) and (max-width:431px) {
        margin-bottom: 470px !important;
    }

    @media (min-width:400px) and (max-width:413px) {
        margin-bottom: 450px !important;
    }

    @media (min-height:860px) and (max-height:897px) {
        margin-bottom: 440px !important;
    }

    @media (min-height:700px) and (max-height:740px) {
        margin-bottom: 285px !important;
    }

    @media (min-width:376px) and (max-width:391px) {
        margin-bottom: 385px !important;
    }

    @media (min-width:361px) and (max-width:375px) {
        margin-bottom: 217px !important;
    }

    @media (max-width:360px) {
        margin-bottom: 285px !important;
    }

    .empty-heading {
        font-size: 20px;
        font-weight: 400;
        font-family: 'Proxima_nova_medium' !important;
    }

    .empty-description {
        font-size: 16px;
        font-weight: 400;
        display: flex;
        justify-content: center;
    }
}


@media (min-width:1200px) and (max-width:1523px) {
    .favourite-listing .listing-manager-fav .rent-box {
        padding-top: 33% !important;
    }

}

// @media (min-width:1023px) and (max-width:1199px) {
//     .empty-fav-list {
//         width: 650px !important;
//     }
// }
@media (min-width:1024px) and (max-width:1199px) {
    .empty-fav-list {
        width: 650px !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .empty-fav-list {
        width: 595px !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .favourite-listing .listing-manager-fav .rent-box {
        // padding-top: 42% !important;
        display: none !important;
    }

    .favourite-listing.listing-manager-fav .deposit-type {
        // padding-top: 15px !important;
        padding-top: 42% !important;
        display: inline-block !important;
    }

    .favourite-listing .listing-manager-fav .mobile-type {
        display: none !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .profile-value {
        width: 70px !important;
        height: 70px !important;
    }

    .favourite-listing.listing-manager-fav .listing-card-detail .user-heading {
        font-size: 16px !important;
    }

    .favourite-listing.listing-manager-fav .listing-card-detail .category-value {
        font-size: 14px !important;
    }

}

@media (min-width:808px) and (max-width:913px) {
    .favourite-listing.listing-manager-fav .rent-box {
        display: none !important;
    }

    // .favourite-listing .listing-manager-fav .listing-card-detail .view-heading {
    //     display: none !important;
    // }

    .favourite-listing .listing-manager-fav .mobile-type {
        display: none !important;
    }

    .favourite-listing .listing-manager-fav .deposit-type {
        // padding-top: 15px !important;
        padding-top: 26% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }
}

@media (min-width:770px) and (max-width:808px) {
    .favourite-listing .listing-manager-fav .rent-box {
        display: none !important;
    }

    // .favourite-listing .listing-manager-fav .listing-card-detail .view-heading {
    //     display: none !important;
    // }

    .favourite-listing .listing-manager-fav .mobile-type {
        display: none !important;
    }

    .favourite-listing .listing-manager-fav .deposit-type {
        // padding-top: 15px !important;
        padding-top: 43% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .categroy-heading {
        font-size: 13px !important;
    }
}

// @media (min-width:575px) and (max-width:991px) {
//     .favourite-listing .listing-manager-fav .deposit-type {
//         display: inline-block !important;
//         padding-top: 30% !important;
//     }
// }

@media (min-width:575px) and (max-width:768px) {
    .favourite-listing .listing-manager-fav .rent-box {
        display: none !important;
    }

    // .favourite-listing .listing-manager-fav .listing-card-detail .view-heading {
    //     display: none !important;
    // }

    .favourite-listing .listing-manager-fav .mobile-type {
        display: none !important;
    }

    .favourite-listing .listing-manager-fav .deposit-type {
        // padding-top: 15px !important;
        padding-top: 30% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }


    .favourite-listing.listing-manager-fav .deposit-type {
        padding-top: 50% !important;
        display: inline-block !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .profile-value {
        width: 70px !important;
        height: 70px !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .user-heading {
        font-size: 16px !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .category-value {
        font-size: 14px !important;
    }

    .favourite-listing .listing-manager-fav .rent-box .rental-price {
        font-size: 16px !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .categroy-heading {
        font-size: 13px !important;
    }

    .favourite-listing.listing-manager-fav .listing-card-detail .view-heading {
        display: none !important;
    }

    .favourite-listing .listing-manager-fav .MuiMobileStepper-root {
        margin-top: -100px !important;
    }
}

@media (min-width:770px) and (max-width:915px) {
    .favourite-listing .listing-manager-fav .MuiMobileStepper-root {
        margin-top: -100px !important;
    }
}

@media (min-width:430px) and (max-width:575px) {
    // .favourite-listing .listing-manager-fav .MuiMobileStepper-root {
    //     margin-top: -145px !important;
    // }

    .favourite-listing .listing-manager-fav .rent-box {
        display: none !important;
    }

    .favourite-listing .listing-manager-fav .section-profile {
        margin-top: 155px !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .property-owner {
        display: none !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .custom-margin {
        margin-top: 0px !important;
    }

    .favourite-listing .listing-manager-fav .mobile-type {
        margin-top: 10px !important;
        display: flex !important;
    }
}

@media (max-width:430px) {
    // .favourite-listing .listing-manager-fav .MuiMobileStepper-root {
    //     margin-top: -100px !important;
    // }

    .favourite-listing .listing-manager-fav .rent-box {
        display: none !important;
    }

    .favourite-listing .listing-manager-fav .section-profile {
        margin-top: 85px !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .property-owner {
        display: none !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .custom-margin {
        margin-top: 0px !important;
    }

    .favourite-listing .listing-manager-fav .mobile-type {
        margin-top: 10px !important;
        display: flex !important;
    }

}