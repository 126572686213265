.upload-container {
    // width: 650px !important;
    margin-top: 120px;



    .upload-heading {
        color: #2F86D1;
        font-size: 35px;
        font-weight: 550;
        font-family: "Proxima Nova";
        display: flex;
        justify-content: center;
    }

    .upload-description {
        // width: 600px;
        text-align: center;
        // padding-left: 60px;
        padding-top: 10px;

        .upload-description-value {
            font-size: 20px;
            font-family: "Proxima Nova";
        }
    }

    .uploadPhoto {
        // width: 40vw;
        height: 40%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 30px;


        .uploadInfo {
            text-align: center;
            margin-top: 24px;
            margin-bottom: 24px;

            .uploadImage {
                // height: 30vh;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 110px;
            }

            .uploadText {
                margin-top: 10px;
                font-family: "Proxima Nova";
                font-style: normal;
                font-weight: 600;
                font-size: 20px !important;
                line-height: 16px;
            }

            .uploadTextDes {
                margin-top: 20px;
                font-family: "Proxima Nova";
                font-style: normal;
                font-size: 18px !important;
                line-height: 16px;
            }

            .uploadTextDesLast {
                margin-top: 50px;
                font-family: "Proxima Nova";
                font-style: normal;
                font-weight: 600;
                font-size: 20px !important;
                line-height: 16px;
                color: #2F86D1;
            }
        }

        .update-pick {
            width: 280px;
            height: 148px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width:760px) and (max-width:800px) {
                width: 240px;
            }

            @media (min-width:800px) and (max-width:900px) {
                width: 258px;
            }

            @media (min-width:530px) and (max-width:575px) {
                width: 252px !important;
                height: 130px !important;
            }

            @media (min-width:420px) and (max-width: 440px) {
                width: 197px !important;
                height: 133px !important;
            }

            @media (min-width:400px) and (max-width: 420px) {
                width: 188px !important;
                height: 120px !important;
            }

            @media (min-width:390px) and (max-width: 400px) {
                width: 177px !important;
                height: 88px !important;
                font-size: 12px !important;
            }

            @media (min-width:376px) and (max-width: 390px) {
                width: 176px !important;
                height: 88px !important;
                font-size: 12px !important;
            }

            @media (min-width:370px) and (max-width: 376px) {
                width: 168px !important;
                height: 88px !important;
                font-size: 12px !important;
            }

            @media (max-width:360px) {
                width: 160px !important;
                height: 80px !important;
                font-size: 12px !important;
            }

            .loader {
                border: 16px solid #f3f3f3;
                border-radius: 50%;
                border-top: 16px solid #3498db;
                width: 120px;
                height: 120px;
                -webkit-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite;
            }

            /* Safari */
            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }

                100% {
                    -webkit-transform: rotate(360deg);
                }
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }

            .loader-add {
                border: 16px solid #f3f3f3;
                border-radius: 50%;
                border-top: 16px solid #3498db;
                width: 120px;
                height: 120px;
                -webkit-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite;

                @media (min-width:370px) and (max-width:530px) {
                    width: 80px;
                    height: 80px;
                }

                @media (max-width:360px) {
                    width: 70px;
                    height: 70px;
                }
            }

            /* Safari */
            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }

                100% {
                    -webkit-transform: rotate(360deg);
                }
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

        .update-pick-loader {
            width: 280px;
            height: 148px;
            border-radius: 4px;
            border: 2px dotted black;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width:760px) and (max-width:800px) {
                width: 240px;
            }

            @media (min-width:800px) and (max-width:900px) {
                width: 258px;
            }

            @media (min-width:530px) and (max-width:575px) {
                width: 252px !important;
                height: 130px !important;
            }

            @media (min-width:420px) and (max-width: 440px) {
                width: 197px !important;
                height: 133px !important;
            }

            @media (min-width:400px) and (max-width: 420px) {
                width: 188px !important;
                height: 120px !important;
            }

            @media (min-width:390px) and (max-width: 400px) {
                width: 177px !important;
                height: 88px !important;
                font-size: 12px !important;
            }

            @media (min-width:376px) and (max-width: 390px) {
                width: 176px !important;
                height: 88px !important;
                font-size: 12px !important;
            }

            @media (min-width:370px) and (max-width: 376px) {
                width: 168px !important;
                height: 88px !important;
                font-size: 12px !important;
            }

            @media (max-width:360px) {
                width: 160px !important;
                height: 80px !important;
                font-size: 12px !important;
            }

            .loader {
                border: 16px solid #f3f3f3;
                border-radius: 50%;
                border-top: 16px solid #3498db;
                width: 120px;
                height: 120px;
                -webkit-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite;
            }

            /* Safari */
            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }

                100% {
                    -webkit-transform: rotate(360deg);
                }
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

        .uploadInfotwo {
            width: 280px;
            height: 148px;
            border-radius: 4px;
            // margin: 0px 10px 10px 0px;
            cursor: pointer;
            border: 2px dotted black;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 20px;

            @media (min-width:760px) and (max-width:800px) {
                width: 240px;
            }

            @media (min-width:800px) and (max-width:900px) {
                width: 258px;
            }

            @media (min-width:530px) and (max-width:575px) {
                width: 252px !important;
                height: 130px !important;
            }

            @media (min-width:420px) and (max-width: 440px) {
                width: 197px !important;
                height: 133px !important;
            }

            @media (min-width:400px) and (max-width: 420px) {
                width: 188px !important;
                height: 120px !important;
            }

            @media (min-width:390px) and (max-width: 400px) {
                width: 177px !important;
                height: 88px !important;
                font-size: 12px !important;
            }

            @media (min-width:376px) and (max-width: 390px) {
                width: 176px !important;
                height: 88px !important;
                font-size: 12px !important;
            }

            @media (min-width:370px) and (max-width: 376px) {
                width: 168px !important;
                height: 88px !important;
                font-size: 12px !important;
            }

            @media (max-width:360px) {
                width: 160px !important;
                height: 80px !important;
                font-size: 12px !important;
            }
        }

        .crossimage {
            position: absolute;
            top: 33%;
            right: 46%;
        }
    }

    .cropImageText {
        font-size: 25px !important;
    }

}

@media (min-width: 1020px) and (max-width:1199px) {
    .upload-container {
        margin: 0 60px 30px 60px !important;
    }
}

@media (min-width: 900px) and (max-width:1000px) {
    .upload-container {
        margin: 0 13px 30px 13px !important;
    }
}

@media (max-width:900px) {
    .upload-container {
        margin: 0 0px 30px 0px !important;
    }
}

@media (max-width:767px) {
    .upload-container {
        // margin: 0 60px 30px 70px !important;
        margin-top: 10px !important;

        .upload-heading {
            font-size: 16px !important;
            justify-content: start !important;
            padding-left: 10px !important;
        }

        .upload-description {
            text-align: left !important;
            padding-left: 10px !important;
        }

        .upload-description-value {
            font-size: 12px !important;
        }

        .uploadPhoto {
            // justify-content: start !important;


            .uploadInfo {
                .uploadTextDes {
                    margin-top: 10px !important;
                    font-size: 12px !important;
                }

                .uploadText {
                    font-size: 16px !important;
                }

                .uploadTextDesLast {
                    margin-top: 10px !important;
                    font-size: 16px !important;

                }



            }
        }
    }


    .cropImageText {
        font-size: 12px !important;
    }

    .canelButton {
        font-size: 12px !important;
        width: 50px !important;
        height: 30px !important;
        background: #E73D3B;
        color: #ffffff;
        font-weight: bold;
        cursor: pointer;
        padding: 4px;
        text-align: center;

    }

    .saveButtonModal {
        width: 50px !important;
        height: 30px !important;
        background: #a3a3a3;
        color: #ffffff;
        font-weight: bold;
        cursor: pointer;
        padding: 4px;
        text-align: center;
        font-size: 12px;
    }
}

.canelButton {
    width: 173px;
    height: 50px;
    background: rgb(231, 61, 59);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    cursor: pointer;
}

.saveButtonModal {
    width: 173px;
    height: 50px;
    background: #a3a3a3;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
}

@media (min-width:760px) and (max-width:770px) {
    .saveButton-upload {
        margin-right: 6px !important;
    }

    .backButton-upload {
        margin-left: -24px !important;
    }

}

@media (min-width:770px) and (max-width:1030px) {
    .saveButton-upload {
        margin-right: 27px !important;
    }

    .backButton-upload {
        margin-left: 0px !important;
    }
}

@media (min-width:1100px) {
    .saveButton-upload {
        margin-right: 50px !important;
    }

    .backButton-upload {
        margin-left: 50px !important;
    }
}

@media (max-width:575px) {
    .saveExit-upload {
        margin-left: 7px !important;
    }

    .saveButton-mobile-upload {
        margin-right: 7px !important;
    }
}

.modal-upload-image-section {
    .slick-slider {
        padding-top: 0px !important;
    }

    .custom-image-upload {
        position: absolute !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .prev-arrows-image {
        right: calc(100% - 130px);
        z-index: 1;

        @media (max-width:1030px) {
            display: none !important;
            right: calc(100% - 60px);
        }
    }

    .next-arrows-image {
        left: calc(100% - 130px);

        @media (max-width: 1030px) {
            display: none !important;
            left: calc(100% - 60px);
        }
    }

    .slick-dots {
        bottom: 0px !important;

        @media (max-width:375px) {
            bottom: 10px !important;
        }
    }

    .slick-dots li button:before {
        color: white !important;
        font-size: 20px !important;
    }

}