.successScreen{
    .GovHome{
        max-width: 100px;
         margin: 40px 0 0 70px;
        position:absolute ;
    }
    @media screen and (max-width:576px) {
        .GovHome{
             margin: 20px 0 0 20px;
        }
    }
}