.basic-select {
    // width: 660px !important;
    margin-top: 50px;
}

.basic-heading {
    color: #2F86D1;
    font-size: 30px;
    font-weight: 600;
    font-family: "Proxima Nova";
    display: flex;
    justify-content: center;
}

.select-count {
    width: 290px;
    margin-top: 15px;
    margin-left: 8px;
    color: #505050;
    display: flex;
    justify-content: space-between;
}

.select-count-drop {
    width: 315px;
    margin-top: 15px;
    margin-left: 15px;
    color: #505050;
    display: flex;
    justify-content: space-between;
}

.count-heading {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Proxima_nova_reg'

}

.pets {
    font-size: 20px;
    color: #505050;
    font-family: "Proxima Nova";

    margin: 15px 0px 10px 7px
}

.pets_button {
    padding: 6px 13px 6px 13px;
    margin-right: 10px;
    border-radius: 50px;
    background: white;
    width: 130px;
    font-size: 18px;
    margin-bottom: 15px;
}

.pets_button::first-letter {
    text-transform: capitalize;
}

.count-show {

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

}

.otherpet {
    .MuiFormLabel-root {
        font-size: 20px;
        color: #505050;
        font-family: "Proxima Nova";
    }
}

.warning {
    .MuiFormLabel-root {
        color: red;
    }
}

.basic-select {
    .basic-Name {
        .MuiInputBase-input {
            padding-bottom: 15px;
        }
    }
}

@media (max-width:1199px) and (min-width:769px) {
    .basic-select {
        margin: 0 50px 0px 80px !important;
    }
}

@media (max-width:767px) {
    .backButton {
        display: none;
    }

    .basic-heading {
        font-size: 18px;
        justify-content: start !important;
        margin-left: 10px;
    }

    .basic-select {
        margin: 0px !important;
        margin-top: 20px;

        .MuiInput-input {
            font-size: 14px !important;
        }

        .MuiInputBase-root {
            margin-right: 10px !important;
        }
    }

    .count-show {
        margin-right: 10px !important;
    }

    .saveButton {
        margin-right: 0px !important;
    }

    .saveExitButton {
        width: 200px;
        justify-content: space-between !important;
    }

    .allButtons {
        margin: 20px 0 20px 0px !important;
    }

    .otherpet .MuiFormLabel-root {
        font-size: 16px !important;
    }

    .count-heading {
        font-size: 16px !important;
    }
}

@media (min-width:'768px') {
    // .saveButton{
    //     margin-right: 50px !important;
    // }
}

.allButtons {
    margin: 20px 0 20px 0px;
}

@media (min-width:760px) and (max-width:770px) {
    .saveButton-continue-basis {
        margin-right: 0 !important;
    }

    .backButton-basis {
        margin-left: 0px !important;
    }
}

@media (min-width:770px) and (max-width:1030px) {
    .saveButton-continue-basis {
        margin-right: 30px !important;
    }

    .backButton-basis {
        margin-left: 30px !important;
    }
}

@media (min-width:1100px) {
    .saveButton-continue-basis {
        margin-right: 50px !important;
    }
}