.nav-link {
    color: #000000 !important;
}

.nav-my-value {
    width: 100% !important;
    justify-content: center;
}

@media (max-width: 991px) {
    .navbar{
        .mobile-logo{
            margin-left: 0px !important;
        }
    }
    
    .navbar-nav {
        width: 100% !important;
    }

    .navbar-collapse .d-flex {
        width: 100% !important;
        justify-content: space-between !important;
        margin-top: 10px !important;
    }

    .navbar-collapse .d-flex .nav-link {
        margin-right: 0 !important;
        margin-left: 10px !important;
    }

    .navbar-collapse .d-flex .d-flex {
        // flex-direction: column !important;
        align-items: flex-start !important;
    }

    .navbar-collapse .d-flex .d-flex img {
        margin-bottom: 10px !important;
    }

    .dropdown-center .dropdown-menu {
        left: 0 !important;
    }
}


.contact-modal {
    padding: 10px;

    .contact-heading {
        font-size: 26px;
        font-weight: 500;
        display: flex;
        justify-content: center;
    }

    .message-heading {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .email-error {
        font-size: 14px;
        font-weight: 400;
        color: red;
    }
}
.navbar{
    .mobile-logo{
        margin-left: 40px ;
    }
}