.payment-detail-change {
    background-color: #ffffff;
    padding: 15px 20px;
    height: auto;

    .payment-heading {
        font-size: 24px;
        font-weight: 600;
        border-bottom: 1.8px solid #e1e3e1;
    }

    .method-heading {
        font-size: 20px;
        font-weight: 600;
    }

    .card-container {
        width: 120px;
        height: auto;
        border: 1.5px solid #505050;

        .credit-heading {
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.listing-detail-payment {
    background-color: #ffffff;
    padding: 15px 20px;
    height: auto;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .listing-main-heading {
        font-size: 24px;
        font-weight: 600;
        border-bottom: 1.8px solid #e1e3e1;
    }

    .payment-card {
        padding: 0px 30px 15px 10px;

        .payment-descrption {
            font-size: 16px;
            font-weight: 400;
            color: #707070;
        }

        .price-payment {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
        }
    }
}

@media (max-width:991px) {
    .payment-detail-change {
        margin-top: 15px !important;
    }

    .listing-detail-payment {
        margin-top: 15px !important;
    }
}