.list-profile {
    background: rgb(242, 242, 247);
    margin-top: 20px;

    .arrow-box-list {
        background-color: #ffffff;
        width: 60px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .list-profile-section {
        height: auto;
        background-color: #ffffff;
        padding: 20px 20px 36px 0;
        margin-top: 20px;
        box-shadow: 0px 2px 8px 0px #0000001F;

        @media (max-width:575px) {
            padding: 0px !important;
            margin: 20px 10px 0 10px !important;
        }

        .tenantProfilePic {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            border: 1px solid grey;
        }

        .phone-container {
            display: none !important;

            @media (min-width:991px) and (max-width:1399px) {
                display: flex !important;
                justify-content: center !important;
            }

            @media (min-width:765px) and (max-width:915px) {
                display: flex !important;
                justify-content: center !important;
            }

            .phone-number {
                font-size: 16px;
                font-weight: 400;
                color: #707070;
                padding-left: 8px;
                display: flex;
                // align-items: center;
            }
        }

        .email-conatiner {
            display: flex;
            justify-content: center;

            @media (min-width:991px) and (max-width:1399px) {
                // display: flex !important;
                // justify-content: center !important;
                display: none !important;
            }

            @media (min-width:765px) and (max-width:915px) {
                display: none !important;
            }

            .phone-number {
                font-size: 16px;
                font-weight: 400;
                color: #707070;
                padding-left: 8px;
                display: flex;
                align-items: center;
            }

        }

        .tenantName {
            font-size: 24px;
            font-weight: 700;
        }

        .tenantCompanyName {
            color: #919CA5;
            font-size: 18px;
        }
    }

    .user-profile-list-container {
        height: 780px;
        overflow-y: scroll;

        @media (max-width:575px) {
            height: auto !important;
        }

        @media (min-width:765px) and (max-width:821px) {
            height: 570px !important;
        }

        @media (max-width:1399px) {
            margin-top: 20px !important;
            overflow-x: hidden !important;
        }


        .user-profile-list {
            // width: 780px;
            height: auto;
            margin-bottom: 15px;
            background-color: #ffffff;
            box-shadow: 0px 2px 8px 0px #0000001F;
            padding: 10px;

            @media (max-width:575px) {
                padding: 0px !important;
                margin: 0 10px 15px 10px !important;
            }

            .MuiBox-root {
                padding: 10px !important;
            }

            .MuiMobileStepper-root {
                display: flex;
                align-items: center;
                background-color: transparent;
                color: #ffff !important;
                margin-top: -124px !important;
                margin-left: -20px !important;
                margin-right: -23px !important;

                @media (min-width:431px) and (max-width:575px) {
                    margin-top: -190px !important;
                }

                @media (min-width:913px) and (max-width:1024px) {
                    margin-top: -111px !important;
                }

                @media (min-width:819px) and (max-width:912px) {
                    margin-top: -90px !important;
                }

                @media (min-width:1400px) and (max-width:1680px) {
                    margin-top: -90px !important;
                }
            }


            .MuiPaper-root {
                background-color: transparent !important;
                color: #ffff !important;
            }

            .MuiButtonBase-root {
                display: inline-block;
                justify-content: inherit !important;
            }

            .MuiBox-root {
                background: rgb(242, 242, 247) !important;
            }

            .listing-heading {
                padding-top: 90px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rent-box {
                padding-top: 32% !important;

                @media (min-width:913px) and (max-width:1025px) {
                    padding-top: 26% !important;
                }

                .rental-heading {
                    padding-top: 70px;
                    color: #707070;
                    font-size: 16px;
                    font-family: "Proxima Nova";
                    display: flex;
                    justify-content: center;
                }

                .rental-price {
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px 6px 5px 10px;
                    font-family: Proxima_nova_reg;
                }

                .rent-heading {
                    display: flex;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                }

                .deposit-price {
                    font-size: 20px;
                    font-weight: 600;
                    padding: 8px 0 5px 10px;
                    font-family: "Proxima Nova";
                }
            }

            .deposit-type {
                display: none !important;

                .rental-heading {
                    padding-top: 70px;
                    color: #707070;
                    font-size: 16px;
                    font-family: "Proxima Nova";
                    display: flex;
                    justify-content: center;
                }

                .rental-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 10px 6px 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }

                .rent-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                }

                .deposit-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 8px 0 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }

            }

            .mobile-type {
                display: none !important;

                .rental-heading {
                    padding-top: 70px;
                    color: #707070;
                    font-size: 16px;
                    font-family: "Proxima Nova";
                    display: flex;
                    justify-content: center;
                }

                .rental-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 10px 6px 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }

                .rent-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                }

                .deposit-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 8px 0 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }

            }


            .listing-card-detail {

                @media (max-width:575px) {
                    padding: 0 10px 0 10px !important;
                }

                .mobile-subscription {
                    @media (min-width:430px) and (max-width:575px) {
                        margin-top: 150px !important;
                    }

                    @media (max-width:430px) {
                        margin-top: 85px !important;
                    }
                }

                .listing-user-heading {
                    font-size: 23px;
                    font-weight: 600;
                    color: #2F86D1;
                }

                .listing-user-month {
                    display: flex;
                    align-self: center;
                    color: #2F86D1;
                    margin-top: 5px;
                    font-size: 16px;
                    font-weight: 600;
                }

                .request-profile {
                    background-color: #2F86D1;
                    color: #ffffff;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 600;
                    padding-right: 3px;
                    padding-top: 3px;
                    cursor: pointer;
                }

                .categroy-heading {
                    color: #707070;
                    font-size: 12px;
                    font-weight: 500;
                    margin-left: 5px;
                    padding-top: 5px;
                }

                .category-value {
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 3px;
                    padding-top: 4px;

                    @media (min-width:765px) and (max-width:1024px) {
                        padding-top: 3px;
                    }
                }

                .select-value-heading {
                    font-size: 14px;
                    font-weight: 600;
                    margin-top: 10px;
                }

                .request-genarte {
                    font-size: 18px;
                    font-weight: 600;
                    margin-right: 20px;
                    color: #2F86D1;
                    margin-top: 10px;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .user-heading {
                    font-size: 18px !important;
                    font-weight: 600 !important;
                    font-family: "Proxima Nova";
                }

                .user-dot {
                    font-size: 20px;
                    font-weight: 800;
                    margin: -6px 6px 0 6px;
                }

                .property-owner {
                    color: #707070;
                    font-size: 16px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                }

                .view-check {
                    margin-top: 110px !important;

                    @media (max-width:575px) {
                        margin-top: 0px !important;

                    }

                    @media (min-width:808px) and (max-width:820px) {
                        margin-top: 55px !important;
                    }

                    @media (min-width:821px) and (max-width:912px) {
                        margin-top: 60px !important;
                    }

                    @media (min-width:1400px) and (max-width:1690px) {
                        margin-top: 55px !important;
                    }
                }

                .view-heading {
                    font-size: 14px;
                    font-weight: 500;
                    color: #2F86D1;
                    text-decoration: underline;
                }

            }

        }
    }
}

@media (min-width:1400px) and (max-width:1690px) {
    .list-profile .user-profile-list-container .user-profile-list .rent-box {
        display: none !important;
    }

    .list-profile .user-profile-list-container .user-profile-list .deposit-type {
        padding-top: 28% !important;
        display: inline-block !important;
    }
}

@media (min-width:1200px) and (max-width:1523px) {
    .list-profile .user-profile-list-container .user-profile-list .rent-box {
        padding-top: 24% !important;
    }

}

@media (min-width:768px) and (max-width:991px) {
    .list-profile .user-profile-list-container .user-profile-list .rent-box {
        // padding-top: 42% !important;
        display: none !important;
    }

    .list-profile .user-profile-list-container .user-profile-list .deposit-type {
        // padding-top: 15px !important;
        padding-top: 42% !important;
        display: inline-block !important;
    }
}

@media (min-width:808px) and (max-width:913px) {
    .list-profile .user-profile-list-container .user-profile-list .rent-box {
        display: none !important;
    }

    .list-profile .user-profile-list-container .user-profile-list .rent-box .rental-price {
        font-size: 16px !important;
    }

    .list-profile .user-profile-list-container .user-profile-list .deposit-type {
        padding-top: 26% !important;
        display: inline-block !important;
    }
}


@media (min-width:770px) and (max-width:808px) {
    .list-profile .user-profile-list-container .user-profile-list .rent-box {
        display: none !important;
    }

    .list-profile .user-profile-list-container .user-profile-list .listing-card-detail .view-check {
        margin-top: 90px !important;
    }

}

@media (min-width:575px) and (max-width:768px) {
    .list-profile .user-profile-list-container .user-profile-list .rent-box {
        display: none !important;
    }

    .list-profile .user-profile-list-container .user-profile-list .deposit-type {
        padding-top: 30% !important;
        display: inline-block !important;
    }

    .list-profile .user-profile-list-container .user-profile-list .listing-card-detail .view-check {
        margin-top: 60px !important;
    }

    .list-profile .user-profile-list-container .user-profile-list .MuiMobileStepper-root {
        margin-top: -100px !important;
    }

    .list-profile .list-profile-section {
        margin-right: 20px !important;
    }

    .list-profile .user-profile-list-container {
        margin-right: 20px !important;
    }
}

@media (min-width:430px) and (max-width:575px) {
    .list-profile .user-profile-list-container .user-profile-list .rent-box {
        display: none !important;
    }
}

@media (max-width:430px) {
    .list-profile .user-profile-list-container .user-profile-list .rent-box {
        display: none !important;
    }

}

@media (max-width:992px) {
    .list-profile .list-profile-section {
        padding: 0px 0 30px 0 !important;
    }
}