body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Proxima_nova_medium;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: Proxima_nova_bold;
  src:url('./assets/Fonts/pnov/Proxima_nova_bold.otf');
}
@font-face {
  font-family: Proxima_nova_medium;
  src:url('./assets/Fonts/pnov/Proxima_nova_medium.otf');
}
@font-face {
  font-family: Proxima_nova_reg;
  src:url('./assets/Fonts/pnov/Proxima_nova_reg.otf');
}
@font-face {
  font-family: Proxima_nova_light;
  src:url('./assets/Fonts/pnov/Proxima_nova_light.otf');
}
:root {
  --primary:#0075CA;
  --secondary:#E7F4FE;
  --blue-33:#338BF8;
  
  @media screen and (min-width:1400px) {
    --big-heading:24px;
    --medium-heading:18px;
    --small-heading:14px;
  }
  @media screen and (min-width:1200px) and (max-width:1400px) {
    --big-heading:24px;
    --medium-heading:18px;
    --small-heading:14px;
  }
  @media screen and (min-width:992px) and (max-width:1200px) {
    --big-heading:24px;
    --medium-heading:18px;
    --small-heading:14px;
  }
  @media screen and (min-width:768px) and (max-width:992px) {
    --big-heading:24px;
    --medium-heading:18px;
    --small-heading:14px;
  }
  @media screen and (min-width:576px) and (max-width:768px) {
    --big-heading:20px;
    --medium-heading:16px;
    --small-heading:12px;
  }
  @media screen and (max-width:576px)  {
    --big-heading:20px;
    --medium-heading:14px;
    --small-heading:12px;
  }
}