.listing-manager-tab {
    margin-left: 20px;
    width: 100% !important;

    .MuiAppBar-colorPrimary {
        color: #000000;
        background-color: #ffffff !important;
    }

    .MuiTab-root {
        min-width: 175px !important;

        @media (max-width:575px) {
            min-width: 33.33% !important;
        }
    }

    .MuiTabs-root {
        width: 525px;
        border: 0.5px solid #909090;

        @media (min-width:375px) and (max-width:575px) {
            width: 100% !important;
            min-height: 40px !important;
        }

        @media (max-width:375px) {
            width: 100% !important;
            margin-right: 10px !important;
        }
    }

    .MuiTab-wrapper {
        @media (max-width:575px) {
            font-size: 10px !important;
        }
    }

    .MuiButtonBase-root {
        border-right: 1px solid #909090;
        background-color: #ffffff;
    }

    .MuiTab-textColorInherit.Mui-selected {
        background-color: #2975ca !important;
        color: #ffffff;

    }

    .MuiTabs-indicator {
        background-color: transparent !important;
    }
}

.listing-tab {

    @media (min-width:700px) and (max-width:770px) {
        margin-right: 15px !important;
    }

    .MuiBox-root {
        padding: 24px 0 0 0 !important;
        margin: 0px !important;
    }

    .listing-tab-card {
        height: 320px;
        overflow-y: scroll;

        .mobile-listing-manager {
            position: relative !important;

            .mobile-listing-manager-icon {
                position: absolute !important;
                top: 5%;
                right: 20px;
                // z-index: 1;

            }
        }


        .MuiBox-root {
            padding: 10px !important;
        }

        .MuiBox-root {
            padding: 24px 0 0 0 !important;
            margin: 0px !important;
        }

        .MuiMobileStepper-root {
            display: flex;
            align-items: center;
            background-color: transparent;
            color: #ffff !important;
            margin-top: -130px !important;
            margin-left: -20px !important;
            margin-right: -23px !important;

            @media (min-width:575px) and (max-width:1024px) {
                margin-top: -100px !important;
            }

            @media (min-width:431px) and (max-width:575px) {
                margin-top: -190px !important;
            }

        }

        .MuiPaper-root {
            background-color: transparent !important;
            color: #ffff !important;
        }

        .MuiButtonBase-root {
            display: inline-block;
            justify-content: inherit !important;
        }

        .MuiBox-root {
            background: rgb(242, 242, 247) !important;
        }

        .listing-heading {
            padding-top: 90px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rent-box {
            padding-top: 40% !important;

            .rental-heading {
                padding-top: 70px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 20px;
                font-weight: 600;
                padding: 10px 6px 5px 10px;
                font-family: "Proxima Nova";
            }

            .rent-heading {
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 20px;
                font-weight: 600;
                padding: 8px 0 5px 10px;
                font-family: "Proxima Nova";
            }
        }

        .deposit-type {
            display: none !important;
        }

        .mobile-type {
            display: none !important;

            .rental-heading {
                padding-top: 70px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 16px;
                font-weight: 600;
                // padding: 10px 6px 5px 10px;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }

            .rent-heading {
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 16px;
                font-weight: 600;
                // padding: 8px 0 5px 10px;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }


        }

        .listing-card-detail {

            .listing-user-heading {
                font-size: 23px;
                font-weight: 600;
                color: #2F86D1;
            }

            .listing-user-month {
                display: flex;
                align-self: center;
                color: #2F86D1;
                margin-top: 5px;
                font-size: 16px;
                font-weight: 600;
            }

            .request-profile {
                background-color: #2F86D1;
                color: #ffffff;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 600;
                padding-right: 3px;
                padding-top: 3px;
                cursor: pointer;
            }

            .categroy-heading-type {
                color: #707070;
                font-size: 12px;
                font-weight: 400;
                margin-left: 8px;
                font-family: "Proxima_nova_medium";
                display: flex;
                align-items: center;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }

            .categroy-heading {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
                margin-top: 3px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

            }

            .category-value {
                font-size: 16px;
                font-weight: 600;
                margin-left: 5px;
                margin-top: 1px;
            }

            .select-value-heading {
                font-size: 16px;
                font-weight: 600;
                margin-top: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .request-genarte {
                font-size: 18px;
                font-weight: 600;
                margin-right: 20px;
                color: #2F86D1;
                margin-top: 10px;
                text-decoration: underline;
                cursor: pointer;
            }

            .user-heading {
                font-size: 18px !important;
                font-weight: 600 !important;
                font-family: "Proxima Nova";
            }

            .user-dot {
                font-size: 20px;
                font-weight: 800;
                margin: -6px 6px 0 6px;
            }

            .property-owner {
                color: #707070;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
            }

            .full-screen-detail {
                display: flex !important;
                align-items: center !important;

                @media (min-width:766px) and (max-width:915px) {
                    display: none !important;
                }
            }

            .ipaid-detail {
                display: none !important;

                @media (min-width:766px) and (max-width:915px) {
                    display: flex !important;
                    justify-content: end !important;
                }
            }


            .view-heading {
                font-size: 16px;
                font-weight: 600;
                color: #2F86D1;
                text-decoration: underline;
            }

        }

    }

    .NoMyListing {
        // width: 880px;
        background: white;
        height: 280px;
        border: 1px dashed black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Proxima_nova_medium';

        .NoListing {
            // padding-left: 30px;
            text-align: center;
            font-size: 20px;
        }

        .ManagingRequest {
            font-size: 20px;
            font-weight: 700;
            color: #2F86D1;
            border-bottom: 1px solid #2F86D1;
            cursor: pointer;
            text-align: center;
        }

    }

}

.listing-tab .listing-tab-card::-webkit-scrollbar {
    width: 8px !important;
    z-index: 1 !important;
    display: inline-block !important;
}

.listing-tab .listing-tab-card::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}

.listing-tab .listing-tab-card::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
    border-radius: 3px !important;
}

.listing-tab .listing-tab-card::-webkit-scrollbar-thumb:hover {
    background-color: #909090 !important;
}

@media (min-width:1200px) and (max-width:1523px) {
    .listing-tab .listing-tab-card .rent-box {
        padding-top: 40% !important;
    }

}

@media (max-width:1199px) {
    .listing-manager-tab {
        margin-left: 0px !important;
    }
}

@media (min-width:991px) and (max-width:1311px) {
    .listing-tab .listing-tab-card .rent-box {
        padding-top: 25% !important;
    }
}

@media (min-width:768px) and (max-width:808px) {
    .listing-tab .listing-tab-card .rent-box {
        display: none !important;
    }

    // .listing-tab .listing-tab-card .view-heading {
    //     display: none !important;
    // }

    .listing-tab .listing-tab-card .deposit-type {
        // padding-top: 15px !important;
        padding-top: 37% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }
}

@media (min-width:1024px) and (max-width:1199px) {
    .listing-tab .listing-tab-card .rent-box {
        padding-top: 23% !important;
    }

}

@media (min-width:800px) and (max-width:950px) {
    .listing-tab .listing-tab-card .rent-box {
        padding-top: 23% !important;
    }

}


@media (max-width:768px) {
    .listing-tab .listing-tab-card .rent-box {
        display: none !important;
    }

    .listing-tab .listing-tab-card .deposit-type {
        // padding-top: 15px !important;
        padding-top: 30% !important;
    }

}

@media (max-width:767px) {
    .mobile-buttons {
        display: none !important;
    }

    .listing-tab .listing-tab-card .mobile-type {
        display: flex !important;
        margin-top: 10px !important;
    }

    .listing-tab-detail {
        .listing-managed-heading {
            font-size: 16px !important;
        }

        .request-heading {
            font-size: 16px !important;
        }

    }

    .listing-tab {
        .category-value {
            text-overflow: ellipsis;
        }

        .listing-tab-card {
            box-shadow: 0px 4px 4px 0px #0000001F;

            .mobile-padding {
                padding: 0px !important;
            }

            overflow-x: hidden !important;

            .listing-card-detail {
                // padding-top: 22% !important;

                .user-heading {
                    font-size: 16px !important;
                }
            }
        }
    }

}

@media (min-width:430px) and (max-width:575px) {
    .listing-tab .listing-tab-card .listing-card-detail .section-profile {
        margin-top: 155px !important;
    }
}

@media (max-width:430px) {
    .listing-tab .listing-tab-card .listing-card-detail .section-profile {
        margin-top: 90px !important;
    }
}


@media (width:375px) {
    .listing-tab .listing-tab-card {
        height: 430px !important;
    }
}

@media (width:414px) {
    .listing-tab .listing-tab-card {
        height: 650px !important;
    }
}

@media (width:390px) {
    .listing-tab .listing-tab-card {
        height: 610px !important;
    }
}

@media (width:430px) {
    .listing-tab .listing-tab-card {
        height: 682px !important;
    }
}

@media (width:393px) {
    .listing-tab .listing-tab-card {
        height: 545px !important;
    }
}

@media (width:412px) {
    .listing-tab .listing-tab-card {
        height: 667px !important;
    }
}

@media (width:360px) {
    .listing-tab .listing-tab-card {
        height: 495px !important;
    }
}

@media (width:375px) and (height:812px) {
    .listing-tab .listing-tab-card {
        height: 565px !important;
    }
}

@media (width:320px) {
    .listing-tab .listing-tab-card {
        height: 225px !important;
    }
}

@media (width:414px) and (height:736px) {
    .listing-tab .listing-tab-card {
        height: 500px !important;
    }
}

@media (width:411px) {
    .listing-tab .listing-tab-card {
        height: 493px !important;
    }
}

@media (width:411px) and (height:823px) {
    .listing-tab .listing-tab-card {
        height: 580px !important;
    }
}

@media (width:540px) {
    .listing-tab .listing-tab-card {
        height: 473px !important;
    }
}