.side-container {
    .MuiStepLabel-label {
        font-size: 18px !important;
        font-weight: 500 !important;
    }

    .MuiStepLabel-label.MuiStepLabel-completed {
        color: #71CE50 !important;
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    .MuiStepIcon-root.MuiStepIcon-completed {
        color: #71CE50 !important;
    }

    .MuiStepContent-root {
        border-left: 4px solid #bdbdbd !important;
    }

    .MuiStepConnector-lineVertical {
        border-left-width: 4px !important;
        min-height: 60px !important;
    }

}

// @media (max-width:1100px) {
.side-section-large {
    width: 100% !important;

    .MuiStepper-root {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    .MuiStepLabel-label {
        font-size: 18px !important;
        font-weight: 500 !important;
        // display: none !important;
    }

    .MuiStepLabel-label.MuiStepLabel-completed {
        color: #71CE50 !important;
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    .MuiStepIcon-root.MuiStepIcon-completed {
        color: #71CE50 !important;
    }

    .MuiStepContent-root {
        border-left: 4px solid #bdbdbd !important;
    }

    .MuiStepConnector-lineVertical {
        border-left-width: 4px !important;
        min-height: 60px !important;
    }

    .active-value {
        font-size: 24px;
        font-weight: 600;
        font-family: Proxima_nova_reg;
    }
    .MuiStep-root.MuiStep-horizontal{
        padding-left: 2px;
        padding-right: 2px;
    }
}

// }
@media (max-width:768px) {
    .active-value {
        font-size: 14px !important;
    }
    .side-section-large {

        .MuiStepper-root{
            padding: 0px !important;
            padding-top: 25px !important;
        }
    }
    
}