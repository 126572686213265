.arrow-box-profile {
    background-color: #ffffff;
    width: 60px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tenant-profile-section {
    font-family: 'Proxima_nova_medium';
    overflow: hidden;
    background: rgb(242, 242, 247);
    padding: 80px 40px 10px 25px !important;

    @media (min-width:575px) and (max-width:1199px) {
        padding: 80px 15px 10px 25px !important;
    }

    .profile {
        font-size: 20px;

    }

    .tenet-profile-heading {
        font-size: 20px;
        margin-top: -30px;
    }


    .profileDetail {
        text-align: center;
        padding: 30px 0 30px 0;
        margin: 10px 0px 0 13px;
        background-color: #ffffff;
        box-shadow: 0px 2px 8px 0px #0000001F;
        height: 430px;

        @media (min-width:575px) and (max-width:1199px) {
            margin: 10px 10px 0 0px !important;
        }

        .tenantProfilePic {
            width: 148px;
            height: 148px;
            border-radius: 50%;
            border: 1px solid grey;
        }

        .tenantName {
            font-size: 32px;
            font-weight: 700;
        }

        .phoneNumber {
            display: flex;
            justify-content: center;

            .tenantCompanyName {
                color: #919CA5;
                font-size: 20px;
                display: flex;
                align-items: center;
            }
        }

        .email-conatiner {
            display: none !important;

            .tenantCompanyName {
                color: #919CA5;
                font-size: 20px;
                display: flex;
                align-items: center;
            }
        }


        .msg-box {
            background: #ffffff;
            color: #2F86D1;
            font-size: 16px;
            font-weight: 500;
            margin-left: 15px;
            width: 180px;
            margin: auto;
            border: 1px solid #E0E0E0;
            height: 44px;
            align-items: center;
            gap: 5px
        }
    }

    .tenent-profile-list-container {
        height: 780px;
        overflow-y: scroll;
        overflow-x: hidden;

        @media (max-width:575px) {
            height: auto !important;
        }

        .mobile-fav {
            position: relative !important;

            .mobile-fav-icon {
                position: absolute !important;
                top: 5%;
                right: 20px;
                // z-index: 1;

                .mobile-fav {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }
            }
        }

        .MuiBox-root {
            padding: 10px !important;
        }

        .MuiMobileStepper-root {
            display: flex;
            align-items: center;
            background-color: transparent;
            color: #ffff !important;
            margin-top: -124px !important;
            margin-left: -20px !important;
            margin-right: -23px !important;
        }

        .MuiPaper-root {
            background-color: transparent !important;
            color: #ffff !important;
        }

        .MuiButtonBase-root {
            display: inline-block;
            justify-content: inherit !important;
        }

        .listing-card-detail {

            @media (max-width:575px) {
                padding: 0 10px 0 10px !important;
            }

            .section-profile {
                @media (min-width:430px) and (max-width:575px) {
                    margin-top: 155px !important;
                }

                @media (max-width:430px) {
                    margin-top: 85px !important;
                }
            }

            .listing-user-heading {
                font-size: 23px;
                font-weight: 600;
                color: #2F86D1;
            }

            .listing-user-month {
                display: flex;
                align-self: center;
                color: #2F86D1;
                margin-top: 5px;
                font-size: 16px;
                font-weight: 600;
            }

            .request-profile {
                background-color: #2F86D1;
                color: #ffffff;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 600;
                padding-right: 3px;
                padding-top: 3px;
                cursor: pointer;
            }

            .categroy-heading-type {
                color: #707070;
                font-size: 12px;
                font-weight: 400;
                margin-left: 8px;
                font-family: "Proxima_nova_medium";
                display: flex;
                align-items: center;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }

            .categroy-heading {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
                margin-top: 3px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

            }

            .category-value {
                font-size: 16px;
                font-weight: 600;
                margin-left: 5px;
                margin-top: 1px;
            }

            .select-value-heading {
                font-size: 16px;
                font-weight: 600;
                margin-top: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .custom-margin {
                margin-top: 17px !important;
                margin-bottom: 10px !important;
            }

            .request-genarte {
                font-size: 18px;
                font-weight: 600;
                margin-right: 20px;
                color: #2F86D1;
                margin-top: 10px;
                text-decoration: underline;
                cursor: pointer;
            }

            .user-heading {
                font-size: 18px !important;
                font-weight: 600 !important;
                font-family: "Proxima Nova";
            }

            .user-dot {
                font-size: 20px;
                font-weight: 800;
                margin: -6px 6px 0 6px;
            }

            .property-owner {
                color: #707070;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
            }

            .ipaid-detail {
                display: none !important;

                @media (min-width:766px) and (max-width:915px) {
                    display: flex !important;
                    justify-content: end !important;
                }
            }

            .full-screen-detail {
                display: flex !important;
                align-items: center !important;

                @media (min-width:766px) and (max-width:915px) {
                    display: none !important;
                }
            }

            .view-heading {
                font-size: 18px;
                font-weight: 600;
                color: #2F86D1;
                text-decoration: underline;

                @media (min-width:766px) and (max-width:915px) {
                    font-size: 16px !important;
                }

            }

        }
    }

    .empty-tenant-profile {
        font-family: "Proxima_nova_reg";
        width: 840px;
        height: 310px;
        background: white;
        // padding: 10px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
    }

}

.disableButton {
    color: rgb(255, 255, 255);
    background: linear-gradient(rgb(180, 189, 196) 0%, rgb(145, 156, 165) 100%);
    border: none;
    width: 250px;
    height: 50px;
    cursor: pointer;
}

.continueButton {
    color: #fff;
    background-color: linear-gradient(180deg, #40A0E5 0%, #136BAB 100%);

}

.tenant-profile-section .tenent-profile-list-container::-webkit-scrollbar {
    width: 8px !important;
    z-index: 1 !important;
    display: inline-block !important;
}

.tenant-profile-section .tenent-profile-list-container::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}

.tenant-profile-section .tenent-profile-list-container::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
    border-radius: 3px !important;
}

.tenant-profile-section .tenent-profile-list-container::-webkit-scrollbar-thumb:hover {
    background-color: #909090 !important;
}

@media (max-width:1199px) {
    .tenent-profile-list-container {
        margin-top: 20px !important;
    }

    .tenant-profile-section .tenet-profile-heading {
        margin-top: 10px !important;
        margin-left: 0px !important;
    }
}

// .mobile-view-heading {
//     margin-left: 20px !important;
// }

@media (min-width:431px) and (max-width:575px) {
    .tenant-profile-section .tenent-profile-list-container .MuiMobileStepper-root {
        // margin-top: -100px !important;
        margin-top: -190px !important;
    }
}

@media (min-width:575px) and (max-width:768px) {
    .tenant-profile-section .tenent-profile-list-container .MuiMobileStepper-root {
        margin-top: -100px !important;
    }
}

@media (max-width:575px) {
    .tenant-profile-section .profileDetail .phoneNumber {
        display: none !important;
    }

    .tenant-profile-section .profileDetail .email-conatiner {
        display: flex !important;
        justify-content: center !important;
    }
}

@media (max-width:767px) {
    .tenant-profile-section {
        padding: 40px 25px 10px 25px !important;

        .profile-icon {
            padding-top: 25px !important
        }

        .tenent-profile-list-container {
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        .profile-mobile {
            padding-left: 0px !important;

            .profileDetail {
                margin: 10px 0px 0 0px;

                .tenantCompanyName {
                    font-size: 16px !important;
                }

            }
        }
    }
}

@media (width:375px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 541px !important;
    }
}

@media (width:414px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 768px !important;
    }
}

@media (width:390px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 717px !important;
    }
}

@media (width:430px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 805px !important;
    }
}

@media (width:393px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 660px !important;
    }
}

@media (width:412px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 788px !important;
    }
}

@media (width:360px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 615px !important;
    }
}

@media (width:375px) and (height:812px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 685px !important;
    }
}

@media (width:320px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 354px !important;
    }
}

@media (width:414px) and (height:736px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 609px !important;
    }
}

@media (width:411px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 605px !important;
    }
}

@media (width:411px) and (height:823px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 697px !important;
    }
}

@media (width:540px) {
    .tenant-profile-section .tenent-profile-list-container {
        height: 595px !important;
    }
}