.locate-select {
    margin-top: 80px;

    .locate-heading {
        color: #2F86D1;
        font-size: 34px;
        font-weight: 600;
        font-family: "Proxima Nova";
        display: flex;
        justify-content: center;
        padding-top: 80px;
    }

    .geosuggest {
        .geosuggest__input-wrapper {
            height: 100%;
            width: 100%;
        }

        .geosuggest__input {
            width: 100%;
            height: 100%;
            padding: 20px !important;
            border: none;
            color: #000000;
            border-radius: 2px !important;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        .geosuggest__input:focus {
            border: none;
        }

        .geosuggest__input::placeholder {
            color: #bcbcbc;
        }

        .geosuggest__suggests-wrapper {
            background-color: #ffffff;
            max-width: 100%;
            position: absolute;

            .geosuggest__item {
                padding: 5px 15px 5px 0px;
                font-size: 18px;
                list-style: none;
                cursor: pointer;
            }

            .geosuggest__item:hover,
            .geosuggest__item:focus {
                background: #ffffff;
            }

        }

        .geosuggest__input:focus,
        input:focus {
            border: 1px solid rgba(0, 0, 0, 0.1);
        }

        .geosuggest__suggests--hidden {
            max-height: 0;
            overflow: hidden;
            border-width: 0;
        }
    }

    // .geo-value.geosuggest .geosuggest__suggests-wrapper {
    //     z-index: 2 !important;
    // }

    // .geo-value2.geosuggest .geosuggest__suggests-wrapper {
    //     z-index: 2 !important;
    // }

    .search-btn-container {
        background-color: #2F86D1;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 5px -3px 5px 0;
        height: 48px;
        width: 140px;
        padding-left: 18px;
        // border-radius: 10px;
        left: 73% !important;
    }

    .feild-unit {
        // margin-left: 10px;
        margin-top: 10px;
        margin-right: 10px !important;

        @media (max-width:575px) {
            margin-left: 10px;
        }
    }

    .feild-unit input[type='number']::-webkit-inner-spin-button,
    .feild-unit input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }


    .feild-input {
        // margin-left: 10px;
        margin-top: 20px;
        margin-right: 10px !important;

        @media (max-width:575px) {
            margin-left: 10px;
        }

    }

    .feild-dropdown {
        // margin-left: 10px;
        margin-top: 20px;
        margin-right: 10px !important;

        @media (max-width:575px) {
            margin-left: 10px;
        }
    }
}

.map-section {
    margin-top: 125px;

    @media (max-width:1030px) {
        // margin: 0 -28px 0 -28px;
        margin: 0px !important;
        overflow-x: hidden !important;
    }
}

@media (min-width:1199px) and (max-width: 1421px) {
    .locate-select .search-btn-container {
        left: 67% !important;
    }
}

@media (min-width:1630px) and (max-width:1650px) {
    .locate-select .search-btn-container {
        left: 71% !important;
    }
}

@media (min-width: 1422px) and (max-width: 1630px) {
    .locate-select .search-btn-container {
        left: 68% !important;
    }
}

@media (min-width: 1199px) and (max-width: 1443px) {
    .locate-heading {
        font-size: 26px !important;
    }

    .locate-select .search-btn-container {
        left: 62% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .locate-select {
        margin: 0 50px 30px 80px !important;

        .locate-heading {
            padding-top: 0 !important;
        }
    }

    // .map-section {
    //     margin: 0 50px 30px 80px !important;
    // }

    .locate-select .search-btn-container {
        left: 83% !important;
    }
}

@media (max-width: 992px) {
    .locate-select {
        margin: 0 50px 30px 80px !important;

        .locate-heading {
            padding-top: 0 !important;
        }
    }

    // .map-section {
    //     margin: 0 50px 30px 80px !important;

    // }
}

@media (min-width:900px) and (max-width:950px) {
    .locate-select .search-btn-container {
        left: 81% !important;
    }
}

@media (min-width:768px) and (max-width:821px) {
    .locate-select .search-btn-container {
        left: 78% !important;
    }
}

@media (max-width:768px) {
    .locate-select .search-btn-container {
        left: 76% !important;
    }
}



.searchName {
    font-size: 20px,
}

@media (max-width:767px) {

    .locate-select {
        margin: 0px 0px 30px 0px !important;

        .geo-value2.geosuggest .geosuggest__suggests-wrapper {
            z-index: 2 !important;
        }

        .locate-heading {
            font-size: 16px !important;
            justify-content: start;
            margin-left: 10px !important;
        }

        .MuiFormControl-root.formControl {
            margin: 0px !important;
        }

        .MuiInputLabel-root {
            color: rgba(0, 0, 0, 0.38);
        }
    }

    // .map-section {
    //     margin: 0px !important;
    // }

    .search-btn-container {
        display: none !important;

    }

    .backButton {
        display: none;
    }

    .buttons {
        margin-top: 20px !important;
        justify-content: center !important;
        overflow: hidden;
        margin-bottom: 10px;
    }

    .saveExitButton {
        width: 200px;
        justify-content: space-between !important;
    }

    .locate-select .geosuggest .geosuggest__input {
        font-size: 14px !important;
    }

    .searchInput {
        position: relative;
        margin-top: -30px !important;
    }
}

@media (min-width:375px) and (max-width:480px) {
    .locate-select {
        .geosuggest .geosuggest__input {
            font-size: 12px !important;
        }

        .geo-value input::placeholder {
            font-size: 12px !important;
        }


        .search-btn-container {
            width: 96px !important;
            padding-left: 10px !important;
            left: 71% !important;

            .searchName {
                font-size: 12px,
            }

            .geosuggest .geosuggest__input {
                font-size: 12px !important;

            }
        }
    }
}

.locate-select .geosuggest .geosuggest__input {
    width: 100% !important;
}

@media (max-width:360px) {
    .locate-select .geo-value input::placeholder {
        font-size: 10px !important;
    }
}