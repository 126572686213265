.find-people-tab {
    position: fixed;
    margin-bottom: 20px;
    width: 100% !important;
    height: auto !important;

    .MuiBox-root {
        padding: 24px 0px 0px 0px !important;
    }

    @media (max-width:575px) {
        margin-top: -5px !important;
    }

    .tab-value {
        margin: 25px 0px 0px 0px;

        @media (max-width:575px) {
            margin: 25px 5px 0px 5px !important;
        }
    }

    .MuiAppBar-colorPrimary {
        color: #000000;
        background-color: #ffffff !important;
    }

    .MuiTab-root {
        min-width: 137px;

        @media (max-width:575px) {
            min-width: 25% !important;
            max-width: 25% !important;
            line-height: 1.25 !important;
            // padding: 0px !important;
        }
    }

    .MuiTabs-root {
        max-width: 556px;
        border: 0.5px solid #909090;

        @media (min-width:375px) and (max-width:575px) {
            width: 100% !important;
            min-height: 40px !important;
        }

        @media (max-width:375px) {
            width: 100% !important;
            // margin-right: 10px !important;
        }
    }

    .MuiTab-wrapper {
        @media (max-width:575px) {
            font-size: 12px !important;
        }
    }

    .MuiButtonBase-root {
        border-right: 1px solid #909090;
        background-color: #ffffff;
    }

    .MuiTab-textColorInherit.Mui-selected {
        background-color: #2975ca !important;
        color: #ffffff;

    }

    .MuiTabs-indicator {
        background-color: transparent !important;
    }

}

.find-people-search {
    margin-top: 85px !important;
    margin-left: -19px;
    z-index: 1;

    .search-btn {
        background-color: #2F86D1;
        cursor: pointer;
        display: flex;
        align-items: center;
        // margin: 5px 0px 5px 30px;
        height: 48px;
        width: 120px;
        padding-left: 18px;
        border-radius: 4px;
        margin-right: 5px;
    }

    .MuiInputBase-root {
        display: flex !important;
        justify-content: space-between !important;
    }

    .search-check {
        .check-list {
            font-size: 16px;
            font-weight: 600;
            font-family: "Proxima Nova";
            color: #505050;
            display: flex;
            align-items: center;
        }
    }

    .search-find-list {
        // width: 760px;
        height: 400px;
        background-color: #ffffff;
        margin-top: 20px;
        // box-shadow: 0px 2px 8px 0px #0000001F;
        overflow-y: scroll;
        overflow-x: hidden;

        @media (max-width:575px) {
            margin-top: 110px !important;
        }

        @media (max-width:360px) {
            height: 326px !important;
        }

        @media (min-width:400px) and (max-width:415px) {
            height: 575px !important;
        }

        @media (min-width:420px) and (max-width:430px) {
            height: 592px !important;
        }

        @media (min-width:380px) and (max-width:400px) {
            height: 504px !important;
        }


        @media (min-height:660px) and (max-height:670px) {
            height: 326px !important;
        }

        @media (min-height:890px) and (max-height: 900px) {
            height: 555px !important;
        }

        @media (min-height:730px) and (max-height: 740px) {
            height: 400px !important;
        }

        @media (min-height:800px) and (max-height:815px) {
            height: 470px !important;
        }

        .find-heading {
            padding-left: 10px;
            padding-top: 10px;

            .find-main-heading {
                font-size: 18px;
                font-weight: 500;
                color: #282828;
            }

            .find-description {
                font-size: 16px;
                font-weight: 400;
                color: #707070;
            }
        }
    }

    .search-my-list {
        // width: 760px;
        height: 400px;
        background-color: #ffffff;
        margin-top: 20px;
        // box-shadow: 0px 2px 8px 0px #0000001F;
        overflow-y: scroll;

        @media (max-width:575px) {
            margin-top: 70px !important;
        }

        @media (max-width:360px) {
            height: 361px !important;
        }

        @media (min-width:400px) and (max-width:415px) {
            height: 610px !important;
        }

        @media (min-width:420px) and (max-width:430px) {
            height: 630px !important;
        }

        @media (min-width:380px) and (max-width:400px) {
            height: 540px !important;
        }


        @media (min-height:660px) and (max-height:670px) {
            height: 361px !important;
        }

        @media (min-height:890px) and (max-height: 900px) {
            height: 590px !important;
        }

        @media (min-height:730px) and (max-height: 740px) {
            height: 433px !important;
        }

        @media (min-height:800px) and (max-height:815px) {
            height: 510px !important;
        }

        .find-heading {
            padding-left: 10px;
            padding-top: 10px;

            .find-main-heading {
                font-size: 18px;
                font-weight: 500;
                color: #282828;
            }

            .find-description {
                font-size: 16px;
                font-weight: 500;
                color: #909090;
            }
        }
    }

    .search-receive-list {
        // width: 760px;
        height: 400px;
        background-color: #ffffff;
        margin-top: 20px;
        // box-shadow: 0px 2px 8px 0px #0000001F;
        overflow-y: scroll;

        @media (max-width:575px) {
            margin-top: -20px !important;
        }

        @media (max-width:360px) {
            height: 326px !important;
        }

        @media (min-width:530px) and (max-width:540px) {
            height: 700px !important;
        }

        @media (min-width:400px) and (max-width:415px) {
            height: 700px !important;
        }

        @media (min-width:420px) and (max-width:430px) {
            height: 720px !important;
        }

        @media (min-width:380px) and (max-width:400px) {
            height: 630px !important;
        }


        @media (min-height:660px) and (max-height:670px) {
            height: 455px !important;
        }

        @media (min-height:890px) and (max-height: 900px) {
            height: 690px !important;
        }

        @media (min-height:730px) and (max-height: 740px) {
            height: 525px !important;
        }

        @media (min-height:800px) and (max-height:815px) {
            height: 600px !important;
        }

        .find-heading {
            padding-left: 10px;
            padding-top: 10px;

            .find-main-heading {
                font-size: 18px;
                font-weight: 500;
                color: #000000;
            }

            .find-description {
                font-size: 16px;
                font-weight: 500;
                color: #909090;
            }
        }
    }

    .manager-card-without-result {
        height: 400px;
        background-color: #ffffff;
        margin-top: -20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dotted black;

        margin-bottom: 10vh;

        @media (min-width: 420px) and (max-width:430px) {
            margin-bottom: 30vh !important;
        }

        @media (min-width:389px) and (max-width:420px) {
            margin-bottom: 28vh !important;
        }

        @media (min-height:800px) and (max-height:815px) {
            margin-bottom: 20vh !important;
        }

        @media (min-height:700px) and (max-height:740px) {
            margin-bottom: 11vh !important;
        }

        .result-heading {
            font-size: 20px;
            font-weight: 400;
            display: flex;
            justify-content: center;
        }
    }
}

.find-people-listing-data {
    height: auto;
    background-color: #ffffff;
    padding: 20px 0px 36px 0;
    box-shadow: 0px 2px 8px 0px #0000001F;
    margin-top: 80px !important;

    .listing-data-heading {
        font-size: 20px;
        font-weight: 500;
        margin-top: 5px;
    }

    .phone-container {
        display: flex;
        justify-content: center;

        .phone-number {
            font-size: 16px;
            font-weight: 400;
            color: #707070;
            padding-left: 8px;
            display: flex;
            align-items: center;
        }

        .msg-box {
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #2975CA;
        }

        .msg-request-box {
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #D9EFFF;
            color: #0075CA;
        }

        .msg-request-box-receive {
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #0075CA;
            color: #ffffff;
        }
    }

    .msg-box {
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #2975CA;
    }

    .msg-request-box {
        width: 180px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #D9EFFF;
        color: #0075CA;
    }

    .msg-request-box-receive {
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #0075CA;
        color: #ffffff;
    }

    .email-conatiner {
        display: none !important;

        .phone-number {
            font-size: 16px;
            font-weight: 400;
            color: #707070;
            padding-left: 8px;
            display: flex;
            align-items: center;
        }

        .msg-box {
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #2975CA;
        }

        .msg-request-box {
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #D9EFFF;
            color: #0075CA;
        }

        .msg-request-box-receive {
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #0075CA;
            color: #ffffff;
        }
    }

}

.responsive-find-list {
    display: inline;
}

.responsive-find-main {
    display: none !important;
}

.find-people-search .search-find-list::-webkit-scrollbar,
.find-people-search .search-receive-list::-webkit-scrollbar,
.find-people-search .search-my-list::-webkit-scrollbar {
    width: 8px !important;
    z-index: 1 !important;
    display: inline-block !important;
}

.find-people-search .search-find-list::-webkit-scrollbar-track,
.find-people-search .search-receive-list::-webkit-scrollbar-track,
.find-people-search .search-my-list::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}

.find-people-search .search-find-list::-webkit-scrollbar-thumb,
.find-people-search .search-receive-list::-webkit-scrollbar-thumb,
.find-people-search .search-my-list::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
    border-radius: 3px !important;
}

.find-people-search .search-find-list::-webkit-scrollbar-thumb:hover,
.find-people-search .search-receive-list::-webkit-scrollbar-thumb:hover,
.find-people-search .search-my-list::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
}



@media (min-width:992px) and (max-width:1200px) {
    .find-people-listing-data {
        margin-top: 73px !important;
        margin-left: -20px !important;
    }

    // .find-people-listing-data .phone-container {
    //     display: none !important;
    // }

    // .find-people-listing-data .email-conatiner {
    //     display: flex !important;
    //     justify-content: center !important;
    // }

    .responsive-find-list {
        display: none !important;
    }

    .responsive-find-main {
        display: inline !important;
        margin-left: -10px;
        padding: 0px !important;
    }

    // .find-people-listing-data {
    //     height: 65vh !important;
    // }
}

@media (min-width:760px) and (max-width:1030px) {
    .find-people-search .search-find-list {
        height: 648px !important;
    }

    .find-people-search .search-my-list {
        height: 648px !important;
    }

    .find-people-search .search-receive-list {
        height: 790px !important;
        margin-top: -20px !important;

    }
}


@media (max-width:1024px) {
    .find-people-search .search-check .check-list {
        font-size: 13px !important;
    }
}

@media (max-width:820px) {
    .find-people-search .search-check .check-list {
        font-size: 15px !important;
    }

    // .find-people-tab {
    //     margin-left: 0px !important;
    // }
}

@media (max-width:690px) {
    .find-people-listing-data .phone-container {
        display: none !important;
    }

    .find-people-listing-data .email-conatiner {
        display: flex !important;
        justify-content: center !important;
    }

    .find-people-search .search-btn {
        width: 48px !important;
        padding-left: 15px !important;
    }
}

@media (max-width:440px) {
    .find-people-listing-data .email-conatiner .phone-number {
        font-size: 12px !important;
    }

    .find-people-search .search-check .check-list {
        font-size: 12px !important;
    }

    .find-people-search .search-btn {
        width: 48px !important;
        padding-left: 15px !important;
    }
}

//  @media (max-width:575px) and (min-width:360px) {
//     .find-people-tab{
//         .MuiTabs-root {
//             max-width: 360px !important;
//             // max-width: 93% !important;
//             min-height: 40px !important;
//         }
//         .MuiTab-wrapper{
//             font-size: 9px !important;
//             // width: 98% !important;
//         }
//         .MuiTab-root {
//             // min-width: 20% !important;
//             min-width: 90px !important;
//             width: 90px !important;
//         }


//     }

//  }

@media (max-width:575px) {
    .find-people-search {
        max-width: 575px !important;
        width: 97.6vw !important;
    }
}