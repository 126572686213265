.modal-open {
    overflow: hidden !important;
}

.landing-image {
    background-image: url("../../../assets/images/landingPage/landing-web-image.svg");
    background-repeat: no-repeat;
    width: 100vw !important;
    height: 774px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding-left: 60px;
    font-family: "Proxima_nova_reg";
    background-size: cover !important;
    padding-top: 230px !important;

    .search-home-contact {
        @media (min-width:760px) and (max-width:825px) {
            max-width: 85vw !important;
        }

        @media (min-width:1430px) and (max-width:1461px) {
            max-width: 89vw !important;
        }

        @media (min-width:1461px) and (max-width:1500px) {
            max-width: 87vw !important;
        }

        @media (min-width:1500px) and (max-width:1531px) {
            max-width: 85vw !important;
        }

        @media (min-width:1531px) and (max-width:1550px) {
            max-width: 83vw !important;
        }

        @media (min-width:1550px) and (max-width:1590px) {
            max-width: 82vw !important;
        }

        @media (min-width:1590px) and (max-width:1626px) {
            max-width: 80vw !important;
        }

        @media (min-width:1626px) and (max-width:1646px) {
            max-width: 78vw !important;
        }

        @media (min-width:1646px) and (max-width:1668px) {
            max-width: 78vw !important;
        }

        @media (min-width:1668px) and (max-width:1720px) {
            max-width: 76vw !important;
        }

        @media (min-width:1721px) {
            max-width: 70vw !important;
        }
    }

    @media (min-width:435px) and (max-width: 540px) {
        padding-top: 40px !important;
    }

    @media (min-width:426px) and (max-width: 435px) {
        padding-top: 40px !important;
    }

    @media (min-width:413px) and (max-width:426px) {
        padding-top: 35px !important;
    }

    @media (min-width:376px) and (max-width:413px) {
        padding-top: 29px !important;
    }

    @media (min-width:361px) and (max-width:376px) {
        padding-top: 54px !important;
    }

    .heading-buttons {
        margin-top: 36px !important;

        @media (min-width:1020px) and (max-width:1030px) {
            margin-top: 40px !important;
        }
    }


    .calender-icon {
        width: 22px;
    }


    .img-title {
        font-weight: bold;
        font-size: 25px;
        letter-spacing: 0.02em;
    }

    .img-title-bugget {
        font-weight: bold;
        font-size: 25px;
        letter-spacing: 0.02em;
    }

    .to-title {
        margin-left: 10px;
    }

    .seaction-bugget {
        display: flex;
        flex-wrap: wrap;

        .buget-value {
            display: flex;
        }
    }

    .my-bugget-large {
        display: flex;
        flex-wrap: wrap;

        @media (min-width:760px) and (max-width:1025px) {
            margin-top: 35px !important;
        }
    }

    .count .mobile-input {
        border: none;
        outline: none;
        color: #0075CA;
        background: transparent !important;
        font-size: 25px;
        font-weight: bold;
        border-bottom: 1px solid #0075CA;
        line-height: 0;
        vertical-align: bottom;
        -webkit-line-height: 0;
        -webkit-vertical-align: bottom;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0px !important;

        @media (min-width:760px) and (max-width:1025px) {
            padding-left: 10px !important;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .dashBetween {
        align-items: center;
        font-weight: 600;
        font-size: 25px;
        justify-content: center;
        margin: -5px 10px 0px 10px
    }

    // .buget-filed {
    //     border: none;
    //     outline: none;
    //     color: #0075CA;
    //     background: none !important;
    //     width: 100px;
    //     font-size: 25px;
    //     font-weight: bold;
    //     border-bottom: 1px solid #0075CA;
    //     line-height: 0;
    //     vertical-align: bottom;
    // }

    .buget-filed {
        outline: none !important;
        color: #0075CA !important;
        background: transparent !important;
        width: 100px;
        font-size: 25px;
        font-weight: bold;
        line-height: 0;
        vertical-align: bottom;
        -webkit-line-height: 0;
        -webkit-vertical-align: bottom;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none !important;
        border-bottom: 1px solid #0075CA !important;
        border-radius: 0 !important;
    }


    ::placeholder {
        color: #0075CA;
    }

    .home-decription {
        width: 380px;
        margin-top: 45px;

        .home-decription-value {
            font-size: 18px;
            font-weight: 600;
            color: #262626;
        }
    }


    .MuiFormLabel-root,
    .MuiInputLabel-root,
    .MuiInputLabel-formControl,
    .MuiInputLabel-animated,
    .MuiInputLabel-sizeMedium,
    .MuiInputLabel-standard,
    .MuiFormLabel-colorPrimary,
    .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 25px !important;
        color: #0075CA !important;
        font-family: "Proxima_nova_reg" !important;
        font-weight: 600 !important;
        padding-left: 0px !important;

    }

    .MuiInputBase-root,
    .MuiInput-root,
    .MuiInput-underline,
    .MuiInputBase-colorPrimary,
    .MuiInputBase-formControl,
    .css-1eed5fa-MuiInputBase-root-MuiInput-root {
        font-size: 23px !important;
        color: #0075CA !important;
        font-family: "Proxima_nova_reg" !important;
        font-weight: 600 !important;
    }

    .MuiSelect-select,
    .MuiSelect-standard,
    .MuiInputBase-input,
    .MuiInput-input,
    .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
        font-size: 25 px !important;
        color: #0075CA !important;
        font-family: "Proxima_nova_reg" !important;
        font-weight: 600 !important;
        padding-right: 5px !important;

        @media (min-width:760px) and (max-width:1025px) {
            padding-left: 10px !important;
        }

    }

    .short-drop-downs {

        .MuiSelect-select,
        .MuiSelect-standard,
        .MuiInputBase-input,
        .MuiInput-input,
        .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
            font-size: 25 px !important;
            color: #0075CA !important;
            font-family: "Proxima_nova_reg" !important;
            font-weight: 600 !important;
            padding-right: 5px !important;
            padding-bottom: 0px !important;
        }
    }

    .searchButton {
        width: 176px !important;
    }

    .mobile-property-type .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input {
        padding-bottom: 0px !important;
    }
}

::-webkit-scrollbar {
    display: none !important;
}

.recent-property,
.modal-recent-property {
    background-color: #D9EFFF;
    width: 100%;
    padding: 60px 150px 60px 150px;

    .recent-heading {
        font-size: 40px;
        font-weight: 500;

    }

    .slick-slide {
        float: auto !important;
        width: 361px;
    }

    .slick-prev {
        left: -70px !important;
        width: 60px !important;
        height: 60px !important;
        background-image: url('../../../assets//images/slider-arrow-pre.svg') !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .slick-next {
        right: -75px !important;
        padding-right: 15px !important;
        margin-right: 10px !important;
    }


    .slick-prev:before {
        color: transparent !important;
    }

    .slick-next:before {
        color: transparent !important;
    }

    .slick-next {
        width: 60px !important;
        height: 60px !important;
        background-image: url('../../../assets/images/slider-arrow-next.svg') !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }


    .slick-track {
        height: 471px !important;
    }

    .slick-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .property-card {
        width: auto !important;
        height: auto;
        background-color: #ffffff;
        margin-top: 10px;
        // padding: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right: 10px;
        margin-left: 25px;


        .property-card-image {
            width: 100%;
            height: 234px;
        }

        .property-price {
            font-size: 28px;
            font-weight: 500;
            color: #0075CA;
        }

        .property-day {
            display: flex;
            align-self: center;
            color: #505050;
            margin-top: 5px;
            font-size: 16px;
        }

        .count-heading {
            font-size: 16px;
            font-weight: 400;
            color: #707070;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .count-heading-location {
            font-size: 16px;
            font-weight: 400;
            color: #707070;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }

        .view-heading {
            font-size: 18px;
            font-weight: 500;
            color: #2F86D1;
            text-decoration: underline;
        }


    }
}

.custom-arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.prev-arrows {
    right: calc(100% - 39px);
}

.next-arrows {
    left: calc(100% - 60px);
}

@media (min-width:760px) and (max-width:1025px) {

    .recent-property,
    .modal-recent-property {
        padding: 30px 50px 20px 50px !important;
    }

    .recent-property .slick-prev {
        left: -45px !important;
        width: 50px !important;
        height: 50px !important;
    }
}

@media (max-width: 880px) {

    .recent-property .list-property-slider,
    .modal-recent-property .list-property-slider {
        justify-content: center !important;
    }

    .recent-property,
    .modal-recent-property {
        padding: 30px 50px 20px 50px !important;
    }

    .recent-property .slick-next {
        right: -52px !important;
    }

    .recent-property .slick-next {
        width: 50px !important;
        height: 50px !important;
    }

    .recent-property .slick-prev {
        left: -45px !important;
        width: 50px !important;
        height: 50px !important;
    }

    .recent-property .property-card,
    .modal-recent-property .property-card {
        margin-left: 0px !important;

        .property-price {
            font-size: 24px !important;
        }

        .count-heading {
            font-size: 14px !important;
        }

        .count-heading-location {
            font-size: 14px !important;
        }

        .view-heading {
            font-size: 14px !important;
        }
    }
}

@media (max-width:991px) {

    .recent-property .property-card,
    .modal-recent-property .property-card {
        margin-left: 0px !important;

        .property-price {
            font-size: 24px !important;
        }

        .count-heading {
            font-size: 14px !important;
        }

        .count-heading-location {
            font-size: 14px !important;
        }

        .view-heading {
            font-size: 14px !important;
        }
    }
}

@media (max-width:700px) {

    .recent-property .list-property-slider,
    .modal-recent-property .list-property-slider {
        display: flex !important;
        justify-content: center !important;
    }
}

.collection-container {
    width: 100%;
    min-height: 470px !important;
    padding: 30px 20px 40px 50px;
    position: relative;

    .recent-heading {
        font-size: 40px;
        font-weight: 500;
        padding-bottom: 20px !important;
    }

    .feature-image {
        width: 24%;
        height: 250px;
        margin-top: 15px;
        margin-right: 10px;
        display: inline-block;
        align-self: flex-end;
        padding-top: 170px;
        position: absolute;


        .short-heading {
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            justify-content: center;
        }

        .short-description {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            padding: 10px 15px 7px 20px;
        }
    }
}

.price-container {
    width: 100%;
    height: auto;
    background-image: url("../../../assets/images/landingPage/back-trans.svg");
    background-repeat: no-repeat;
    margin-top: 20px;

    .price-color {
        background-color: #0075CA;
        width: 100%;
        height: auto;
        opacity: 0.8;
        padding-top: 90px;
        padding-bottom: 90px;

        .price-heading {
            font-size: 30px;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            justify-content: center;
        }

        .price-description {
            font-size: 20px;
            font-weight: 400;
            color: #FFC105;
            display: flex;
            justify-content: center;
        }
    }
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters:hover {
    background-color: #D9EFFF !important;
}



.MuiSvgIcon-root.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    top: calc(50% - 1em) !important;
}

// .zipCode {

//     .geosuggest {
//         .geosuggest__suggests--hidden {
//             max-height: 0;
//             overflow: hidden;
//             border-width: 0;
//             display: none;
//         }

//         .geosuggest__input-wrapper {
//             height: 100%;
//             width: 100%;
//         }

//         .geosuggest__input {
//             white-space: nowrap;
//             border: none;
//             background: none;
//             border-bottom: 1px solid black;
//             color: #0075CA;
//             font-weight: 600;
//             font-size: 25px;
//             font-family: "Proxima_nova_reg";
//         }

//         .geosuggest__input:focus {
//             outline: none !important;
//         }

//         .geosuggest__input::placeholder {
//             color: #0075CA;
//             font-weight: 600;
//             font-size: 25px;
//             font-family: "Proxima_nova_reg";
//         }

//         .geosuggest__suggests-wrapper {
//             background-color: #ffffff;
//             width: 150px;
//             position: absolute;
//             z-index: 10 !important;

//             .geosuggest__item {
//                 padding: 5px 15px 5px 0px;
//                 font-size: 18px;
//                 list-style: none;
//                 cursor: pointer;
//             }

//             .geosuggest__item:hover,
//             .geosuggest__item:focus {
//                 background: #ffffff;
//             }
//         }
//     }
// }

.zipCode {

    .geosuggest {
        .geosuggest__suggests--hidden {
            max-height: 0 !important;
            overflow: hidden !important;
            border-width: 0;
            display: none;
            -webkit-max-height: 0;
            -webkit-overflow: hidden;
            -webkit-display: none;
        }

        .geosuggest__input-wrapper {
            height: 100% !important;
            width: 100% !important;
            -webkit-height: 100% !important;
            -webkit-width: 100% !important;
        }

        .geosuggest__input {
            // white-space: nowrap;
            // border: none;
            // background: none;
            // border-bottom: 1px solid black;
            // color: #0075CA;
            // font-weight: 600;
            // font-size: 25px;
            // font-family: "Proxima_nova_reg";
            // -webkit-white-space: nowrap;
            // -webkit-border: none;
            // -webkit-background: none;
            white-space: nowrap;
            background: transparent !important;
            border: none !important;
            border-bottom: 1px solid black !important;
            color: #0075CA !important;
            font-weight: 600;
            font-size: 25px;
            font-family: "Proxima_nova_reg";
            -webkit-white-space: nowrap;
            -webkit-border: none !important;
            -webkit-background: transparent !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0 !important;
        }

        .geosuggest__input:focus {
            outline: none !important;
        }

        .geosuggest__input::placeholder {
            color: #0075CA;
            font-weight: 600;
            font-size: 25px;
            font-family: "Proxima_nova_reg";
            -webkit-color: #0075CA;
        }

        .geosuggest__suggests-wrapper {
            background-color: #ffffff;
            width: auto !important;
            position: absolute !important;
            z-index: 10 !important;
            -webkit-background-color: #ffffff !important;
            -webkit-width: auto !important;
            max-width: 90vw !important;
            box-sizing: border-box;

            .geosuggest__item {
                padding: 5px 15px 5px 0px;
                margin-left: -30px !important;
                font-size: 18px;
                list-style: none;
                cursor: pointer;
                -webkit-font-size: 18px;
            }

            .geosuggest__item:hover,
            .geosuggest__item:focus {
                background: #ffffff !important;
                -webkit-background: #ffffff;
            }
        }
    }
}


// .state-code {

//     .geosuggest {
//         .geosuggest__suggests--hidden {
//             max-height: 0;
//             overflow: hidden;
//             border-width: 0;
//             display: none;
//         }

//         .geosuggest__input-wrapper {
//             height: 100%;
//             width: 100%;
//         }

//         .geosuggest__input {
//             white-space: nowrap;
//             border: none;
//             background: none;
//             border-bottom: 1px solid black;
//             color: #0075CA;
//             font-weight: 600;
//             font-size: 25px;
//             font-family: "Proxima_nova_reg";
//         }

//         .geosuggest__input:focus {
//             outline: none !important;
//         }

//         .geosuggest__input::placeholder {
//             color: #0075CA;
//             font-weight: 600;
//             font-size: 25px;
//             font-family: "Proxima_nova_reg";
//         }

//         .geosuggest__suggests-wrapper {
//             background-color: #ffffff;
//             width: 150px;
//             z-index: 10 !important;
//             position: absolute;

//             .geosuggest__item {
//                 font-size: 18px;
//                 list-style: none;
//                 cursor: pointer;
//             }

//             .geosuggest__item:hover,
//             .geosuggest__item:focus {
//                 background: #ffffff;
//             }
//         }
//     }
// }

.state-code {

    .geosuggest {
        .geosuggest__suggests--hidden {
            max-height: 0 !important;
            overflow: hidden !important;
            border-width: 0;
            display: none;
            -webkit-max-height: 0;
            -webkit-overflow: hidden;
            -webkit-display: none;
        }

        .geosuggest__input-wrapper {
            height: 100% !important;
            width: 100% !important;
            -webkit-height: 100% !important;
            -webkit-width: 100% !important;
        }

        .geosuggest__input {
            white-space: nowrap;
            background: transparent !important;
            border: none !important;
            border-bottom: 1px solid black !important;
            color: #0075CA;
            font-weight: 600;
            font-size: 25px;
            font-family: "Proxima_nova_reg";
            -webkit-white-space: nowrap;
            -webkit-border: none !important;
            -webkit-background: transparent !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0 !important;
        }

        .geosuggest__input:focus {
            outline: none !important;
        }

        .geosuggest__input::placeholder {
            color: #0075CA;
            font-weight: 600;
            font-size: 25px;
            font-family: "Proxima_nova_reg";
            -webkit-color: #0075CA;
        }

        // .geosuggest__suggests-wrapper {
        //     background-color: #ffffff;
        //     // width: 150px;
        //     z-index: 10 !important;
        //     position: absolute;
        //     -webkit-background-color: #ffffff;
        //     // -webkit-width: 150px;

        //     .geosuggest__item {
        //         padding: 5px 15px 5px 0px !important;
        //         font-size: 18px;
        //         list-style: none;
        //         cursor: pointer;
        //         -webkit-font-size: 18px;
        //     }

        //     .geosuggest__item:hover,
        //     .geosuggest__item:focus {
        //         background: #ffffff;
        //         -webkit-background: #ffffff;
        //     }
        // }

        .geosuggest__suggests-wrapper {
            background-color: #ffffff;
            width: 130px;
            position: absolute !important;
            z-index: 10 !important;
            -webkit-background-color: #ffffff !important;
            -webkit-width: 130px;
            max-width: 90vw !important;
            box-sizing: border-box;


            .geosuggest__item {
                padding: 5px 15px 5px 0px;
                margin-left: -30px !important;
                font-size: 18px;
                list-style: none;
                cursor: pointer;
                -webkit-font-size: 18px;
            }

            .geosuggest__item:hover,
            .geosuggest__item:focus {
                background: #ffffff !important;
                -webkit-background: #ffffff;
            }
        }
    }
}


.Disablecontine {
    border-radius: 4px;
    padding: 10px;
    color: white;
    background: #B4BDC4;
    border: none;
    font-size: 20px;
    width: 217px !important;
    height: 50px !important;
}


.continue_button {
    border-radius: 4px;
    padding: 10px;
    color: white;
    background: #0075CA;
    border: none;
    font-size: 20px;
    height: 50px !important;
}

.demo-simple-select-label {
    color: #0075CA;
}

.featureCollection {
    width: 100%;
    cursor: pointer;
}

.featureCollection:hover {
    transform: scale(1.1);
    padding: 8px;
}

@media (min-width:992px) and (max-width:1400px) {
    .collection-container {
        min-height: 332px !important;
    }
}

@media (min-width:1400px) and (max-width:1600px) {
    .collection-container {
        min-height: 400px !important;
    }
}


@media (min-width:1200px) and (max-width: 1500px) {
    .price-container .price-heading {
        font-size: 24px !important;
    }

    .price-container .price-description {
        font-size: 18px !important;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .landing-image {
        .MuiFormControl-root {
            width: 176px !important;
        }
    }

    .collection-container {
        padding: 30px 20px 40px 20px !important;
    }


}

@media (min-width:769px) and (max-width:1020px) {

    .recent-property .property-card,
    .modal-recent-property .property-card {
        margin-left: 35px !important;

        .count-heading {
            font-size: 16px !important;
        }

        .count-heading-location {
            font-size: 16px !important;
        }
    }
}

@media (min-width:900px) and (max-width: 1200px) {
    .price-container .price-heading {
        font-size: 20px !important;
    }

    .price-container .price-description {
        font-size: 16px !important;
    }
}

@media (min-width:766px) and (max-width:900px) {
    .price-container .price-heading {
        font-size: 18px !important;
    }

    .price-container .price-description {
        font-size: 14px !important;
    }
}

@media (min-width:769px) and (max-width:1024px) {

    .landing-image .to-title {
        margin-top: -5px !important;
    }
}

@media (max-width:768px) {
    .landing-image .img-title-bugget {
        margin-top: 14px !important;
    }

    .landing-image .to-title {
        margin-top: -5px !important;
    }


    .landing-image .calender {
        margin-left: 0px !important;
        margin-top: 0px !important;
    }

    .recent-property .property-card,
    .modal-recent-property .property-card {
        margin-left: 24px !important;
    }
}

@media (max-width:780px) {
    .landing-image {

        .Disablecontine {
            font-size: 14px !important;
            width: 135px !important;
            font-weight: 400 !important;
        }

        .continue_button {
            font-size: 14px !important;
            width: 160px !important;
            font-weight: 400 !important;
        }
    }

}

@media (max-width:575px) {
    .price-container .price-color {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .price-container .price-heading {
        font-size: 20px !important;
        padding-top: 15px !important;
    }

    .price-container .price-description {
        font-size: 16px !important;
    }

    .landing-image {
        display: flex !important;
        justify-content: center !important;

        .mobile-property-type {
            width: 110px;

            .MuiFormControl-root {
                width: 100px !important;
            }
        }

        height: 80vh !important;
        padding-left: 0px !important;

        // .buget-filed {
        //     width: 50px !important;
        //     text-overflow: ellipsis !important;
        //     font-size: 16px !important;
        // }

        .buget-filed {
            width: 80px !important;
            font-size: 16px !important;
            display: inline-block;
            /* Or 'block', depending on layout */
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .calender-icon {
            width: 30px !important;
        }

        .img-title {
            font-size: 16px !important;
        }

        .short-drop-downs {

            .MuiInputBase-root,
            .MuiInput-root,
            .MuiInput-underline,
            .MuiInputBase-colorPrimary,
            .MuiInputBase-formControl,
            .css-1eed5fa-MuiInputBase-root-MuiInput-root {
                font-size: 16px !important;

            }
        }

        .calender-start {
            width: 136px !important;
        }

        .calender-end {
            width: 136px !important;
        }

        .MuiInputBase-root,
        .MuiInput-root,
        .MuiInput-underline,
        .MuiInputBase-colorPrimary,
        .MuiInputBase-formControl,
        .css-1eed5fa-MuiInputBase-root-MuiInput-root {
            font-size: 16px !important;
            margin-top: 8px !important;


        }

        .MuiInputLabel-root {
            font-size: 16px !important;
        }

        .MuiFormControl-root.formControl {
            min-width: 118px;
        }

        .img-title-bugget {
            font-size: 16px !important;
            margin-left: 9px !important;
        }

        .dashBetween {
            font-size: 16px !important;
        }

        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            font-size: 16px !important;
            height: 20px !important;
            margin-top: -1px !important;
        }

        .searchButton {
            width: 174.5px !important
        }

        .count .mobile-input {
            width: 50px !important;
            font-size: 16px !important;
        }

        // .zipCode {
        //     .geosuggest .geosuggest__suggests-wrapper {
        //         width: 130px !important;
        //     }
        // }
        .zipCode {
            .geosuggest .geosuggest__suggests-wrapper {
                width: 130px !important;
                -webkit-width: 130px !important;
            }
        }

    }

    .continue_button {
        width: 135px !important;
    }

    .featureCollection {
        height: 238px !important;
    }

    .collection-container {
        padding: 20px 20px 10px 20px !important;
    }

    .state-code {
        .geosuggest .filledClass {
            width: 69px !important;
            -webkit-width: 69px !important;
        }

    }

    .geosuggest .geosuggest__input {
        font-size: 16px !important;

    }

    .recent-property .property-card,
    .modal-recent-property .property-card {
        margin-right: 0px !important;

        .view-heading {
            text-decoration: none !important;
        }
    }

    .stateField {
        font-size: 16px !important;
    }

    .geosuggest__input::placeholder {

        font-size: 16px !important;

    }


    // .zipCode .geosuggest .geosuggest__input {
    //     font-size: 16px !important;
    // }

    .zipCode .geosuggest .geosuggest__input {
        font-size: 16px !important;
        -webkit-font-size: 16px !important;
    }


    .recent-property,
    .modal-recent-property {
        .recent-heading {
            font-size: 20px !important;

        }
    }

    .collection-container .recent-heading {
        font-size: 20px !important;
        padding-bottom: 10px !important;
    }

    .mobile-container .mobile-section {
        .mobile-heading {
            font-size: 20px !important;
            margin-bottom: 10px !important;
        }

        .web-heading {
            font-size: 20px !important;
            padding-top: 15px !important;
        }
    }

    .about-section {

        .about-heading {
            font-size: 20px !important;
        }

        .about-us {
            font-size: 14px !important;

            .about-us-description {
                font-size: 14px !important;
            }
        }

    }

    .main_calender .react-datepicker__week {
        font-size: 16px !important;
    }

    .modal-container {
        .dateRange {
            font-size: 16px !important;
        }
    }

    .custom-header .date_month {
        padding: 5px 30px 5px 18px !important;
    }

    .modal-container .calender_header {
        padding: 20px 32px 0px 18px !important;
    }

    .recent-property,
    .modal-recent-property {
        .slick-list {
            margin: -2px !important;
        }

        .slick-next {
            right: -4px !important;
        }

        .slick-prev {
            left: -15px !important;

        }
    }

    .recent-property,
    .modal-recent-property {
        padding: 20px 0px 20px 20px !important;
    }

    .recent-property .property-card,
    .modal-recent-property .property-card {
        margin-left: 0px !important;
        width: 90vw !important;
    }

}

@media (min-width:390px) and (max-width:575px) {
    .landing-image {
        min-height: 60vh !important;
        height: auto !important;
        // padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
}

@media (max-width:374px) {
    .landing-image {
        height: 90vh !important;
    }
}

@media (min-width:1270px) and (max-width:1281px) {
    .landing-image {
        .to-title {
            margin-top: -7px !important;
        }

    }
}

@media (min-width:576px) and (max-width:1024px) {
    .landing-image {


        .to-title {
            padding-right: 10px !important;
        }
    }
}

@media (min-width:500px) and (max-width:540px) {
    .landing-image {
        .short-drop-down {
            margin-top: 13px !important;
        }
    }
}

@media (max-width:767px) {
    .landing-image {
        background-image: url("../../../assets/images/landing-page-mobile.svg");

        .mobile-property-type {
            .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input {
                text-overflow: clip !important;
            }
        }


    }

    .modal-arrow-right {
        margin-left: 15px !important;
        width: 20px !important;
    }

    .custom-back {
        width: 18px !important;
        height: 18px !important;
    }

    .recent-property {

        .slick-prev:hover,
        .slick-prev:focus,
        .slick-next:hover,
        .slick-next:focus {
            width: 50px !important;
            height: 50px !important;
            color: #0000;
            outline: none;
            background-color: unset !important;
        }

        .property-card .view-heading {
            font-size: 18px !important;
        }
    }

    .custom-arrows {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .prev-arrows {
        right: calc(100% - 39px);
    }

    .next-arrows {
        left: calc(100% - 60px);
    }

}

@media (min-width:768px) and (max-width:1024px) {
    .custom-arrows {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .next-arrows {
        left: calc(100% - 60px);
    }

}

@media (min-width:539px) and (max-width:575px) {
    .landing-image {
        padding-left: 32px !important;
        background-image: url("../../../assets/images/landing-image-540.svg");
        height: 553px !important;
        padding-bottom: 75px !important;
        padding-top: 103px !important;
        padding-left: 0px !important;
        align-items: baseline !important;
    }

    // .state-code .geosuggest .geosuggest__suggests-wrapper {
    //     right: 10px !important;
    //     -webkit-right: 10px !important;
    // }
}

@media (min-width:760px) and (max-width:800px) {
    .landing-image {
        padding-left: 32px !important;
        background-image: url("../../../assets/images/landing-image-768.svg");
        height: 738px !important;
        padding-bottom: 75px !important;
        padding-top: 103px !important;
    }

    .prev-arrows {
        right: calc(100% - 39px);
    }
}


@media (min-width:800px) and (max-width:900px) {
    .landing-image {
        padding-left: 32px !important;
        background-image: url("../../../assets/images/landing-image-820.svg");
        height: 788px !important;
        padding-top: 162px !important;
    }

    .prev-arrows {
        right: calc(100% - 50px);
    }
}

@media (min-width:900px) and (max-width:950px) {
    .landing-image {
        padding-left: 32px !important;
        background-image: url("../../../assets/images/landing-image-920.svg");
        height: 984px !important;
        padding-top: 205px !important;
    }

    .prev-arrows {
        right: calc(100% - 50px);
    }
}

@media (min-width:980px) and (max-width:1025px) {
    .landing-image {
        padding-left: 32px !important;
        background-image: url("../../../assets/images/laning-image-1024.svg");
        height: 984px !important;
        padding-top: 189px !important;
    }

    .prev-arrows {
        right: calc(100% - 39px);
    }
}

@media only screen and (width: 1024px) and (height: 600px) {
    .landing-image {
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            margin-top: 1px !important;
        }

        .short-drop-down {
            .MuiInputBase-root::after {
                margin-left: 10px !important;
            }
        }
    }
}

@media only screen and (width: 1026px) and (height: 1366px) {
    .landing-image {
        height: 50vh !important;
    }
}

@media (width:430px) {
    .landing-image {

        .in-text {
            padding-top: 15px !important;
            margin-top: 0px !important;
        }
    }

    .from-text {
        margin-top: 21px !important;
    }

}


// @media (width:414px) {
//     .state-code .geosuggest .geosuggest__suggests-wrapper {
//         right: 10px !important;
//         -webkit-right: 10px !important;
//     }

// }




@media (max-width:360px) {
    .landing-image {
        height: 534px !important;
        align-items: baseline !important;
        display: flex !important;
        justify-content: center !important;
        padding-top: 40px !important;
    }

}

@media (max-width:374px) {
    .landing-image .searchButton {
        width: 160px !important
    }
}



.landing-image {
    .inputField_calender_rent_start {
        width: 110px !important;
    }
}

.modal-recent-property {
    .slick-next {
        display: none !important;
    }

    .slick-prev {
        display: none !important;
    }
}

@media (width:768px) {
    .recent-property .recent-heading {
        margin-left: 10px !important;
    }
}

@media (min-width:768px) {
    .landing-image .calender-icon {
        width: 24px !important;
        height: 35px !important;
    }

}

@media (width:820px) {
    .landing-image {
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            margin-top: 1px !important;
        }
    }

    .recent-property .recent-heading {
        margin-left: 20px !important;
    }
}

@media (width:1024px) {
    .recent-property .recent-heading {
        margin-left: 10px !important;
    }
}

@media (width:1280px) {
    .recent-property .recent-heading {
        margin-left: 10px !important;
    }


}

@media (width:912px) {
    .recent-property .recent-heading {
        margin-left: 20px !important;
    }
}


@media (min-width:1025px) {
    .landing-image .MuiFormControl-root {
        width: 176px;
    }

}

@media (min-width:375px) and (max-width:539px) {
    .landing-image {
        min-height: 495px !important;
        height: auto;
        display: flex;
        align-items: baseline !important;
    }

    // .state-code .geosuggest .geosuggest__suggests-wrapper {
    //     right: 10px !important;
    //     -webkit-right: 10px !important;
    // }

}