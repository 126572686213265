.search-home-overflow {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width:575px) {
        height: auto !important;
        margin-top: 80px !important;
    }
}

.search-all-list {
    background-color: #F2F2F7 !important;
    box-shadow: 0px 3px 12px 0px #0000001F;
    margin: 20px !important;

    @media (max-width:575px) {
        margin: 20px 10px 20px 10px !important;
        overflow-x: hidden !important;
    }
}

.search-section {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width:575px) {
        height: auto !important;
        margin-top: 60px !important;
    }

}

.search-conatiner {
    background-color: white !important;
    width: 100% !important;
    margin-bottom: 25px !important;
    padding: 15px;
    height: auto;
    font-family: 'Proxima_nova_medium' !important;

    @media (max-width:575px) {
        padding: 0px !important;
    }

    .MuiMobileStepper-root {
        // position: absolute;
        display: flex;
        align-items: center;
        background-color: transparent;
        color: #ffff !important;
        margin-top: -124px !important;
        margin-left: -20px !important;
        margin-right: -23px !important;

        @media (min-width:431px) and (max-width:575px) {
            margin-top: -190px !important;
        }
    }

    .MuiPaper-root {
        background-color: transparent !important;
        color: #ffff !important;
    }

    .MuiButtonBase-root {
        display: inline-block;
        justify-content: inherit !important;
    }

    .rent-box {
        padding-top: 24% !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 20px;
            font-weight: 600;
            padding: 10px 6px 5px 10px;
            font-family: "Proxima Nova";
        }

        .rent-heading {
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 20px;
            font-weight: 600;
            padding: 8px 0 5px 10px;
            font-family: "Proxima Nova";
        }
    }

    .deposit-type {
        display: none !important;
    }

    .mobile-type {
        display: none !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }


    }

    .detail-conatiner {
        // margin-left: -17px;
        margin-top: 5px;

        @media (max-width:575px) {
            padding: 0 10px 0 10px !important;
        }

        .user-heading {
            font-size: 18px !important;
            font-weight: 600 !important;
            font-family: "Proxima Nova";
        }

        .user-dot {
            font-size: 20px;
            font-weight: 800;
            margin: -6px 10px 0 10px;
        }

        .property-owner {
            color: #707070;
            font-size: 18px;
            font-weight: 500;
        }

        .categroy-heading {
            color: #707070;
            font-size: 14px;
            font-weight: 500;
            margin-left: 8px;
            margin-top: 2px;
        }

        .category-value {
            font-size: 16px;
            font-weight: 600;
            margin-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .select-value-heading {
            font-size: 16px;
            font-weight: 600;
            margin-top: 10px;
        }

        .view-heading {
            font-size: 14px;
            font-weight: 600;
            color: #2F86D1;
            text-decoration: underline;
        }

        .view-heading-small {
            font-size: 18px;
            font-weight: 600;
            color: #2F86D1;
            text-decoration: underline;
        }

        // .map-show {
        //     width: 150px;
        //     height: 40px;
        //     border-radius: 4px;
        //     color: #ffffff;
        //     background-color: #0075CA;
        //     display: flex !important;
        //     justify-content: center !important;
        //     align-items: center !important;
        //     cursor: pointer;
        // }
    }

}

.map-show {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    color: #ffffff;
    background-color: #0075CA;
    // display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer;
    position: fixed;
    bottom: 20px;

    @media (max-width:575px) {
        position: fixed !important;
    }

}

// .searchhome {
//     font-family: 'Proxima_nova_medium' !important;
//     background: #f1f1f1 !important;

//     ::-webkit-scrollbar {
//         width: 13px !important;
//     }

//     ::-webkit-scrollbar-track {
//         background: #f1f1f1 !important;
//         box-shadow: inset 0 0 5px grey;
//     }

//     ::-webkit-scrollbar-thumb {
//         background: #888 !important;
//         border-radius: 3px;
//     }

//     ::-webkit-scrollbar-thumb:hover {
//         background: #555 !important;
//     }
// }

.empty-property-home {
    width: 100%;
    height: 450px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
    font-family: Proxima_nova_reg;
    border: 1px dashed black;

    @media (max-width:575px) {
        margin-top: 20px !important;
    }
}

.search-fav-icon {
    position: absolute !important;
    top: 1%;
    left: 7px;
    // z-index: 1;

    .mobile-fav {
        width: 30px;
        height: 30px;
        cursor: pointer;
        color: red !important;
    }
}


.featurePropertyIcon {
    background: rgb(255, 184, 0);
    width: 154px;
    position: absolute;
    top: -15px;
    right: 0px;
    display: flex;
    align-items: flex-start;
    padding-top: 5px;
    height: 26px;
    gap: 5px;
    padding-left: 5px;
    border-radius: 0 10px 0 0;

    @media (max-width:575px) {
        top: 0 !important;
        border-radius: 0 0px 0 0 !important;
        // z-index: 1 !important;
    }

    .featurePropertyText {
        color: white;
        font-size: 12px;
        font-weight: 600;
    }
}

.map-large-section {

    .map-container {
        width: 100%;
        height: 100%;

        .location-tooltip {
            position: absolute;
            bottom: 10px;
            left: 10px;
            background-color: white;
            padding: 5px;
            border: 1px solid black;
            border-radius: 3px;
            z-index: 100;
        }
    }

}

@media (max-width:1199px) {
    .map-large-section {
        display: none !important;
    }
}

// @media (min-width: 768px) {
//     .map-container {
//         height: 600px;
//     }
// }

// @media (min-width: 1024px) {
//     .map-container {
//         height: 800px;
//     }
// }

@media (min-width:1523px) and (max-width:1647px) {
    .search-conatiner .rent-box {
        padding-top: 30% !important;
    }
}

@media (min-width:1200px) and (max-width:1523px) {
    .search-conatiner .rent-box {
        padding-top: 34% !important;
    }

}


@media (min-width:1024px) and (max-width:1311px) {
    .search-conatiner .rent-box {
        padding-top: 35% !important;
    }
}

@media (min-width:991px) and (max-width:1024px) {
    .search-conatiner .rent-box {
        padding-top: 28% !important;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .search-home-overflow {
        height: 55vh !important;
    }
}

@media (min-width:769px) and (max-width:991px) {
    .search-conatiner .rent-box {
        padding-top: 38% !important;
    }

}

@media (max-width:768px) {
    // .search-conatiner .rent-box {
    //     padding-top: 38% !important;
    // }

    .search-home-overflow {
        height: 60vh !important;
    }

    .search-conatiner .rent-box {
        display: none !important;
    }

    .search-conatiner .deposit-type {
        // padding-top: 15px !important;
        padding-top: 40% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }

}

@media (min-width:808px) and (max-width:913px) {
    .search-conatiner .rent-box {
        display: none !important;
    }

    // .search-conatiner .detail-conatiner .view-heading {
    //     display: none !important;
    // }

    .search-section .search-conatiner .mobile-type {
        display: none !important;
    }

    .search-conatiner .deposit-type {
        // padding-top: 15px !important;
        padding-top: 37% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }
}

@media (min-width:575px) and (max-width:768px) {
    .search-conatiner .MuiMobileStepper-root {
        margin-top: -100px !important;
    }

    .search-conatiner .deposit-type {
        // padding-top: 15px !important;
        padding-top: 30% !important;
    }

    // .search-conatiner .detail-conatiner .view-heading {
    //     display: none !important;
    // }

    .search-section .search-conatiner .mobile-type {
        display: none !important;
    }
}

@media (min-width:430px) and (max-width:575px) {
    .search-conatiner .deposit-type {
        // padding-top: 15px !important;
        display: none !important;
    }


    .search-conatiner .detail-conatiner .section-profile {
        margin-top: 155px !important;
    }

    .search-section .search-conatiner .mobile-type {
        margin-top: 10px !important;
        display: flex !important;
    }
}

@media (max-width:430px) {

    .search-section {
        .search-conatiner .deposit-type {
            // padding-top: 15px !important;
            display: none !important;
        }

        .search-conatiner .detail-conatiner .section-profile {
            margin-top: 85px !important;
        }

    }

    .search-section .search-conatiner .mobile-type {
        margin-top: 10px !important;
        display: flex !important;
    }
}