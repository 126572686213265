.amenitie-container {
    // width: 900px !important;
    margin-top: 130px;
    text-align: center;

    .amenitie-heading {
        color: #2F86D1;
        font-size: 34px;
        font-weight: 550;
        font-family: "Proxima Nova";
        display: flex;
        justify-content: center;
    }

    .amenite-description {
        // width: 620px;
        text-align: center;
        // padding-left: 250px;

        .amenitie-description-value {
            font-size: 20px;
            font-family: "Proxima Nova";
        }
    }

}

.amenitie-section {
    display: flex;
    // justify-content: center;
    flex-wrap: wrap;

    .amenitie-box {
        margin-top: 50px;
        margin-left: 15px;
        display: inline-block;
        cursor: pointer;

        .amenitie-select {
            width: auto;
            min-width: 220px;
            height: 70px;
            display: flex;
            align-items: center;

            @media (min-width:760px) and (max-width:775px) {
                min-width: 200px !important;
            }

            @media (min-width:900px) and (max-width:915px) {
                min-width: 189px !important;
            }

            .amenitie-select-heading {
                font-size: 20px;
                font-family: "Proxima Nova";
                padding-left: 10px;
                padding-right: 15px;
            }
        }
    }

}


.otheramenities {
    margin-top: 20px;
    margin-left: 100px;

    .MuiFormLabel-root {
        font-size: 20px;
        color: #505050;
        font-family: "Proxima Nova";

    }
}

@media (min-width:1200px) {
    .amenitie-section {
        justify-content: center !important;
    }
}

@media (min-width:1600px) and (max-width:1670px) {
    .otheramenities {
        margin-left: 128px !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .amenitie-container {
        margin: 0 50px 30px 50px !important;

    }

    .amenitie-section {
        margin-left: 50px !important;
        margin-right: 20px !important;

        .amenitie-box {
            margin: 0 0px 30px 15px !important;
        }
    }

    .otheramenities {
        margin: -15px 20px 20px 50px !important;
    }
}

@media (min-width:770px) and (max-width:1199px) {
    .amenitie-container {
        margin-top: 0 !important;

    }

    .amenitie-section {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .otheramenities {
        margin-left: 30px !important;
    }
}

@media (max-width:767px) {
    .amenitie-container {
        margin-top: 10px !important;
        margin-bottom: 20px !important;

    }

    .amenitie-container {

        .amenitie-heading {
            font-size: 16px !important;
            justify-content: start;
            margin-left: 10px;
        }

        .amenitie-description-value {
            font-size: 12px !important;
            text-align: start;
            margin-left: 10px !important;
        }

    }

    .amenitie-image {
        width: 32px !important;
        height: 32px !important;
        padding-left: 5px !important;
    }

}

@media (min-width:540px) and (max-width:767px) {
    .amenitie-section {
        justify-content: start !important;


        .amenitie-box {
            margin: 0 0px 10px 10px !important;
            width: 159px !important;
            height: 56px;
            display: flex;
            align-items: center;

            .amenitie-select {

                .amenitie-select-heading {
                    font-size: 14px !important;
                }
            }

        }
    }

    .otheramenities {
        margin-left: 20px !important;
        margin-top: -15px !important;

        .MuiFormLabel-root {
            font-size: 16px !important;
            color: #505050 !important;
        }
    }
}

@media (min-width:420px) and (max-width:430px) {
    .amenitie-section {
        justify-content: start !important;


        .amenitie-box {
            margin: 0 0px 10px 10px !important;
            width: 188px !important;
            height: 56px;
            display: flex;
            align-items: center;

            .amenitie-select {

                .amenitie-select-heading {
                    font-size: 14px !important;
                }
            }

        }
    }

    .otheramenities {
        margin-left: 20px !important;
        margin-top: -15px !important;

        .MuiFormLabel-root {
            font-size: 16px !important;
            color: #505050 !important;
        }
    }
}

@media (min-width:391px) and (max-width:420px) {
    .amenitie-section {
        justify-content: start !important;


        .amenitie-box {
            margin: 0 0px 10px 10px !important;
            width: 180px !important;
            height: 56px;
            display: flex;
            align-items: center;

            .amenitie-select {

                .amenitie-select-heading {
                    font-size: 14px !important;
                }
            }

        }
    }

    .otheramenities {
        margin-left: 20px !important;
        margin-top: -15px !important;

        .MuiFormLabel-root {
            font-size: 16px !important;
            color: #505050 !important;
        }
    }
}

@media (min-width:381px) and (max-width:391px) {
    .amenitie-section {
        justify-content: start !important;


        .amenitie-box {
            margin: 0 0px 10px 10px !important;
            width: 169px !important;
            height: 56px;
            display: flex;
            align-items: center;

            .amenitie-select {

                .amenitie-select-heading {
                    font-size: 14px !important;
                }
            }

        }
    }

    .otheramenities {
        margin-left: 20px !important;
        margin-top: -15px !important;

        .MuiFormLabel-root {
            font-size: 16px !important;
            color: #505050 !important;
        }
    }
}

@media (min-width:361px) and (max-width:380px) {
    .amenitie-section {
        justify-content: start !important;


        .amenitie-box {
            margin: 0 0px 10px 10px !important;
            width: 162px !important;
            height: 56px;
            display: flex;
            align-items: center;

            .amenitie-select {

                .amenitie-select-heading {
                    font-size: 14px !important;
                }
            }

        }
    }

    .otheramenities {
        margin-left: 20px !important;
        margin-top: -15px !important;

        .MuiFormLabel-root {
            font-size: 16px !important;
            color: #505050 !important;
        }
    }
}


@media (max-width:360px) {
    .amenitie-section {
        justify-content: start !important;


        .amenitie-box {
            margin: 0 0px 10px 10px !important;
            width: 155px !important;
            height: 56px;
            display: flex;
            align-items: center;

            .amenitie-select {

                .amenitie-select-heading {
                    font-size: 14px !important;
                }
            }

        }
    }

    .otheramenities {
        margin-left: 20px !important;
        margin-top: -15px !important;

        .MuiFormLabel-root {
            font-size: 16px !important;
            color: #505050 !important;
        }
    }
}

.amenitie-image {
    width: 48px;
    height: 48px;
    padding-left: 10px;
}

@media (min-width:760px) and (max-width:770px) {
    .saveButton-amite {
        margin-right: 15px !important;
    }

    .backButton-amite {
        margin-left: -18px !important;
    }

}

@media (min-width:770px) and (max-width:1030px) {
    .saveButton-amite {
        margin-right: 27px !important;
    }

    .backButton-amite {
        margin-left: 0px !important;
    }
}

@media (min-width:1100px) {
    .saveButton-amite {
        margin-right: 50px !important;
    }
}

@media (max-width:575px) {
    .saveExit-mobile-amite {
        margin-left: 7px !important;
    }

    .saveButton-mobile-amite {
        margin-right: 7px !important;
    }
}