.chat-container {
    margin-top: 20px;

    @media (max-width:575px) {
        margin-top: 0px !important;
    }

    // width: 430px;

    .search-btn {
        background-color: #2F86D1;
        cursor: pointer;
        display: flex;
        align-items: center;
        // margin: 5px 4px 5px 0;
        height: 48px;
        width: 140px;
        padding-left: 18px;
        border-radius: 4px;
    }


    .chat-seaction {
        // width: 432px;
        height: 460px;
        background-color: #ffffff;
        margin-top: 15px;
        overflow-y: scroll;
        overflow-x: hidden;
        // padding: 16px 0 16px 24px;

        @media (max-width:575px) {
            margin-bottom: 25px !important;
        }

        @media (max-width:375px) {
            margin-bottom: 10px !important;
        }

        .users-heading {
            // padding-left: 16px;
            // padding-top: 10px;

            .user-main-heading {
                font-size: 18px;
                font-weight: 500;
                color: #000000;

                @media (max-width:575px) {
                    font-size: 16px !important;
                }
            }

            .user-heading {
                font-size: 14px;
                font-weight: 500;
                color: #909090;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media (max-width:575px) {
                    font-weight: 400 !important;
                }
            }
        }

        .request-profile {
            background-color: #2F86D1;
            color: #ffffff;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            margin-top: 16px;
            margin-right: 15px;
            cursor: pointer;
        }
    }
}

// .chat-seaction::-webkit-scrollbar {
//     width: 8px !important;
//     z-index: 1 !important;
//     display: inline-block !important;
// }

// .chat-seaction::-webkit-scrollbar-track {
//     background-color: #f5f5f5 !important;
// }

// .chat-seaction::-webkit-scrollbar-thumb {
//     background-color: #909090 !important;
//     border-radius: 3px !important;
// }

// .chat-seaction::-webkit-scrollbar-thumb:hover {
//     background-color: #909090 !important;
// }

.manager-chat-without-result {
    width: 430px;
    height: 400px;
    background-color: #ffffff;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dotted black;

    @media (max-width:575px) {
        width: 100% !important;
    }

    .result-heading {
        font-size: 20px;
        font-weight: 400;
        display: flex;
        justify-content: center;
    }
}

.chat-heading {
    margin-left: 30px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Proxima_nova_reg';
}

.chat-section-mobile {
    background-color: #ffffff;
    padding: 15px;
    box-shadow: 0px 2px 4px 0px #0000001F;

    @media (min-width:575px) and (max-width:1024px) {
        margin-top: 20px !important;
    }

    .listing-name-mobile {
        font-size: 20px;
        font-weight: 600;
        font-family: 'Proxima_nova_medium' !important;
        padding-left: 12px;
        display: flex;
        align-items: center;
    }
}

.chat-contact-main {
    display: flex;
    flex-direction: column;
    // width: 850px !important;
    height: 700px;
    background-color: #ffffff;
    overflow-y: scroll;

    @media (min-width:431px) and (max-width:575px) {
        height: 520px !important;
    }

    @media (min-width:414px) and (max-width:430px) {
        height: 746px !important;
    }

    @media (min-width:412px) and (max-width:414px) {
        height: 710px !important;
    }

    @media (min-width:391px) and (max-width:412px) {
        height: 729px !important;
    }

    @media (width:414px) and (height:736px) {
        height: 549px !important;
    }

    // @media (max-height:643px) {
    //     height: 4400px !important;
    // }

    @media (min-width:376px) and (max-width:390px) {
        height: 660px !important;
    }

    @media (min-height:600px) and (max-height:668px) {
        height: 480px !important;
    }

    @media (min-height:800px) and (max-height:813px) {
        height: 630px !important;
    }

    @media (max-width:360px) {
        height: 555px !important;
    }

    .select-des-value {
        font-size: 20px;
        font-weight: 400;
    }

    .chat-detail-container {
        // width: 840px;
        padding: 15px;
        // height: 873px;
        overflow-y: scroll;
        margin-top: 10px;
        height: 100%;

        @media (max-width:575px) {
            margin-top: 0px !important;
        }

        .chat-contact-left {
            width: 324px;
            height: auto;
            background-color: #D9EFFF;
            padding: 10px;
            margin-top: 8px;
            margin-left: 32px;
            position: relative;
            font-family: 'Proxima_nova_medium' !important;

            @media (max-width:575px) {
                width: 262px !important;
                margin-left: 10px !important;
            }

            .listing-user-heading {
                font-size: 23px;
                font-weight: 600;
                color: #2F86D1;

                @media (max-width:575px) {
                    font-size: 16px !important;
                }
            }

            .listing-user-month {
                display: flex;
                align-self: center;
                color: #2F86D1;
                margin-top: 5px;
                font-size: 16px;
                font-weight: 600;

                @media (max-width:575px) {
                    margin-top: 0px !important;
                }
            }

            .chat-description {
                font-size: 16px;
                margin-top: 4px;

                @media (max-width:575px) {
                    font-size: 14px !important;
                }
            }

            .chat-bed-heading {
                font-size: 18px;
                font-weight: 500;
                color: #505050;
                padding-left: 7px;

                @media (max-width:575px) {
                    font-size: 14px !important;
                    padding-top: 5px !important;
                }

            }

            .chat-sqare {
                font-size: 18px;
                font-weight: 500;

                @media (max-width:575px) {
                    font-size: 14px !important;
                }
            }
        }

        .chat-contact-left-2 {
            width: 324px;
            height: auto;
            background-color: #D9EFFF;
            margin-top: 8px;
            margin-left: 32px;
            padding: 3px 7px 3px 15px;
            position: relative;
            font-family: 'Proxima_nova_medium' !important;

            @media (max-width:575px) {
                width: 262px !important;
                margin-left: 10px !important;
            }

            .chat-contact-heading {
                font-size: 18px;
                font-weight: 600;
                color: #303030;
                font-family: 'Proxima_nova_medium' !important;

                @media (max-width:575px) {
                    font-size: 14px !important;
                }
            }
        }

        .chat-contact-right {
            width: 180px;
            height: auto;
            background-color: #0075CA;
            color: #ffffff;
            padding: 10px;
            border-radius: 5px;
            margin-top: 10px;
            margin-right: 32px;
            position: relative;

            @media (max-width:575px) {
                width: 121px !important;
                margin-right: 10px !important;
                margin-top: 20px !important;
            }

            .chat-contact-right-heading {
                font-size: 20px;
                font-weight: 600;

                @media (max-width:575px) {
                    font-size: 14px !important;
                }
            }

        }

        .MuiFilledInput-root.Mui-focused {
            outline: none !important;
            border: none !important;
        }

        .MuiFilledInput-root {
            background-color: #F2F2F7 !important;
        }
    }

    .sent-box {
        width: 100%;
        padding: 8px 24px 12px 24px;
        margin-top: 20px;

        @media (max-width:912px) {
            padding: 8px 12px 12px 12px !important;
            box-shadow: 0px -1px 6px 0px #0000001F;
        }

        .MuiFilledInput-input {
            padding: 10px 12px 10px !important;
        }
    }
}


.notch {
    width: 0;
    height: 0;
    border-top: 20px solid #D9EFFF;
    border-right: 20px solid transparent;
    transform: rotate(90deg);
    position: absolute;
    left: -11px;
    top: 0;
    z-index: 0;
}

.notch-right {
    width: 0;
    height: 0;
    border-top: 14px solid #0075CA;
    border-right: 14px solid transparent;
    position: absolute;
    top: 0;
    right: -11px;
    z-index: 0;
}

@media (min-width:1026px) and (max-width:1660px) {
    .chat-container .chat-seaction .users-heading {
        padding-left: 16px !important;
    }
}

@media (min-width:770px) and (max-width:1024px) {
    .chat-container .chat-seaction .users-heading {
        padding-left: 20px !important;
    }
}

@media (min-width:575px) and (max-width:770px) {
    .chat-container .chat-seaction .users-heading {
        padding-left: 16px !important;
    }
}

@media (max-width:575px) {
    .chat-container .chat-seaction .users-heading {
        padding-left: 20px !important;
    }
}