.find-people-list-container {
    .manager-heading {
        font-size: 18px;
        font-weight: 500;
        margin-top: 15px;
        margin-left: 10px;
    }

    .add-detail-manager-seaction {
        height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;

        .manager-card-detail {
            padding: 10px;

            .MuiBox-root {
                padding: 10px !important;
            }

            .MuiMobileStepper-root {
                display: flex;
                align-items: center;
                background-color: transparent;
                color: #ffff !important;
                margin-top: -130px !important;
                margin-left: -20px !important;
                margin-right: -23px !important;

                @media (min-width:431px) and (max-width:575px) {
                    margin-top: -190px !important;
                }

                @media (min-width:575px) and (max-width:1024px) {
                    margin-top: -100px !important;
                }

            }

            .MuiPaper-root {
                background-color: transparent !important;
                color: #ffff !important;
            }

            .MuiButtonBase-root {
                display: inline-block;
                justify-content: inherit !important;
            }

            .rent-box {
                padding-top: 35% !important;
                display: flex;
                justify-content: center;

                .rental-heading {
                    padding-top: 70px;
                    color: #707070;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: "Proxima_nova_medium";
                    // display: flex;
                    // justify-content: center;
                }

                .rental-price {
                    font-size: 20px;
                    font-weight: 600;
                    // padding: 10px 0px 5px 10px;
                    padding-top: 10px;
                    font-family: "Proxima_nova_medium";
                    text-wrap: nowrap;
                }

                .rent-heading {
                    display: flex;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                }

                .deposit-price {
                    font-size: 20px;
                    font-weight: 600;
                    padding: 8px 0 5px 10px;
                    font-family: "Proxima Nova";
                    display: flex;
                    justify-content: center;
                }
            }

            .deposit-type {
                display: none !important;

                .rental-heading {
                    padding-top: 70px;
                    color: #707070;
                    font-size: 16px;
                    font-family: "Proxima Nova";
                    display: flex;
                    justify-content: center;
                }

                .rental-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 10px 6px 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }

                .rent-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                }

                .deposit-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 8px 0 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }
            }

            .mobile-type {
                display: none !important;

                .rental-heading {
                    padding-top: 70px;
                    color: #707070;
                    font-size: 16px;
                    font-family: "Proxima Nova";
                    display: flex;
                    justify-content: center;
                }

                .rental-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 10px 6px 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }

                .rent-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                }

                .deposit-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 8px 0 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }
            }

            .listing-card-detail {

                .listing-user-heading {
                    font-size: 23px;
                    font-weight: 600;
                    color: #2F86D1;
                }

                .listing-user-month {
                    display: flex;
                    align-self: center;
                    color: #2F86D1;
                    margin-top: 5px;
                    font-size: 16px;
                    font-weight: 600;
                }

                .request-profile {
                    background-color: #2F86D1;
                    color: #ffffff;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 600;
                    padding-right: 3px;
                    padding-top: 3px;
                    cursor: pointer;
                }

                .categroy-heading-type {
                    color: #707070;
                    font-size: 12px;
                    font-weight: 400;
                    margin-left: 8px;
                    font-family: "Proxima_nova_medium";
                    display: flex;
                    align-items: center;
                    // white-space: nowrap;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                }

                .categroy-heading {
                    color: #707070;
                    font-size: 14px;
                    font-weight: 400;
                    margin-left: 8px;
                    font-family: "Proxima_nova_medium";
                    // display: flex;
                    // align-items: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top: 3px;
                }

                .category-value {
                    font-size: 16px;
                    font-weight: 600;
                    margin-left: 8px;
                    color: #303030;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // display: flex;
                    // align-items: center;
                }

                .select-value-heading {
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-family: "Proxima_nova_medium";
                }

                .request-genarte {
                    font-size: 18px;
                    font-weight: 600;
                    margin-right: 20px;
                    color: #2F86D1;
                    margin-top: 10px;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .user-heading {
                    font-size: 18px !important;
                    font-weight: 600 !important;
                    // font-family: "Proxima Nova";
                    font-family: 'Proxima Nova Condensed', sans-serif;
                }

                .user-dot {
                    width: 4px;
                    height: 4px;
                    // font-size: 20px;
                    // font-weight: 800;
                    // margin: -4px 6px 0 6px;
                }

                .property-owner {
                    color: #707070;
                    font-size: 12px;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    font-family: 'Proxima Nova Condensed', sans-serif;
                }


                .custom-margin {
                    margin-top: 17px !important;
                }

                .ipaid-detail {
                    display: none !important;

                    @media (min-width:766px) and (max-width:915px) {
                        display: flex !important;
                        justify-content: end !important;
                    }
                }

                .full-screen-detail {
                    display: flex !important;
                    align-items: center !important;

                    @media (min-width:766px) and (max-width:915px) {
                        display: none !important;
                    }
                }

                .view-heading {
                    font-size: 18px;
                    font-weight: 600;
                    color: #2F86D1;
                    text-decoration: underline;
                    font-family: 'Proxima Nova Condensed', sans-serif;

                    @media (min-width:766px) and (max-width:915px) {
                        font-size: 16px !important;
                    }
                }

            }
        }

        .empty-manager-list {
            font-family: "Proxima_nova_reg";
            width: 840px;
            height: 345px;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .add-manager-empty {
        height: 250px;
        background-color: #ffffff;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dotted black;

        .result-heading {
            font-size: 20px;
            font-weight: 400;
            display: flex;
            justify-content: center;
        }
    }
}

.find-people-list-container .add-detail-manager-seaction::-webkit-scrollbar {
    width: 8px !important;
    z-index: 1 !important;
    display: inline-block !important;
}

.find-people-list-container .add-detail-manager-seaction::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}

.find-people-list-container .add-detail-manager-seaction::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
    border-radius: 3px !important;
}

.find-people-list-container .add-detail-manager-seaction::-webkit-scrollbar-thumb:hover {
    background-color: #909090 !important;
}

@media (min-width:1200px) and (max-width:1630px) {
    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .rent-box {
        padding-top: 40% !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .mobile-type {
        display: none !important;
    }
}

@media (max-width:1399px) {
    .find-people-list-container {
        margin-top: 20px !important;
    }
}

@media (max-width:992px) {
    .find-people-listing-data {
        margin-top: 70px !important;
        margin-left: -19px !important;
    }

    .find-people-list-container {
        margin-left: -19px !important;
    }
}

@media (max-width:768px) {
    // .find-people-list-container .add-detail-manager-seaction .manager-card-detail .rent-box {
    //     // padding-top: 58% !important;
    //     // padding: 0 30px 0 30px !important;
    // }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .rent-box {
        display: none !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .rent-box .rental-price {
        font-size: 14px !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .rent-box .deposit-price {
        font-size: 16px !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .rent-box .rent-type {
        margin-top: 7px !important;
    }
}

@media (min-width:768px) and (max-width:808px) {
    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .rent-box {
        display: none !important;
    }

    // .find-people-list-container .add-detail-manager-seaction .manager-card-detail .listing-card-detail .view-heading {
    //     display: none !important;
    // }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .mobile-type {
        display: none !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .mobile-type {
        display: none !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .deposit-type {
        // padding-top: 15px !important;
        padding-top: 40% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }

    .listing-manager-fav .listing-card-detail .categroy-heading {
        font-size: 13px !important;
    }
}

@media (min-width:1024px) and (max-width:1199px) {
    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .MuiMobileStepper-root {
        margin-top: -100px !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .rent-box {
        padding-top: 20% !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .mobile-type {
        display: none !important;
    }
}

@media (max-width:768px) {
    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .deposit-type {
        // padding-top: 15px !important;
        padding-top: 30% !important;


    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail {
        // box-shadow: 0px 4px 4px 0px #0000001F !important;
        padding: 0px !important;
    }

    // .find-people-list-container .add-detail-manager-seaction .manager-card-detail .MuiMobileStepper-root {
    //     margin-top: -100px !important;
    // }

    .mobile-fav-pos {
        position: relative;

        .mobile-favIcon {
            position: absolute;
            top: 5%;
            right: 20px;
            // z-index: 1;

            .mobile-fav {
                width: 30px;
                height: 30px;
                cursor: pointer;
            }
        }
    }

    // .MuiButton-label{
    //     color: black !important;
    // }
}

@media (min-width:430px) and (max-width:575px) {
    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .listing-card-detail .section-profile {
        margin-top: 145px !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .listing-card-detail .property-owner {
        display: none !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .listing-card-detail .user-dot {
        display: none !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .mobile-type {
        display: flex !important;
        // justify-content: space-between !important;
        margin-top: 10px !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .listing-card-detail .custom-margin {
        margin-top: 0px !important;
    }
}

@media (max-width:430px) {
    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .listing-card-detail .section-profile {
        margin-top: 80px !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .listing-card-detail .property-owner {
        display: none !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .listing-card-detail .user-dot {
        display: none !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .mobile-type {
        display: flex !important;
        // justify-content: space-between !important;
        margin-top: 10px !important;
    }

    .find-people-list-container .add-detail-manager-seaction .manager-card-detail .listing-card-detail .custom-margin {
        margin-top: 0px !important;
    }
}

@media (max-width:575px) {
    .find-people-list-container {
        margin-left: -19px !important;
        margin-right: -19px !important;

        .listing-card-detail {
            padding: 10px !important;
        }

        .manager-heading {
            margin-left: 0px !important;
        }
    }

    .find-people-listing-data {
        margin-top: 65px !important;
        margin-left: -19px !important;
        margin-right: -19px !important;
        padding-bottom: 10px !important;
    }
}

@media (width:375px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 410px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:414px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 640px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:390px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 600px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:430px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 685px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:393px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 560px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:412px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 660px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:360px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 490px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:540px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 465px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:344px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 657px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:411px) and (height:731px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 505px !important;
        margin-bottom: 25px !important;
    }
}

@media (width:411px) and (height:823px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 593px !important;
    }
}

@media (width:375px) and (height:812px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 560px !important;
    }
}

@media (width:320px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 225px !important;
    }
}

@media (width:414px) and (height:736px) {

    .find-people-list-container .add-detail-manager-seaction,
    .find-people-list-container .add-manager-empty {
        height: 485px !important;
    }
}