.review-section-container {
    margin-top: -50px;
    width: 100% !important;
    text-align: center;

    .review-heading {
        font-size: 40px;
        font-weight: 600;
        color: #0075CA;
    }

    .review-description {
        font-size: 20px;
        font-weight: 400;
    }

    .review-section {
        width: 950px !important;
        background-color: #F2F2F7;
        border-radius: 5px;
        margin-top: 12px !important;
        padding: 25px;
        margin: auto;

        .review-value-item {
            background-color: #ffff;
            height: auto;
            padding: 15px;

            .MuiMobileStepper-root {
                // position: absolute;
                display: flex;
                align-items: center;
                background-color: transparent;
                color: #ffff !important;
                margin-top: -120px !important;
                margin-left: -20px !important;
                margin-right: -23px !important;
            }

            .MuiPaper-root {
                background-color: transparent !important;
                color: #ffff !important;
            }

            .MuiButtonBase-root {
                display: inline-block;
                justify-content: inherit !important;
            }

            .seach-heading {
                padding-top: 80px;
                color: #707070;
                font-size: 14px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 22px;
                font-weight: 600;
                padding: 10px 6px 5px 2px;
                font-family: "Proxima Nova";
            }

            .rent-heading {
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 25px;
                font-weight: 600;
                padding: 8px 0 5px 10px;
                font-family: "Proxima Nova";
            }

            .detail-conatiner {
                margin-left: -17px;
                margin-top: -10px;

                .user-heading {
                    font-size: 18px !important;
                    font-weight: 600 !important;
                    font-family: "Proxima Nova";
                }

                .user-dot {
                    font-size: 20px;
                    font-weight: 800;
                    margin: -6px 10px 0 10px;
                }

                .property-owner {
                    color: #707070;
                    font-size: 18px;
                    font-weight: 500;
                }

                .categroy-heading {
                    color: #707070;
                    font-size: 16px;
                    font-weight: 500;
                    margin-left: 8px;
                }

                .category-value {
                    font-size: 18px;
                    font-weight: 600;
                    margin-left: 10px;
                }

                .select-value-heading {
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 10px;
                    text-align: start;
                }
            }

        }

        .edit-property {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            text-decoration: underline;
            font-size: 20px;
            font-weight: 600;
            color: #2F86D1;
            font-family: "Proxima Nova";
            cursor: pointer;
        }
    }

    .update-container {
        width: 920px !important;
        margin-top: 10px !important;
        margin: auto;

        .update-heading {
            font-size: 26px;
            font-weight: 600;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .update-des {
            width: 730px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 160px;
        }

        .update-des-value {
            font-size: 20px;
            font-family: "Proxima Nova";
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #707070;
        }

        .update-check {
            // width: 730px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-top: 10px;

            .check-list {
                font-size: 20px;
                font-weight: 600;
                font-family: "Proxima Nova";
                color: #2F86D1;
            }

        }

    }

    .changes-des {
        width: 920px !important;
        margin-top: 10px !important;
        margin: auto;

        .changes-des-value {
            font-size: 20px;
            font-weight: 500;
            color: #707070;
        }
    }
}