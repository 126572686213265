.side-desktop {
    .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
        color: #909090;
    }

    .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
        font-weight: 400 !important;
    }

    .side-navigation-panel {
        font-size: 20px !important;
    }


    .side-navigation-panel-select {
        margin-top: 15px !important;
    }

    .side-navigation-panel-select-option,
    .side-navigation-panel-select-option-selected {
        border-left: 2px solid transparent !important;
        height: 73px;

        svg {
            height: 24px;
            width: 26px;
        }
    }

    [class*="option-selected"] {
        border-left: 4px solid #2975CA !important;
        color: #2975CA !important;
        font-weight: 500 !important;
        background: #D9EFFF !important;
        width: 100% !important;
    }

    .side-navigation-panel-select-inner-option-text {
        font-size: 20px !important;
        font-family: 'Poppins', sans-serif;
        height: 32px;
        display: flex;
        align-items: center;
        color: gray !important;
    }

    .side-navigation-panel-select-inner-option {
        height: 73px !important;
    }

    .side-navigation-panel-select-inner-option:hover {
        border-left: 2px solid #19C1D6 !important;
    }

    .side-navigation-panel-select-inner-option {
        border-left: 2px solid transparent;
    }

    .Sidebar_control::-webkit-scrollbar {
        display: none !important;
    }

    .sidebar_business {
        display: none !important;
    }


}

@media (min-width: 1311px) and (max-width:1617px) {
    .side-navigation-panel {
        font-size: 16px !important;
    }

    .side-navigation-panel-select-option,
    .side-navigation-panel-select-option-selected {

        svg {
            height: 20px;
            width: 20px;
        }
    }
}

@media (min-width: 1114px) and (max-width: 1311px) {

    // .side-navigation-panel {
    //     font-size: 14px !important;
    // }

    .side-desktop {
        .side-navigation-panel-select-option-text {
            display: none !important;
        }

        .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
            display: flex !important;
            justify-content: center !important;
        }

        .side-navigation-panel-select-option,
        .side-navigation-panel-select-option-selected {

            svg {
                height: 20px;
                width: 20px;
            }
        }

    }
}

@media (min-width: 992px) and (max-width: 1113px) {

    // .side-navigation-panel {
    //     font-size: 12px !important;
    // }

    .side-desktop {
        .side-navigation-panel-select-option-text {
            display: none !important;
        }

        .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
            display: flex !important;
            justify-content: center !important;
        }

        .side-navigation-panel-select-option,
        .side-navigation-panel-select-option-selected {

            svg {
                height: 20px;
                width: 20px;
            }
        }

    }
}

@media (min-width:768px) and (max-width:992px) {
    .side-desktop {
        .side-navigation-panel-select-option-text {
            display: none !important;
        }

        .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
            display: flex !important;
            justify-content: center !important;
        }

        .side-navigation-panel-select-option,
        .side-navigation-panel-select-option-selected {

            svg {
                height: 22px;
                width: 22px;
            }
        }

    }
}

@media (max-width: 768px) {
    .side-desktop {
        .side-navigation-panel-select-option-text {
            display: none !important;
        }

        .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
            display: flex !important;
            justify-content: center !important;
        }

        .side-navigation-panel-select-option,
        .side-navigation-panel-select-option-selected {

            svg {
                height: 20px;
                width: 24px;
                padding-right: 3px !important;
            }
        }

    }
}

@media (max-width:428px) {
    .side-desktop {
        .side-navigation-panel-select-option-text {
            display: none !important;
        }


        .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
            display: flex !important;
            justify-content: center !important;
        }

        .side-navigation-panel-select-option,
        .side-navigation-panel-select-option-selected {

            svg {
                height: 16px;
                width: 20px;
                padding-right: 3px !important;
            }
        }

    }
}

@media (max-width:576px) {
    .side-desktop {
        .navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
            padding: 0px !important;
        }

        .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
            padding: 0px !important;
        }

        [class*="option-selected"] {
            border-left: none !important;
        }

    }
}