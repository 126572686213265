.arrow-box {
  background-color: #ffffff;
  width: 60px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width:765px) and (max-width:915px) {
    margin-bottom: 15px !important;
  }
}

.bedroom-detail {
  border-bottom: 1px solid rgb(183, 183, 183);
  border-right: 1px solid rgb(183, 183, 183);
}

.home-detail {
  border-bottom: 1px solid rgb(183, 183, 183);
}

.chair-detail {
  border-right: 1px solid rgb(183, 183, 183);
}

.arrow-back-responsive {
  display: none !important;
}

.carousel-container {
  position: relative;
  margin-top: 10px;
}

.custom-arrow {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}


.prev-arrow {
  right: calc(100% - 36px);
}

.next-arrow {
  left: calc(100% - 50px);
}

.carousel-item {
  margin: 0 1px;
}

.slick-slider {
  padding-top: 15px;
}

// .slick-track{
//   width: 120vw !important;
// }

.detail-conatiner-view-detail {
  background-color: #ffffff;
  padding: 15px;

  .detals-values {
    padding: 15px 0px 15px 0px;
    // border-top: 1px solid black;
  }

  .detals-box {
    padding: 15px 0px 15px 0px;
    // border-top: 1px solid black;
  }

  .user-heading {
    font-size: 14px;
    font-weight: 600;
    font-family: "Proxima_nova_medium";
  }

  .user-dot {
    width: 4px;
    height: 4px;
  }

  .property-owner {
    font-size: 12px;
    font-weight: 400;
    color: #707070;
    font-family: "Proxima_nova_medium";
  }

  .calender {
    display: flex;
    align-items: center;

    .calender-text {
      font-size: 16px;

    }
  }

  .AcceptButton {
    background: linear-gradient(rgb(64, 160, 229) 0%, rgb(19, 107, 171) 100%);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
  }

  .RejectButton {
    border: 2px solid rgb(47, 134, 209);
    border-radius: 4px;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    background: white;
    color: rgba(0, 117, 202, 1);
    width: 100%;
  }

  .icon {
    padding-right: 10px;
  }

  .amount_seacrh {
    font-size: 12px;
    color: #505050;
    line-height: 14.52px;
    margin-bottom: 0;
  }

  .price {
    font-size: 32px;
    font-weight: 600;
  }

  .locationAddress {
    display: flex;
    align-items: flex-start;
    gap: 5px;

    .address {
      color: #505050;
      font-size: 16px;
    }
  }

  .property {
    font-size: 20px;
    font-weight: 700;
  }

  .housedetails {
    text-align: center;
    padding-top: 15px;
  }

  .propertydetails {
    display: flex;
    justify-content: center;
    gap: 5px;
    font-size: 15px;
    color: #505050;
    align-items: baseline;
  }

  .details {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .conditions {
    color: #505050;
    margin: 0px
  }

  .amenities {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    border: none;
    padding: 5px;
    background: #F5F5F5;
    margin-top: 15px;
  }

  .amenitie-box {
    margin: 0;
    padding: 0px;
    display: inline-block;

    .amenitie-select {
      width: auto;
      height: 70px;
      display: flex;
      align-items: center;
      background: #F5F5F5;
      margin-top: 10px;
      margin-right: 10px;
      padding-left: 20px;
      padding-right: 20px;

      .amenitie-select-heading {
        font-size: 20px;
        font-family: Proxima_nova_medium;
        padding-left: 10px;
        color: #505050;
      }
    }
  }
}

.property-details {
  padding-top: 15px;
}


.modal-container {
  .calender_header {
    display: flex;
    justify-content: space-between;
    // align-items: baseline;
    padding: 20px 32px 0px 32px;
  }
}

.custom-header {
  .monthyear {
    font-size: 24px;
    font-family: 'Proxima_nova_reg';
    // text-align: left;


  }

  .date_month {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 32px 20px 32px;

    @media (min-width:766px) and (max-width:915px) {
      padding: 20px 22px 20px 22px;
    }
  }

  .MuiFormControl-root {
    padding-bottom: 5px;
  }

}

.react-datepicker__month-container {
  float: none !important;
}

.inputField_calender-finial{
  display: flex;
  width: 50%;
  padding-left: 32px;

  .MuiInputBase-input {
    font-weight: 600;
    font-family: 'Proxima_nova_reg';
  }
}

.inputField_calender {
  display: flex;
  width: 50%;
  padding-left: 32px;

  .MuiInputBase-input {
    font-weight: 600;
    font-family: 'Proxima_nova_reg';
  }
}


.react-datepicker__day-names {
  display: 'none'
}

.main_calender {

  .react-datepicker__header {
    background-color: #ffffff !important;
    border-bottom: none !important;
    width: 100% !important;
    margin: auto;
    padding: 0px !important;
  }

  .react-datepicker {
    border: none;
    width: 100% !important;
  }

  .react-datepicker__week {
    font-size: 20px;
    justify-content: space-around !important;
    display: flex;
    font-family: 'Proxima_nova_reg';
    // padding-bottom: 20px
    padding: 0 0px 20px 0px;
  }

  .react-datepicker__day--selected:hover {

    background: #0075CA;
    border-radius: 50%;
    // padding: 2px;
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    padding: 2px;
    background-color: #E5FFE5 !important;
    border-radius: 50px !important;
    color: #00BC29 !important;
  }

  .react-datepicker__day--selected {
    background-color: #E5FFE5 !important;
    border-radius: 50px !important;
    color: #00BC29 !important;
  }


  .range-selected {
    background-color: #E5FFE5;
    border-radius: 50px;
    color: #00BC29;
  }

  .start-date {
    background-color: #E5FFE5;
    border-radius: 50px;
    color: #00BC29 !important;
  }

  .end-date {
    background-color: #E5FFE5;
    border-radius: 50px;
    color: #00BC29 !important;
  }

}

.dropdown_calender {


  .react-datepicker__day--selected {
    background: #0075CA !important;
    border-radius: 50% !important;
    padding: 4px !important;
  }
}

.footer-section-modal {
  display: flex;
  justify-content: space-between;
  padding: 20px 32px 20px 32px;
  border: 1px solid #EAEAEA;
  align-items: baseline;

  @media (min-width:766px) and (max-width:915px) {
    padding: 20px 22px 20px 22px;
  }

  @media (max-width:575px) {
    padding: 20px 10px 20px 10px;
  }
}

.calender_footer {

  display: flex;
  align-items: center;
  gap: 5px;

  .footer_para {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Proxima_nova_reg';
    display: flex !important;
    align-items: center !important;
    margin-top: 15px;
  }

}

.searchdetail {
  .continue_button {
    width: 217px !important;
  }
}

.continue_button {
  border-radius: 4px;
  padding: 10px;
  color: white;
  background: #40A0E5;
  border: none;
  font-size: 20px;
  height: 50px !important;
}

.Disablecontine {
  border-radius: 4px;
  padding: 10px;
  color: white;
  background: #B4BDC4;
  border: none;
  font-size: 20px;
  width: 217px;
  height: 50px;
}

.continue-button-home {
  border-radius: 4px;
  padding: 10px;
  color: white;
  background: #40A0E5;
  border: none;
  font-size: 20px;
  height: 50px !important;
}

.disablecontine-button-home {
  border-radius: 4px;
  padding: 10px;
  color: white;
  background: #B4BDC4;
  border: none;
  font-size: 20px;
  width: 217px;
  height: 50px;
}

.main_calender {

  .react-datepicker__day-names {
    display: none !important;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden !important;
  }
}

.carousel-item.selected img {
  border: 4px solid #0075CA;
  padding: 5px;
  width: 95% !important;
  outline: none;
}

.carousel-item.selected {
  outline: none;
}

.mainImage {
  width: 40vw;
  height: 459px;
}

.select-image-slider {
  width: 170px;
  height: 90px;
}

@media (min-width:1541px) and (max-width:1725px) {
  .carousel-item.selected img {
    width: 90% !important;
  }

  .select-image-slider {
    width: 150px !important;
    height: 70px !important;
  }
}

@media (min-width:1329px) and (max-width:1540px) {
  .carousel-item.selected img {
    width: 90% !important;
  }

  .select-image-slider {
    width: 140px !important;
    height: 70px !important;
  }
}

@media (min-width:769px) and (max-width:1024px) {

  .select-image-slider {
    width: 90px !important;
    height: 90px !important;
  }

  .custom-arrow {
    top: 60% !important;
  }

  .prev-arrow {
    right: calc(100% - 24px);
  }

  .next-arrow {
    left: calc(100% - 50px);
  }
}

@media (max-width:1157px) {
  .detail-conatiner-view-detail .price {
    font-size: 24px !important;
  }
}

@media (max-width:768px) {

  .prev-arrow {
    right: calc(100% - 98px);
  }

  .next-arrow {
    left: calc(100% - 95px);
  }

  .mainImage {
    width: 100% !important;
    // position: relative;
    // margin-left: 10px !important;
  }

  .arrow-back-responsive {
    background-color: #ffffff !important;
    width: 60px !important;
    height: 35px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    position: absolute !important;
    margin: 20px 0 0 32px !important;
    z-index: 1;
  }

  .select-image-slider {
    width: 162px !important;
    height: 101px !important;
    margin-left: 10px !important;
  }

  .detail-conatiner-view-detail {
    margin-top: 20px !important;
    padding: 20px !important;

    .user-heading {
      font-size: 16px !important;
      font-weight: 600 !important;
    }

    .property-owner {
      font-size: 14px !important;
    }
  }

  .view-detail-section .slick-slider {
    padding: 15px 32px 0px 32px !important;
  }

  .searchdetail {
    .continue_button {
      font-size: 14px !important;
      width: 160px !important;
      font-weight: 400 !important;
    }
  }

}

@media (min-width:768px) and (max-width:972px) {
  .mainImage {
    width: 95vw;
    margin-top: 20px !important;
    margin-left: 10px !important;
  }

  .inputField_calender {
    padding-left: 10px !important;

    .MuiInputBase-input {
      font-size: 14px !important;
    }
  }

  .inputField_calender-finial{
    padding-left: 10px !important;

    .MuiInputBase-input {
      font-size: 14px !important;
    }
  }

  // .slick-track {
  //   width: 230vw !important;
  // }

  // .slick-slide {
  //   width: 180px !important;
  // }

  // .slick-slide img {
  //   padding-left: 10px !important;
  // }
}

// @media (max-width:880px) {
//   .custom-header .date_month {
//     padding: 20px 32px 20px 10px !important;
//   }
// }


// @media (max-width:767px) {
//   .searchdetail {
//     .detail-conatiner-view-detail {
//       margin-top: 0px !important;
//       padding: 0px 10px 20px 10px !important;

//       .price {
//         font-size: 20px !important;
//       }

//       .property {
//         font-size: 16px !important;
//       }

//       .details {
//         font-size: 16px;
//       }

//       .detals-values {
//         align-items: center;
//       }
//     }

//     .calender {
//       margin-left: -4px;

//       .calender-img {
//         width: 20px !important;
//       }

//       .calender-text {
//         font-size: 14px !important;
//         padding-top: 1px !important;
//       }
//     }

//     .conditions {
//       font-size: 14px;
//     }

//     .amenitie-box .amenitie-select {
//       width: auto;
//       height: 32px;
//       padding: 8px 12px 8px 12px;

//       .amenitie-select-heading {
//         font-size: 16px;
//         font-weight: 400;
//       }
//     }

//     .rentProperty-button {
//       margin-left: -4px;
//     }

//     .property {
//       margin-bottom: 0px;
//     }

//     .property-details {
//       padding-top: 0px;
//     }

//     .continue_button {
//       font-size: 14px !important;
//       width: 180px !important;
//       font-weight: 400 !important;
//     }

//     .modal-container .calender_header {
//       padding-bottom: 10px !important;
//     }

//     .react-datepicker__week {
//       font-size: 12px !important;
//     }


//   }


//   .modal-container .calender_header {
//     padding-bottom: 10px !important;
//   }

//   .react-datepicker__week {
//     font-size: 12px !important;
//   }

//   .mobile-detail-slider {
//     height: 240px !important;

//     .slick-slider {
//       padding: 0px !important;
//     }
//   }

//   .prev-arrow-mobile {
//     right: calc(100% - 55px);
//     border-radius: 50%;
//     box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
//     /* width: 24px; */
//     /* height: 24px; */
//     padding: 5px 15px 5px 15px;
//   }

//   .next-arrow-mobile {
//     left: calc(100% - 55px);
//     border-radius: 50%;
//     box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
//     /* width: 24px; */
//     /* height: 24px; */
//     padding: 5px 15px 5px 15px;
//   }

//   .custom-arrow-mobile {
//     position: absolute;
//     top: 30%;
//     transform: translateY(-50%);
//     cursor: pointer;
//     z-index: 1;
//   }

//   .main_calender {
//     .react-datepicker__week {
//       font-size: 13px !important;
//       padding: 0 20px 20px 15px !important;
//     }

//     .main_calender {
//       .react-datepicker__week {
//         font-size: 13px !important;
//         padding: 0 20px 20px 15px !important;
//       }
//     }

//     .custom-header .date_month {
//       align-items: baseline;
//     }

//     .inputField_calender {
//       display: none !important;
//     }

//     .inputField_calender_rent {
//       padding-left: 10px !important;
//       width: 150px !important;
//       text-overflow: ellipsis !important;
//     }

//     .bedroom-detail {
//       border-bottom: none;
//       border-right: none;

//     }

//     .home-detail {
//       border-bottom: none;
//     }

//     .chair-detail {
//       border-right: none;
//     }

//     .background-mobile {
//       background-color: #F5F5F5;
//       height: 66px;
//       padding: 10px;
//     }

//     .custom-header {
//       .forward-calender {
//         width: 18px !important;
//         height: 18px !important;
//       }
//     }
//   }

// }


@media (max-width:767px) {
  .calender-mobile-detail-rent {
    padding: 5px 30px 5px 0px !important;
  }

  .searchdetail {
    .detail-conatiner-view-detail {
      margin-top: 0px !important;
      padding: 0px 10px 20px 10px !important;

      .price {
        font-size: 20px !important;
      }

      .property {
        font-size: 16px !important;
      }

      .details {
        font-size: 16px;
      }

      .detals-values {
        align-items: center;
      }
    }

    .calender {
      margin-left: -4px;

      .calender-img {
        width: 20px !important;
      }

      .calender-text {
        font-size: 14px !important;
        padding-top: 1px !important;
      }
    }

    .conditions {
      font-size: 14px;
    }

    .amenitie-box .amenitie-select {
      width: auto !important;
      height: 32px;
      padding: 5px 12px 5px 12px;

      .amenitie-select-heading {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .rentProperty-button {
      margin-left: -4px;
    }

    .property {
      margin-bottom: 0px;
    }

    .property-details {
      padding-top: 0px;
    }

    .continue_button {
      font-size: 14px !important;
      width: 180px !important;
      font-weight: 400 !important;
    }

    .Disablecontine {
      font-size: 14px !important;
      width: 135px !important;
      font-weight: 400 !important;
    }

    .modal-container .calender_header {
      padding-bottom: 10px !important;
    }

    .react-datepicker__week {
      font-size: 12px !important;
    }

    .mobile-detail-slider {
      height: 240px !important;

      .slick-slider {
        padding: 0px !important;
      }
    }

    .prev-arrow-mobile {
      right: calc(100% - 55px);
      border-radius: 50%;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
      /* width: 24px; */
      /* height: 24px; */
      padding: 5px 15px 5px 15px;
    }

    .next-arrow-mobile {
      left: calc(100% - 55px);
      border-radius: 50%;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
      /* width: 24px; */
      /* height: 24px; */
      padding: 5px 15px 5px 15px;
    }

    .custom-arrow-mobile {
      position: absolute;
      top: 30%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
    }

  }

  .main_calender {
    .react-datepicker__week {
      font-size: 16px !important;
      padding: 0 0px 10px 0 !important;
    }
  }

  .custom-header .date_month {
    align-items: baseline;
  }

  .inputField_calender {
    display: none !important;
  }

  .inputField_calender-finial{
    padding-left: 10px !important;
    text-overflow: ellipsis !important;
  }

  .inputField_calender_rent {
    padding-left: 10px !important;
    width: 120px !important;
    text-overflow: ellipsis !important;
  }

  .bedroom-detail {
    border-bottom: none;
    border-right: none;

  }

  .home-detail {
    border-bottom: none;
  }

  .chair-detail {
    border-right: none;
  }

  .background-mobile {
    background-color: #F5F5F5;
    height: 66px;
    padding: 10px;
  }

  .custom-header {
    .forward-calender {
      width: 18px !important;
      height: 18px !important;
    }
  }
}

@media (max-width:912px) and (min-width:820px) {

  .select-image-slider {
    width: 175px !important;
    height: 101px !important;
  }

  .view-detail-section .slick-slider {
    padding: 15px 32px 0px 32px !important;
  }

  .next-arrow {
    left: calc(100% - 95px) !important;
  }

  .prev-arrow {
    right: calc(100% - 98px) !important;
  }
}

.mobile-detail-slider {
  .property-card {
    margin-left: 0px !important;
    width: 100vw !important;
  }

  .slick-slide {
    float: auto !important;
    // width: 370px !important;
  }

  .slick-prev {
    left: -70px !important;
    width: 60px !important;
    height: 60px !important;
    background-image: url('../../../assets//images/slider-arrow-pre.svg') !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }

  .slick-next {
    right: -75px !important;
    padding-right: 15px !important;
    margin-right: 10px !important;
  }


  .slick-prev:before {
    color: transparent !important;
  }

  .slick-next:before {
    color: transparent !important;
  }

  .slick-next {
    width: 60px !important;
    height: 60px !important;
    background-image: url('../../../assets/images/slider-arrow-next.svg') !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }


  .slick-track {
    height: 471px !important;
    width: 11847px !important;
    // transform: translate3d(-724px, 0px, 0px) !important;
  }

  .slick-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}


@media (max-width: 880px) {
  .mobile-detail-slider .list-property-slider {
    // display: flex !important;
    justify-content: center !important;
  }

  .mobile-detail-slider {
    padding: 0px !important;
  }

  .mobile-detail-slider .slick-next {
    right: -52px !important;
  }

  .mobile-detail-slider .slick-next {
    width: 50px !important;
    height: 50px !important;
  }

  .mobile-detail-slider .slick-prev {
    left: -45px !important;
    width: 50px !important;
    height: 50px !important;
  }


}

@media (max-width:700px) {
  .arrow-box {
    display: none !important;
  }

  .mobile-detail-slider .list-property-slider {
    display: flex !important;
    justify-content: center !important;
  }
}

.detail-continue-button {
  width: 180px !important;
  font-size: 16px !important;
}

.detail-disable-button {
  width: 180px !important;
  font-size: 16px !important;
}

@media (max-width:360px) {
  .calender_footer .footer_para {
    font-size: 15px !important;
  }

  .detail-continue-button {
    font-size: 15px !important;
    width: 200px !important;
  }
}