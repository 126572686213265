.side-resposne {
    width: 24%;
    height: 100vh;
    overflow: scroll;
}

.side-mobile-responsive {
    display: none !important;
}

.side-value-component {
    width: 50% !important;
    height: 100vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.side-value-my-list {
    width: 50% !important;
    height: 100vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.side-value-list {
    width: 76% !important;
    height: 100vh !important;
    // overflow: scroll !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.side-value-list-mobile {
    width: 50% !important;
    height: 100vh !important;
    overflow: scroll !important;
}

.side-value-pay {
    width: 70% !important;
    height: 100vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

@media (min-width:1311px) and (max-width:1448px) {
    .side-resposne {
        width: 28% !important;
        margin-left: 0 !important;
    }

    .side-value-component {
        width: 60% !important;
    }

    .side-value-my-list {
        width: 60% !important;
    }

    .side-value-list {
        width: 85% !important;
    }

    .side-value-list-mobile {
        width: 60% !important;
    }
}

@media (min-width:992px) and (max-width:1311px) {
    .side-resposne {
        width: 13% !important;
        margin-left: 0 !important;
    }

    .side-value-component {
        width: 80% !important;
    }

    .side-value-my-list {
        width: 80% !important;
    }

    .side-value-list {
        width: 85% !important;
    }

    .side-value-list-mobile {
        width: 80% !important;
    }
}

@media (max-width:1199px) {
    .side-value-list {
        width: 80% !important;
    }
}

@media (min-width:768px) and (max-width:992px) {
    .side-resposne {
        width: 18% !important;
    }

    .side-value-component {
        width: 80% !important;
    }

    .side-value-my-list {
        width: 80% !important;
    }

    .side-value-list-mobile {
        width: 80% !important;
    }
}

@media (min-width:576px) and (max-width:768px) {
    .side-resposne {
        width: 19% !important;
    }

    .side-value-component {
        width: 80% !important;
    }

    .side-value-my-list {
        width: 80% !important;
    }

    .side-value-list {
        width: 100% !important;
    }

    .side-value-list-mobile {
        width: 80% !important;
    }
}

@media (max-width:676px) {
    .side-resposne {
        display: none !important;
    }

    .side-resposne-mobile {
        display: inline-block !important;
    }

    .side-value-component {
        width: 100% !important;
        height: auto !important;
    }

    .side-value-my-list {
        width: 100% !important;
        height: auto !important;
    }

    .side-mobile-responsive {
        display: inline-block !important;
    }

    .side-value-list-mobile {
        width: 100% !important;
        height: auto !important;
    }

    .side-value-list {
        width: 100% !important;
        height: auto !important;
    }

    .side-value-pay {
        width: 100% !important;
    }

}