.mobile-container {
    margin: 40px 0 40px 0;
    height: 'auto';
    flex-wrap: wrap;

    .mobile-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // width: 500px;

        .mobile-heading {
            font-size: 30px;
            font-weight: 500;
            color: #2E83CD;
            margin-bottom: 50px;
        }

        .web-heading {
            font-size: 24px;
            font-weight: 500;
            color: #2E83CD;
            padding-left: 7px;
            padding-top: 50px;
        }

        .web-description {
            font-size: 16px;
            font-weight: 400;
            color: #505050;
            // padding: 10px 0 0 7px;
        }
    }
}


.about-section {
    background-color: #D9EFFF;
    height: auto;
    padding: 50px;

    .about-heading {
        font-size: 30px;
        font-weight: 700;
        color: #2E83CD;
        padding-bottom: 15px;
    }


    .listing-heading {
        font-size: 24px;
        font-weight: 700;
        // line-height: 1;
        color: #2E83CD;
        padding-bottom: 10px;
    }

    .listing-description {
        font-size: 16px;
        font-weight: 400;
        color: #505050;
        // line-height: 3.5;
    }

    .line-text-num {
        line-height: 1.6;
    }

    .line-text {
        line-height: 2;
    }
}

.footer-section {
    height: auto;
    padding: 25px 50px 25px 50px;
    background-color: #1E4A6A;

    .footer-heading {
        color: white;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 400;
    }

    .footer-value {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
    }

    .footer-column {
        flex: 1 -1 280px;
        margin: 10px;
    }
}

.footer-last {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    padding-left: 7px;
}

.about-us {
    font-family: 'Proxima_nova_reg'
}

.about-us-description {
    margin-top: 8px !important;
}

@media (min-width:1570px) {
    .footer-section .footer-value {
        justify-content: space-between !important;
    }
}

@media (min-width:1200px) and (max-width: 1400px) {
    .about-section {
        .listing-heading {
            font-size: 16px !important;
        }

        .listing-description {
            font-size: 15px !important;
        }
    }

}

// @media (min-width:992px) and (max-width: 1200px) {
//     .about-section {
//         .listing-heading {
//             font-size: 16px !important;
//         }

//         .listing-description {
//             font-size: 15px !important;
//         }

//     }

// }

@media (max-width:1200px) {
    .about-section .listing-heading {
        margin-top: 20px !important;
    }
}

@media (min-width:766px) and (max-width: 992px) {
    .about-section .listing-heading {
        margin-top: 20px !important;
    }
}

@media (min-width:766px) and (max-width:900px) {
    .about-section .listing-heading {
        margin-top: 20px !important;
    }
}

@media (max-width:575px) {
    .about-section{
        padding:15px !important;
    }
    .mobile-container .mobile-section {
        .mobile-heading {
            text-align: left !important;
            font-size: 19px !important;
        }

        .web-heading {
            text-align: left !important;
            padding-left: 0px !important;
        }

        .web-description {
            text-align: left !important;
        }
    }

    .about-section .listing-heading {
        margin-top: 20px !important;
        font-size: 24px !important;
        font-weight: 700 !important;
    }
    .listing-description {
        font-size: 16px !important;
    }
    .about-section .about-heading{
        font-size: 20px !important;
    }
    .footer-section{
        display: none !important;
    }
}