.add-manager-detail {
    // margin-top: 40px;

    @media (max-width:575px) {
        margin-bottom: 25px !important;
    }

    .add-manager-listing-data {
        // width: 840px;
        height: auto;
        background-color: #ffffff;
        padding: 20px 20px 36px 0;

        .listing-data {
            margin-top: -26px;
        }

        .listing-data-heading {
            font-size: 20px;
            font-weight: 500;
            margin-top: 5px;
        }

        .phone-number {
            font-size: 16px;
            font-weight: 400;
            color: #707070;
            padding-left: 8px;
        }

        .msg-box {
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: #2975CA;
        }
    }

    .manager-heading {
        font-size: 18px;
        font-weight: 500;
        margin-top: 15px;
        margin-left: 10px;
    }

    .add-detail-manager-seaction {
        margin: 10px !important;
        height: 470px;
        overflow-y: scroll;
        overflow-x: hidden;

        .manager-card-detail {
            padding: 10px;

            .MuiBox-root {
                padding: 10px !important;
            }

            .MuiMobileStepper-root {
                display: flex;
                align-items: center;
                background-color: transparent;
                color: #ffff !important;
                margin-top: -130px !important;
                margin-left: -20px !important;
                margin-right: -23px !important;

                @media (min-width:575px) and (max-width:1024px) {
                    margin-top: -100px !important;
                }

                @media (min-width:431px) and (max-width:575px) {
                    margin-top: -190px !important;
                }

            }

            .MuiPaper-root {
                background-color: transparent !important;
                color: #ffff !important;
            }

            .MuiButtonBase-root {
                display: inline-block;
                justify-content: inherit !important;
            }

            .mobile-type {
                display: none !important;

                .rental-heading {
                    padding-top: 70px;
                    color: #707070;
                    font-size: 16px;
                    font-family: "Proxima Nova";
                    display: flex;
                    justify-content: center;
                }

                .rental-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 10px 6px 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }

                .rent-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                }

                .deposit-price {
                    font-size: 16px;
                    font-weight: 600;
                    // padding: 8px 0 5px 10px;
                    padding-left: 7px;
                    font-family: Proxima_nova_reg;
                }


            }

            .rent-box {
                padding-top: 40% !important;
                display: flex;
                justify-content: center;

                .rental-heading {
                    padding-top: 70px;
                    color: #707070;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: "Proxima_nova_medium";
                    // display: flex;
                    // justify-content: center;
                }

                .rental-price {
                    font-size: 20px;
                    font-weight: 600;
                    // padding: 10px 0px 5px 10px;
                    padding-top: 10px;
                    font-family: "Proxima_nova_medium";
                    text-wrap: nowrap;
                }

                .rent-heading {
                    display: flex;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 500;
                    color: #707070;
                }

                .deposit-price {
                    font-size: 20px;
                    font-weight: 600;
                    padding: 8px 0 5px 10px;
                    font-family: "Proxima Nova";
                    display: flex;
                    justify-content: center;
                }
            }

            .deposit-type {
                display: none !important;
            }

            .listing-card-detail {

                @media (max-width:575px) {
                    padding-bottom: 10px !important;
                }

                .listing-user-heading {
                    font-size: 23px;
                    font-weight: 600;
                    color: #2F86D1;
                }

                .listing-user-month {
                    display: flex;
                    align-self: center;
                    color: #2F86D1;
                    margin-top: 5px;
                    font-size: 16px;
                    font-weight: 600;
                }

                .request-profile {
                    background-color: #2F86D1;
                    color: #ffffff;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 600;
                    padding-right: 3px;
                    padding-top: 3px;
                    cursor: pointer;
                }

                .categroy-heading {
                    color: #707070;
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .category-value {
                    font-size: 18px;
                    font-weight: 600;
                    margin-left: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .select-value-heading {
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .request-genarte {
                    font-size: 18px;
                    font-weight: 600;
                    margin-right: 20px;
                    color: #2F86D1;
                    margin-top: 10px;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .user-heading {
                    font-size: 18px !important;
                    font-weight: 600 !important;
                    font-family: "Proxima Nova";
                }

                .user-dot {
                    font-size: 20px;
                    font-weight: 800;
                    margin: -4px 6px 0 6px;
                }

                .property-owner {
                    color: #707070;
                    font-size: 16px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                }

                .ipaid-detail {
                    display: none !important;

                    @media (min-width:766px) and (max-width:915px) {
                        display: flex !important;
                        justify-content: end !important;
                    }
                }

                .full-screen-detail {
                    display: flex !important;
                    align-items: center !important;

                    @media (min-width:766px) and (max-width:915px) {
                        display: none !important;
                    }
                }

                .view-heading {
                    font-size: 18px;
                    font-weight: 600;
                    color: #2F86D1;
                    text-decoration: underline;

                    @media (min-width:766px) and (max-width:915px) {
                        font-size: 16px !important;
                    }
                }

            }
        }

        .empty-manager-list {
            font-family: Proxima_nova_reg;
            width: 100% !important;
            height: 320px;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 500;
        }
    }

}

.add-manager-detail .add-detail-manager-seaction::-webkit-scrollbar {
    width: 8px !important;
    z-index: 1 !important;
    display: inline-block !important;
}

.add-manager-detail .add-detail-manager-seaction::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
}

.add-manager-detail .add-detail-manager-seaction::-webkit-scrollbar-thumb {
    background-color: #909090 !important;
    border-radius: 3px !important;
}

.add-manager-detail .add-detail-manager-seaction::-webkit-scrollbar-thumb:hover {
    background-color: #909090 !important;
}

@media (min-width:768px) and (max-width:808px) {
    .add-manager-detail .add-detail-manager-seaction .manager-card-detail .rent-box {
        display: none !important;
    }

    // .add-manager-detail .add-detail-manager-seaction .manager-card-detail .listing-card-detail .view-heading {
    //     display: none !important;
    // }

    .add-manager-detail .add-detail-manager-seaction .manager-card-detail .deposit-type {
        // padding-top: 15px !important;
        padding-top: 58% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }
}

@media (min-width:1024px) and (max-width:1199px) {
    .add-manager-detail .add-detail-manager-seaction .manager-card-detail .rent-box {
        padding-top: 23% !important;
    }

}

@media (min-width:800px) and (max-width:950px) {
    .add-manager-detail .add-detail-manager-seaction .manager-card-detail .rent-box {
        padding-top: 23% !important;
    }
}

@media (max-width:768px) {
    .add-manager-detail .add-detail-manager-seaction .manager-card-detail .rent-box {
        display: none !important;
    }

    .add-manager-detail .add-detail-manager-seaction .manager-card-detail .deposit-type {
        padding-top: 30% !important;
    }
}

@media (max-width:767px) {

    .add-manager-detail .add-detail-manager-seaction {

        width: 97vw !important;

        .manager-card-detail {
            box-shadow: 0px 4px 4px 0px #0000001F !important;
            padding: 0px !important;

            .images-addManager {
                padding: 0px !important;
            }

            .listing-card-detail {

                .mobile-view {
                    justify-content: end !important;
                }

                .mobile-type {
                    display: flex !important;
                    margin-top: 10px !important;
                }

                .view-heading {
                    display: inline-block !important;
                }
            }

            .mobile-fav-pos .mobile-favIcon {
                left: 88% !important;
                position: absolute !important;
                top: 5% !important;
            }

        }
    }
}

@media (min-width:430px) and (max-width:540px) {
    .add-manager-detail .add-detail-manager-seaction .manager-card-detail .listing-card-detail .section-profile {
        margin-top: 155px !important;
    }

}

@media (max-width:430px) {
    .add-manager-detail .add-detail-manager-seaction .manager-card-detail .listing-card-detail .section-profile {
        margin-top: 90px !important;
    }
}

@media (width:375px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 510px !important;
    }
}

@media (width:414px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 740px !important;
    }
}

@media (width:390px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 700px !important;
    }
}

@media (width:430px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 782px !important;
    }
}

@media (width:393px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 645px !important;
    }
}

@media (width:412px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 767px !important;
    }
}

@media (width:360px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 595px !important;
    }
}

@media (width:375px) and (height:812px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 665px !important;
    }
}

@media (width:320px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 325px !important;
    }
}

@media (width:414px) and (height:736px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 590px !important;
    }
}

@media (width:411px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 593px !important;
    }
}

@media (width:411px) and (height:823px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 680px !important;
    }
}

@media (width:540px) {
    .add-manager-detail .add-detail-manager-seaction {
        height: 573px !important;
    }
}