.arrow-box-payment {
    background-color: #ffffff;
    // width: 60px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.listing-payment {
    .listing-detail {
        background-color: #ffffff;
        padding: 15px 20px;
        height: auto;

        @media (max-width:575px) {
            padding: 15px 0px;
        }

        .arrow-back-payment {
            display: none !important;
        }

        .listing-main-heading {
            font-size: 24px;
            font-weight: 600;
            // border-bottom: 1.8px solid #e1e3e1;
        }

        .rent-box {
            // padding: 24% !important;
            padding-top: 24% !important;
            padding-bottom: 20px;

            .rental-heading {
                // padding-top: 70px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 20px;
                font-weight: 600;
                padding: 10px 6px 5px 10px;
                font-family: "Proxima Nova";
            }

            .rent-heading {
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 20px;
                font-weight: 600;
                padding: 10px 0 5px 10px;
                font-family: "Proxima Nova";
            }
        }

        .deposit-type {
            display: none !important;
        }


        .listing-details-card {

            // @media (max-width:575px) {
            //     padding: 20% 10px 10px 10px !important;
            // }
            @media (min-width:535px) and (max-width:575px) {
                padding: 20% 10px 10px 10px !important;
            }

            @media (min-width:430px) and (max-width:520px) {
                padding: 25% 10px 10px 10px !important;
            }

            @media (min-width:350px) and (max-width:430px) {
                padding: 17% 10px 10px 10px !important;
            }

            .listing-user-heading {
                font-size: 20px;
                font-weight: 600;
                color: #2F86D1;
                // margin-top: 7px;
            }

            .listing-user-month {
                display: flex;
                align-self: center;
                color: #2F86D1;
                // margin-top: 7px;
                font-size: 16px;
                font-weight: 600;
            }

            .categroy-heading {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
            }

            .categroy-heading-type {
                color: #707070;
                font-size: 14px;
                font-weight: 500;
                margin-left: 8px;
                font-family: "Proxima_nova_medium";
                display: flex;
                align-items: center;
                margin-top: 3px;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }

            .category-value {
                font-size: 14px;
                font-weight: 600;
                margin-left: 10px;
                display: flex;
                align-items: center;
                margin-top: 3px;
            }

            .select-value-heading {
                font-size: 13px;
                font-weight: 600;
                margin-top: 6px;
            }
        }

        .payment-card {
            padding: 0px 30px 15px 10px;

            .payment-descrption {
                font-size: 16px;
                font-weight: 400;
                color: #707070;
            }

            .price-payment {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
            }
        }
    }

}

.payment-detail {
    background-color: #ffffff;
    padding: 15px 20px;
    height: auto;

    .payment-heading {
        font-size: 24px;
        font-weight: 600;
        border-bottom: 1.8px solid #e1e3e1;
    }

    .method-heading {
        font-size: 20px;
        font-weight: 600;
    }

    .card-container {
        width: 120px;
        height: auto;
        border: 1.5px solid #505050;

        .credit-heading {
            font-size: 16px;
            font-weight: 600;
        }

    }

    .paypal-button.paypal-button-color-white {
        background-color: red !important;
    }



    // .paypal-buttons {
    //     min-width: 125px !important;
    //     width: 125px !important;

    //     .paypal-button-container {
    //         min-width: 125px !important;
    //         width: 125px !important;
    //     }
    // }
}

// .paypal-button.paypal-button-color-white {
//     background-color: red !important;
// }
.paypal-button {
    background-color: red !important;
}

.searchdetail {

    .MuiMobileStepper-root {
        display: flex;
        align-items: center;
        background-color: transparent;
        color: #ffff !important;
        margin-top: -130px !important;
        margin-left: -20px !important;
        margin-right: -23px !important;


        @media (min-width:575px) and (max-width:915px) {
            margin-top: -100px !important;
        }

        @media (min-width:431px) and (max-width:575px) {
            margin-top: -190px !important;
        }
    }

    .MuiPaper-root {
        background-color: transparent !important;
        color: #ffff !important;
    }

    .MuiButtonBase-root {
        display: inline-block;
        justify-content: inherit !important;
    }
}

@media (min-width:1200px) and (max-width:1523px) {
    .listing-payment .listing-detail {
        .rent-box {
            padding: 30% !important;
        }
    }
}

@media (min-width:1023px) and (max-width:1199px) {
    .arrow-box-payment {
        display: none !important;
    }

    .listing-payment .listing-detail {

        .listing-main-heading {
            display: none !important;
        }

        .arrow-back-payment {
            display: flex !important;
        }
    }

    .payment-detail {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
}

@media (min-width:991px) and (max-width:1024px) {
    .arrow-box-payment {
        display: none !important;
    }

    .listing-payment .listing-detail {

        .listing-main-heading {
            display: none !important;
        }

        .arrow-back-payment {
            display: flex !important;
        }
    }

}

@media (min-width:768px) and (max-width:991px) {
    .listing-payment .listing-detail {
        .rent-box {
            display: none !important;
        }
    }

    .listing-payment .listing-detail .deposit-type {
        display: inline-block !important;
        // padding-top: 33% !important;
        // padding-left: 10px !important;
        padding: 35% 0 20px 10px;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }

    .review-container {
        margin: 10px !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .arrow-box-payment {
        display: none !important;
    }

    .listing-payment {
        margin: 10px !important;
    }

    .payment-detail {
        margin: 10px !important;
        margin-bottom: 10px !important;
    }

    .listing-payment .listing-detail {

        .listing-main-heading {
            display: none !important;
        }

        .arrow-back-payment {
            display: flex !important;
        }
    }
}

@media (max-width:767px) {
    .listing-payment .listing-detail {
        .rent-box {
            padding: 30% !important;
        }
    }

    .arrow-box-payment {
        display: none !important;
    }

    .listing-payment .listing-detail {

        .listing-main-heading {
            display: none !important;
        }

        .arrow-back-payment {
            display: flex !important;
            padding-left: 10px !important;
        }
    }

    .payment-detail {
        margin: 10px 0px 10px 0px !important;

    }
}

// @media (min-width:535px) and (max-width:575px) {
//     .listing-details-card {
//         padding-top: 20% !important;
//     }
// }

// @media (min-width:350px) and (max-width:430px) {
//     .listing-details-card {
//         padding-top: 20% !important;
//     }
// }

@media (min-width:430px) and (max-width:575px) {
    .listing-payment .listing-detail .listing-details-card .section-profile {
        margin-top: 50px !important;
    }

}

@media (max-width:430px) {
    .listing-payment .listing-detail .listing-details-card .section-profile {
        margin-top: 25px !important;
    }

}

.loader-payment {
    border-radius: 50%;
    border: none !important;
    margin-bottom: 12px !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width:575px) {
        height: 120vh !important;
    }

    .loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #3498db;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .verify-description {
        display: inline-block !important;

        @media (max-width:575px) {
            display: none !important;
        }
    }

}

.loader-description {
    border-radius: 50%;
    border: none !important;
    margin-bottom: 12px !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .verify-description-mobile {
        display: none !important;

        @media (max-width:575px) {
            display: inline-block !important;
        }
    }
}